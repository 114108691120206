@import './../../../../styles/globalConfigs';

.simpleTitle {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ($sm - 0.02)) {
    font-size: 14px;
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
