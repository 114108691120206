@import "../../styles/globalConfigs";

[data-jsx="Writing"] {
  .writing-accordion {
    margin-bottom: 30px;

    .top-border {
      display: block;
      width: 100%;
      border-radius: 50rem;
      @media (max-width: ($sm - 0.02)) {
        height: 9px;
      }
      @media (min-width: $sm) {
        height: 14px;
      }
    }

    .wa-card {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.57);
      border-radius: 0 0 10px 10px;
      @media (max-width: ($sm - 0.02)) {
        padding: 8px 19px;
        margin: 0 10px;
      }
      @media (min-width: $sm) {
        padding: 12px 28px;
        margin: 0 10px;
      }

      .accordion-button {
        &.collapsed {
          .arrow {
            transform: rotate(0deg);
          }
        }
      }
    }

    .card-header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin-bottom: 0;
        font-weight: 700;
        font-style: italic;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

        @media (max-width: ($sm - 0.02)) {
          font-size: 17px;
        }
        @media (min-width: $sm) {
          font-size: 24px;
        }

        .writing-icon {
          transform: translateY(-3px);
          display: inline-block;

          @media (max-width: ($sm - 0.02)) {
            width: 28px;
            height: 28px;
            margin-right: 5px;
          }
          @media (min-width: $sm) {
            width: 40px;
            height: 40px;
            margin-right: 7px;
          }

          > svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .arrow {
        transition: transform 0.3s ease;
        transform: rotate(180deg);
        display: flex;
        @media (max-width: ($sm - 0.02)) {
          width: 14px;
        }
        @media (min-width: $sm) {
          width: 24px;
        }

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .card-main {
      @media (max-width: ($sm - 0.02)) {
        padding-top: 10px;
      }
      @media (min-width: $sm) {
        padding-top: 19px;
      }

      ul {
        li {
          @media (max-width: ($sm - 0.02)) {
            margin-bottom: 10px;
            font-size: 16px;
          }
          @media (min-width: $sm) {
            margin-bottom: 16px;
            font-size: 24px;
          }
        }
      }
    }

    .accordion-button {
      width: 100%;
    }
  }

  .writing-openpage-animation {
    position: absolute;
    border-radius: 50rem;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: ($sm - 0.02)) {
      height: 9px;
      width: 9px;
    }
    @media (min-width: $sm) {
      height: 14px;
      width: 14px;
    }

    animation: motionStep1 0.6s ease-in-out forwards,
    motionStep2 0.6s ease-in-out forwards 0.6s,
    motionStep3 0.6s ease-in-out forwards 1.2s;
  }

  .writing-display-after-animation {
    opacity: 0;
    pointer-events: none;
    animation: motionDisplayPage 0.6s ease-in-out forwards 1.2s;
  }

  .exercise-line {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      margin-top: 70px;
    }
    @media (min-width: $sm) {
      margin-top: 100px;
    }

    span {
      flex: 1 1 auto;
      height: 2px;
      width: 100%;
    }

    p {
      font-style: italic;
      @media (max-width: ($sm - 0.02)) {
        font-size: 20px;
        margin-right: 12px;
        margin-left: 12px;
      }
      @media (min-width: $sm) {
        font-size: 28px;
        margin-right: 18px;
        margin-left: 18px;
      }
    }
  }
}

@keyframes motionStep1 {
  100% {
    top: 30px;
  }
}

@keyframes motionStep2 {
  100% {
    top: 0;
  }
}

@keyframes motionStep3 {
  100% {
    width: 100%;
    opacity: 0;
  }
}

@keyframes motionDisplayPage {
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}