@import './../../styles/globalConfigs';

.buttonPrimary {
  font-family: 'Roboto Flex', $Sahel, $SegoeUI, sans-serif;
  border-radius: 100px;
  width: fit-content;
  display: flex;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  @media (max-width: ($sm - 0.02)) {
    width: 250px;
    max-width: 100%;
    height: 40px;
    font-size: 18px;
    padding: 5px 10px;
  }
  @media (min-width: $sm) {
    min-width: 250px;
    height: 46px;
    font-size: 21px;
    padding: 7px 14px;
  }

  &.buttonDisabled {
    background: linear-gradient(to right, #e8e8e8, #c6c6c6) !important;
    cursor: default;
    color: #9b9fa3 !important;
  }

  &.buttonSmall {
    @media (max-width: ($sm - 0.02)) {
      min-width: 100px;
      height: 27px;
      font-size: 14px;
      padding: 5px 10px;
    }
    @media (min-width: $sm) {
      min-width: 136px;
      height: 37px;
      font-size: 19px;
      padding: 7px 14px;
    }
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 16px;
    margin-left: 0;
    position: relative;
    transition: width 0.3s ease-out, margin-left 0.3s ease-out;

    &:after {
      display: none;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-radius: 50%;
      border: 3px solid var(--spinner-color);
      border-left-color: transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    &.startSpin {
      width: 16px;
      margin-left: 6px;

      &:after {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
