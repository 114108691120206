@import "../../styles/globalConfigs";

[data-jsx="HintClueButton"] {
  &.hint-clue-button {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: ($sm - 0.02)) {
      width: 43px;
      height: 43px;
    }
    @media (min-width: $sm) {
      width: 60px;
      height: 60px;
    }

    > svg {
      display: inline-block;
      width: 64%;
      height: 64%;
    }
  }
}