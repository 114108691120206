@import "../../styles/globalConfigs";

[data-jsx="AudioPlayer"] {
  &.audio-player-container {
    transition: 150ms;
    border-radius: 20px;
    max-width: 500px;
    margin: auto;

    @media (max-width: ($sm - 0.02)) {
      height: 140px;
      padding: 7px 15px;
    }
    @media (min-width: $sm) {
      height: 186px;
      padding: 10px 23px;
    }

    .audio-info {
      transition: none;
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        padding: 0 15px;
        margin-bottom: 6px;
      }
      @media (min-width: $sm) {
        padding: 0 23px;
        margin-bottom: 9px;
      }

      .text {
        transition: 150ms;
        @media (min-width: $sm) and (max-width: ($sm - 0.02)) {
          height: 19px;
        }
        @media (min-width: $sm) {
          height: 24px;
        }
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .audio-title {
        transition: 150ms;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .audio-details {
      transition: 150ms;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 50rem;
      border: 1px solid;
      @media (max-width: ($sm - 0.02)) {
        padding: 0 14px;
        margin-bottom: 8px;
        height: 20px;
      }
      @media (min-width: $sm) {
        padding: 0 22px;
        margin-bottom: 12px;
        height: 32px;
      }

      .date {
        transition: none;
        width: 33.3333%;
        text-align: left;
      }

      .duration {
        transition: none;
        width: 33.3333%;
        text-align: center;
      }

      .size {
        transition: none;
        width: 33.3333%;
        text-align: right;
      }
    }

    .audio-controls {
      transition: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: ($sm - 0.02)) {
        padding: 0 5px;
        margin-bottom: 8px;
      }
      @media (min-width: $sm) {
        padding: 0 11px;
        margin-bottom: 12px;
      }

      .play-button, .pause-button {
        transition: 150ms;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        background: transparent;
        padding: 0;
        @media (max-width: ($sm - 0.02)) {
          margin-right: 8px;
          width: 23px;
          height: 23px;
        }
        @media (min-width: $sm) {
          margin-right: 13px;
          width: 23px;
          height: 23px;
        }

        svg {
         width: 100%;
         height: 100%;
        }
      }

      .bar {
        user-select: none;
        width: 100%;
        display: flex;
        align-items: center;

        .bar__time {
          transition: 150ms;
        }

        .bar__progress {
          transition: 150ms;
          flex: 1;
          border-radius: 5px;
          margin: 0 10px;
          height: 2px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;

          .bar__progress__before {
            position: absolute;
            height: 4px;
            transition: width 0ms;
          }

          .bar__progress__knob {
            position: relative;
            border-radius: 50%;
            transition: left 0ms;
            @media (max-width: ($sm - 0.02)) {
              height: 12px;
              width: 12px;
            }
            @media (min-width: $sm) {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }

    .audio-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: none;

      button {
        border: 1px solid;
        border-radius: 50rem;
        background: transparent;
        transition: 150ms;
        @media (max-width: ($sm - 0.02)) {
          height: 20px;
          min-width: 50px;
          margin: 0 12px;
        }
        @media (min-width: $sm) {
          height: 27px;
          min-width: 75px;
          margin: 0 19px;
        }
      }
    }
  }
}