@import './../../styles/globalConfigs';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50rem;
  width: 260px;
  height: 40px;
  max-width: 100%;

  .icon {
    display: flex;
    width: 40px;

    > svg {
      width: 100%;
      height: auto;
    }
  }
  @media (max-width: ($sm - 0.02)) {
  }
  @media (min-width: $sm) {
  }
}
