@import './../../../styles/globalConfigs';

.badge {
  .large {
    @media (max-width: ($sm - 0.02)) {
      width: 50px;
    }
    @media (min-width: $sm) {
      width: 64px;
    }
  }

  .medium {
    @media (max-width: ($sm - 0.02)) {
      width: 38px;
    }
    @media (min-width: $sm) {
      width: 45px;
    }
  }

  svg {
    width: 100%;
    height: auto;
  }
}
