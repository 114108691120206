.container {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  left: 0;
  top: 0;
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.16);
}

.rive {
  width: 420px;
  height: 420px;
  max-width: 90%;
  max-height: 90%;
}
