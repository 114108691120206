@import './../../styles/globalConfigs';

.comboBoxDropdownContainer {
  max-width: 100%;
  margin: 4px 0;
  display: inline-block;
}

.comboBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  border-radius: 20px;

  @media (max-width: ($sm - 0.02)) {
    min-width: 90px;
    height: auto;
    border: 1px solid;
    padding: 0 5px;
  }
  @media (min-width: $sm) {
    min-width: 170px;
    height: 36px;
    border: 2px solid;
    padding: 0 10px;
  }

  &:after {
    content: none;
  }

  &[aria-expanded='true'] {
    .comboBoxArrow {
      transform: rotate(180deg);
    }
  }
}

.comboBoxDropdown {
  transition: none;
  padding: 0;
  margin: 0;
  @media (max-width: ($sm - 1)) {
    min-width: 53px;
    border: 1px solid;
  }
  @media (min-width: $sm) {
    min-width: 170px;
    border: 2px solid;
  }
}

.comboBoxItem {
  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
  }
  @media (min-width: $sm) {
    font-size: 22px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid;
  }

  &:hover {
    background: rgba(251, 251, 251, 0.2);
  }

  &:focus {
    background: rgba(251, 251, 251, 0.2);
  }

  &:active {
    background: rgba(251, 251, 251, 0.2);
  }
}

.comboBoxValue {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;

  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
  }
  @media (min-width: $sm) {
    font-size: 20px;
  }
}

.comboBoxArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    width: 10px;
    margin-left: 7px;
  }
  @media (min-width: $sm) {
    width: 15px;
    margin-left: 10px;
  }

  > svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.comboBoxSheetItems {
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-height: 100px;

  .item {
    display: flex;
    gap: 7px;
    align-items: baseline;
    min-height: 32px;
    border-radius: 5px;
    padding: 6px;
  }
}
