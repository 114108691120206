@import '../../../../styles/globalConfigs';

.container {
  width: 700px;
  max-width: 100%;
  padding: 34px 10px;
  border-radius: 20px;
  font-size: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: $xsm) {
    padding: 3px 7px;
  }
  @media (max-width: $sm) {
    padding: 25px 23px;
    width: 500px;
  }
  .circlePercent {
    width: 113px;
    height: 113px;
    margin-bottom: 16px;
    @media (max-width: $sm) {
      margin-bottom: 10px;
    }
  }
  .description {
    font-size: 21px;
    margin-bottom: 16px;
    @media (max-width: $sm) {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    @media (max-width: $sm) {
      gap: 10px;
    }
  }
}
