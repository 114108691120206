@import './../../../../styles/globalConfigs';

.bubbleDescriptionsContainer {
  display: grid;
  @media (max-width: ($sm - 0.02)) {
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
  }
  @media (min-width: $sm) {
    margin-top: 14px;
    margin-bottom: 14px;
    gap: 14px;
  }

  > div {
    display: flex;
    align-items: baseline;
  }

  .bubble {
    display: inline-flex;
    position: relative;
    @media (max-width: ($sm - 0.02)) {
      margin-right: 8px;
      transform: translateY(3px);
    }
    @media (min-width: $sm) {
      margin-right: 12px;
      transform: translateY(5px);
    }

    > svg {
      @media (max-width: ($sm - 0.02)) {
        width: 18px;
        height: 18px;
      }
      @media (min-width: $sm) {
        width: 25px;
        height: 25px;
      }
    }

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      @media (max-width: ($sm - 0.02)) {
        font-size: 10px;
      }
      @media (min-width: $sm) {
        font-size: 12px;
      }
    }
  }

  &.bubbleImageTwoColumnContainer {
    grid-template-columns: repeat(2, 50%);
  }
}
