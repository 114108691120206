@import '../../styles/globalConfigs';

.sectionTextInfoButton {
  @media (max-width: ($sm - 0.02)) {
    width: 15px;
  }
  @media (min-width: $sm) {
    width: 19px;
  }

  > svg {
    width: 100%;
    height: auto;
  }
}

.textinfoPopoverBody {
  padding: 12px;
  min-width: 274px;
}

.textinfoPopover {
  border-radius: 15px;
  font-size: 16px;
  min-height: 114px;
  z-index: 1010;

  .tabsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 120px;
      padding: 2px;
      border: 1px solid;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &:not(:first-child) {
        border-left: 0;
      }
    }
  }

  .infoContent {
    p {
      padding: 10px 7px 0;
    }
  }

  * {
    font-size: inherit !important;
  }
}
