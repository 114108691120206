@import './../../styles/globalConfigs';

.deleteButton {
  width: 20px;
  height: 20px;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }
}

.addPhotoButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .icon {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 60%;
      height: 60%;
    }
  }
}

.avatars {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}
.loadingStyle {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
.avatarItem {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 7px;

  img {
    width: 100%;
    height: 100%;
  }

  &.selected {
    img {
      border: 4px solid;
    }
  }
}
