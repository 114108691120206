@import './../../../../styles/globalConfigs';

.button {
  scroll-margin-top: 20vh;
  display: block;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

  @media (max-width: ($sm - 0.02)) {
    border-radius: 15px;
    font-size: 13px;
    padding: 6px 10px;
    margin-bottom: 7px;
    min-width: 218px;
  }
  @media (min-width: $sm) {
    border-radius: 20px;
    font-size: 17px;
    padding: 9px 14px;
    margin-bottom: 14px;
    min-width: 305px;
  }

  &.border {
    @media (max-width: ($sm - 0.02)) {
      border: 1px solid transparent;
    }
    @media (min-width: $sm) {
      border: 2px solid transparent;
    }
  }
}
