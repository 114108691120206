@import './../../styles/globalConfigs';

.comboBox {
  margin: 4px 0;
  max-width: 100%;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ($sm - 0.02)) {
    min-width: 53px;
    height: auto;
    border: 2px solid;
    padding: 0 5px;
  }
  @media (min-width: $sm) {
    min-width: 170px;
    height: 36px;
    border: 3px solid;
    padding: 0 10px;
  }

  .comboBoxValue {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;

    @media (max-width: ($sm - 0.02)) {
      font-size: 16px;
    }
    @media (min-width: $sm) {
      font-size: 20px;
    }
  }
}
