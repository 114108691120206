@import './../../styles/globalConfigs';

.chooseFromCategoryContainer {
  .playButtonContainer {
    display: flex;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      margin-bottom: 14px;
    }
  }

  .exerciseCard {
    @media (max-width: ($sm - 0.02)) {
      padding: 15px 4px;
    }
    @media (min-width: $sm) {
      width: 610px !important;
      padding: 20px 16px;
    }

    margin-right: auto;
    margin-left: auto;
  }

  .categoryBox {
    border: 1px solid;
    width: 100%;
    text-align: left;

    &.checked {
      border: 3px solid;
    }

    @media (max-width: ($sm - 0.02)) {
      border-radius: 5px;
      padding: 8px 6px;
      font-size: 14px;
    }
    @media (min-width: $sm) {
      border-radius: 10px;
      padding: 16px 12px;
      font-size: 20px;
    }

    &:not(:last-child) {
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 5px;
      }
      @media (min-width: $sm) {
        margin-bottom: 10px;
      }
    }
  }
}

.exerciseCard {
  @media (max-width: ($sm - 0.02)) {
    border-radius: 12px;
    font-size: 16px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    font-size: 22px;
  }

  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      margin-bottom: 14px;
    }
  }

  .cardNumberBadge {
    flex: 0 0 auto;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;

    &:not(:empty) {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    }

    @media (max-width: ($sm - 0.02)) {
      width: 25px;
      height: 25px;
      font-size: 12px;
      margin-right: 10px;
    }
    @media (min-width: $sm) {
      width: 30px;
      height: 30px;
      font-size: 16px;
      margin-right: 14px;
    }
  }
}

.exerciseInCard {
  &:not(.checked) {
    background: transparent !important;
  }

  &:first-child {
    @media (max-width: ($sm - 0.02)) {
      border-radius: 12px 12px 0 0;
    }
    @media (min-width: $sm) {
      border-radius: 15px 15px 0 0;
    }
  }

  &:last-child {
    @media (max-width: ($sm - 0.02)) {
      border-radius: 0 0 12px 12px;
    }
    @media (min-width: $sm) {
      border-radius: 0 0 15px 15px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid;
    @media (max-width: ($sm - 0.02)) {
      padding-bottom: 10px;
    }
    @media (min-width: $sm) {
      padding-bottom: 14px;
    }
  }
}

.categoriesBoxContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 21px;
  }
  @media (min-width: $sm) {
    margin-bottom: 29px;
    display: flex;
    justify-content: center;
  }

  .exerciseCard {
    margin-bottom: 0;
    max-width: 100%;
    width: 405px;
  }
}

.matchContainer {
  .categoriesBoxContainer {
    display: flex;
    justify-content: center;
  }

  .matchCard {
    @media (max-width: ($sm - 0.02)) {
      padding: 7px 15px 15px 15px;
    }
    @media (min-width: $sm) {
      padding: 10px 31px 31px 31px;
    }

    &:first-child {
      @media (max-width: ($sm - 0.02)) {
        margin-right: 6px;
      }
      @media (min-width: $sm) {
        margin-right: 20px;
      }
    }

    .categoryBox {
      transition: color 300ms ease;
      border-bottom: 1px solid;
      cursor: pointer;

      @media (max-width: ($sm - 0.02)) {
        padding: 15px 0;
        font-size: 15px;
      }
      @media (min-width: $sm) {
        padding: 21px 0;
        font-size: 21px;
      }

      &.selected {
        font-weight: 600;
        font-style: italic;
      }
    }
  }

  .matchResultCard {
    display: block;
    outline: none;
    border: 3px solid transparent;
    max-width: 100%;
    text-align: inherit;

    @media (max-width: ($sm - 0.02)) {
      padding: 11px 14px;
      margin: 0 auto 10px;
      width: 100%;
    }
    @media (min-width: $sm) {
      padding: 16px 20px;
      margin: 0 auto 14px;
      width: 830px;
    }

    p {
      margin-bottom: 0;
      @media (max-width: ($sm - 0.02)) {
        font-size: 15px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
      }
    }
  }
}

.sayTheFollowingContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 30px;
  }
  @media (min-width: $sm) {
    margin-bottom: 43px;
  }

  .exerciseCard {
    margin: {
      left: auto;
      right: auto;
    }
    max-width: 100%;
    @media (min-width: $sm) {
      width: 839px;
    }
  }
}

.sayTheFollowingCard {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  margin-right: auto;
  margin-left: auto;
  transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
  position: relative;

  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
    padding: 12px 19px;
  }
  @media (min-width: $sm) {
    font-size: 20px;
    padding: 16px 27px;
  }

  &.checked:not(.exerciseInCard) {
    border: 3px solid transparent;
  }

  .phrases {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text:last-child {
      margin-bottom: 0;
    }
  }
}

.dragDropContainer {
  flex: 1;
  flex-direction: column;

  @media (max-width: ($sm - 0.02)) {
    margin-top: 18px;
    padding: 0 10px;
  }
  @media (max-width: ($sm + 200)) {
    margin-top: 18px;
    padding: 0 10px;
  }
  @media (min-width: ($sm + 200)) {
    margin-top: 25px;
    padding: 0 90px;
  }

  .dragDropInnerContainer {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 21px;
    }
    @media (min-width: $sm) {
      margin-bottom: 29px;
    }
  }

  .phrasesList {
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      margin-bottom: 19px;
      padding: 4px;
    }
    @media (min-width: $sm) {
      border-radius: 15px;
      margin-bottom: 27px;
      padding: 6px;
    }

    .item {
      border-radius: 50rem;
      overflow: hidden;
      cursor: grab;

      @media (max-width: ($sm - 0.02)) {
        line-height: 15px;
        font-size: 15px;
        margin: 7px;
        padding: 5px 15px 10px 10px;
      }
      @media (min-width: $sm) {
        line-height: 21px;
        font-size: 21px;
        margin: 11px;
        padding: 7px 15px 10px 10px;
      }
    }

    &.clickable {
      .item {
        cursor: pointer;
      }
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.dragDropCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: ($xsm - 0.02)) {
    flex-direction: column;
  }

  .dragDropImage {
    object-fit: contain;
    @media (max-width: ($md - 0.02)) {
      width: 155px;
      height: 155px;
      max-width: 100%;
    }
    @media (min-width: $md) {
      width: 220px;
      height: 220px;
    }

    &.dragDropBubble {
      height: auto;
      @media (max-width: ($xsm - 0.02)) {
        width: 220px;
        max-width: 100%;
      }
      @media (min-width: $xsm) and (max-width: ($sm - 0.02)) {
        width: 220px;
        margin-right: 10px;
      }
      @media (min-width: $sm) and (max-width: ($md - 0.02)) {
        width: 250px;
      }
      @media (min-width: $md) {
        width: 300px;
      }
    }
  }

  .dragDropCard {
    @media (max-width: ($md - 0.02)) {
      width: 100%;
    }
    @media (min-width: $md) {
      flex: 1 1 auto;
    }
  }
}

.dragDropCard {
  border-radius: 20px;

  @media (max-width: ($sm - 0.02)) {
    padding: 10px;
    font-size: 16px;
  }
  @media (min-width: $sm) {
    padding: 28px;
    font-size: 20px;
  }
}

.dragDropDialogsContainer {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: ($sm - 0.02)) {
    margin-top: 28px;
  }
  @media (min-width: $sm) {
    margin-top: 40px;
  }
}

.dragDropCategoriesCard {
  margin: auto;
  @media (max-width: ($sm - 0.02)) {
    padding: 14px;
  }
  @media (min-width: $sm) {
    padding: 20px;
  }
  width: 950px;
  max-width: 100%;
  min-width: fit-content;
}

.dragDropCategoriesContainer {
  @media (min-width: $lg) {
    display: flex;
    justify-content: space-evenly;
  }

  .dragDropCategoryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    @media (max-width: ($md - 0.02)) {
      margin-left: auto;
      margin-right: auto;
    }

    .dragDropGivenContainer {
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        margin-right: 10px;
        margin-left: 10px;
      }
      @media (min-width: $sm) {
        margin-right: 20px;
        margin-left: 20px;
      }

      .givenText {
        font-weight: 600;
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          margin: 10px auto;
          font-size: 15px;
        }
        @media (min-width: $sm) {
          margin: 15px auto;
          font-size: 21px;
        }
      }

      img {
        object-fit: contain;
        @media (max-width: ($sm - 0.02)) {
          width: 100px;
          max-height: 130px;
        }
        @media (min-width: $sm) {
          width: 152px;
          max-height: 200px;
        }
      }
    }

    .exerciseCard {
      flex: 1 1 auto;
      @media (max-width: ($sm - 0.02)) {
        padding: 14px 14px;
        max-width: 212px;
      }
      @media (min-width: $sm) {
        padding: 25px 25px;
        max-width: 304px;
      }

      .answerBoxWrapper {
        margin-bottom: 10px;
      }

      .answerBox {
        display: flex;
        width: fit-content;
      }
    }
  }
}

.dragDropCategoryLockContainer {
  @media (max-width: ($sm - 0.02)) {
    height: 21px;
  }
  @media (min-width: $sm) {
    height: 34px;
  }
  display: grid;
  justify-content: flex-end;
  margin-top: 10px;
}

.dragDropSimpleContainer {
  margin-top: 20px;

  .exerciseCard.typeImage {
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      padding: 10px;
    }
    @media (min-width: $sm) {
      padding: 14px 20px;
    }

    .answerBoxWrapper {
      &.twoColumn {
        flex-direction: column;

        .answerBox {
          margin-right: 0;
          @media (max-width: ($sm - 0.02)) {
            margin-bottom: 7px;
          }
          @media (min-width: $sm) {
            margin-bottom: 10px;
          }
        }

        .answerBoxRightItems {
          margin-left: 0;
          width: unset;
        }
      }
    }
  }

  &.finished {
    //.answerBoxRightItems:empty {
    //  display: none;
    //}

    .exerciseCard.typeImage {
      &:not(.typeBubble) {
        .dragDropCardContainer {
          flex-direction: unset;
        }

        .dragDropImage {
          @media (max-width: ($sm - 0.02)) {
            height: 100px;
            width: 100px;
          }
          @media (min-width: $sm) {
            height: 160px;
            width: 160px;
          }
        }
      }
    }
  }
}

.dragDropAnswerBoxContainer {
  @media (max-width: ($sm - 0.02)) {
    width: 300px;
  }
  @media (min-width: $sm) {
    width: 420px;
  }
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.answerBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.answerBox {
  position: relative;
  touch-action: none;
  transition: none;
  cursor: pointer;
  color: #fff;
  border-radius: 20px;
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;

  @media (max-width: ($sm - 0.02)) {
    font-size: 15px;
  }
  @media (min-width: $sm) {
    font-size: 21px;
  }

  .answerBoxBubble {
    position: absolute;
    display: flex;
    @media (max-width: ($sm - 0.02)) {
      top: -7px;
      left: -6px;
      width: 18px;
      height: 18px;
    }
    @media (min-width: $sm) {
      top: -10px;
      left: -8px;
      width: 24px;
      height: 24px;
    }

    > svg {
      width: 100%;
      height: 100%;
    }

    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      @media (max-width: ($sm - 0.02)) {
        font-size: 12px;
      }
      @media (min-width: $sm) {
        font-size: 14px;
      }
    }
  }

  &.selected {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  &.filled:not(.notDraggable) {
    cursor: grab;

    a {
      pointer-events: none;
    }

    &:active {
      cursor: grabbing;
    }
  }

  &.notDraggable {
    cursor: unset;
  }

  @media (max-width: ($sm - 0.02)) {
    min-width: 132px;
    margin-right: 4px;
    min-height: 26px;
    padding: 2px 10px;
  }
  @media (min-width: $sm) {
    min-width: 186px;
    margin-right: 6px;
    min-height: 36px;
    padding: 2px 10px;
  }
}

.answerBoxRightItems {
  @media (max-width: ($sm - 0.02)) {
    margin-left: 7px;
    width: 59px;
  }
  @media (min-width: $sm) {
    margin-left: 10px;
    width: 72px;
  }
}

.putInOrderCard {
  max-width: 100%;
  border: 3px solid transparent;
  margin: {
    left: auto;
    right: auto;
  }
  @media (max-width: ($sm - 0.02)) {
    padding: 18px 14px;
  }
  @media (min-width: $sm) {
    padding: 21px 18px;
    width: 920px;
  }

  .playButtonContainer {
    display: flex;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 60px;
    }
    @media (min-width: $sm) {
      margin-bottom: 85px;
    }
  }

  .phraseCardList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .phraseCard {
    white-space: nowrap;
    border: 1px solid;

    &.used {
      border-color: transparent !important;
      color: transparent !important;
    }

    @media (max-width: ($sm - 0.02)) {
      border-radius: 5px;
      font-size: 18px;
      padding: 2px 8px;
      margin: 5px;
    }
    @media (min-width: $sm) {
      border-radius: 8px;
      font-size: 22px;
      padding: 3px 12px;
      margin: 8px;
    }
  }

  .textHolder {
    width: 100%;

    @media (max-width: ($sm - 0.02)) {
      background-size: 14px;
      min-height: 45px;
    }
    @media (min-width: $sm) {
      background-size: 18px;
      min-height: 59px;
    }

    &.checked {
      min-height: unset !important;
    }

    .phraseCard {
      @media (max-width: ($sm - 0.02)) {
        margin: 6px 3px;
      }
      @media (min-width: $sm) {
        margin: 9px 5px;
      }
    }

    .text {
      //width:700px;
      //max-width: 100%;
      margin-bottom: 0;
      @media (max-width: ($sm - 0.02)) {
        font-size: 18px;
      }
      @media (min-width: $sm) {
        font-size: 22px;
      }
    }
  }
}

.putInOrderConversationContainer {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $sm) {
    width: 920px;
  }

  .exerciseCard {
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      padding: 12px;
    }
    @media (min-width: $sm) {
      padding: 32px;
    }

    &:not(:last-child) {
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 12px;
      }
      @media (min-width: $sm) {
        margin-bottom: 20px;
      }
    }
  }
}

.singleConversationWrapper {
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in;
  transform-origin: left center;

  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
  }
  @media (min-width: $sm) {
    font-size: 20px;
  }

  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      margin-bottom: 25px;
    }
  }

  > div {
    transition: all 0.5s ease-in;
  }

  &.disappear {
    pointer-events: none;
    transform: scale(0);
    opacity: 0;
    height: 0 !important;
    margin: 0;
  }

  .conversationAvatarName {
    font-weight: bold;
    @media (max-width: ($sm - 0.02)) {
      font-size: 10px;
    }
    @media (min-width: $sm) {
      font-size: 14px;
    }
  }

  .conversationDialogBox {
    flex: 1 1 auto;
  }

  .lockContainer {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dialogRightItems {
    @media (max-width: ($sm - 0.02)) {
      width: 75px;
      margin-left: 7px;
    }
    @media (min-width: $sm) {
      width: 102px;
      margin-left: 10px;
    }
  }

  .profile {
    @media (max-width: ($sm - 0.02)) {
      width: 29px;
    }
    @media (min-width: $sm) {
      width: 40px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar {
      position: relative;
      border: 2px solid transparent;

      @media (max-width: ($sm - 0.02)) {
        width: 29px;
        height: 29px;
      }
      @media (min-width: $sm) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .username {
      margin-top: 3px;
      text-align: center;
      font-weight: bold;
      font-size: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.writingMark {
  @media (max-width: ($sm - 0.02)) {
    font-size: 25px;
  }
  @media (min-width: $sm) {
    font-size: 36px;
  }
}

.typeInAnswerCard {
  @media (max-width: ($sm - 0.02)) {
    padding: 12px 14px;
  }
  @media (min-width: $sm) {
    padding: 17px 20px;
  }

  &.checked {
    &:not(.exerciseInCard) {
      border: 3px solid transparent;
    }

    .givenContainer {
      margin-bottom: 0;
    }
  }

  .textarea {
    background: transparent;
    width: 100%;
    border: 2px solid;
    outline: none;
    resize: none;
    @media (max-width: ($sm - 0.02)) {
      padding: 12px 9px;
      border-radius: 20px 0;
      font-size: 15px;
      min-height: 200px;
    }
    @media (min-width: $sm) {
      padding: 30px 20px;
      border-radius: 40px 0;
      font-size: 21px;
      min-height: 200px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.multipleChoiceCard,
.comboBoxCard {
  max-width: 100%;
  margin: auto;

  @media (max-width: ($sm - 0.02)) {
    padding: 14px 16px;
  }
  @media (min-width: $sm) {
    padding: 20px 23px;
  }

  :global(.col-6) {
    img {
      object-fit: contain;
      @media (max-width: ($sm - 0.02)) {
        width: 120px !important;
        height: 120px !important;
      }
      @media (min-width: $sm) {
        width: 180px !important;
        height: 180px !important;
      }
    }
  }

  :global(.col-sm-4) {
    img {
      object-fit: contain;
      @media (min-width: $sm) {
        width: 160px !important;
        height: 160px !important;
      }
    }
  }

  &.checked {
    &:not(.exerciseInCard) {
      border: 3px solid transparent;
    }

    &:not(&.multipleChoiceCard) {
      .givenContainer {
        margin-bottom: 0;
      }
    }
  }
}

.comboBoxContainer,
.putInOrderContainer,
.typeInAnswerContainer,
.multipleChoiceContainer {
  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 40px;
    }
    @media (min-width: $sm) {
      margin-bottom: 56px;
    }
  }
}

.choiceOptionsContainer {
  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 8px;
    }
    @media (min-width: $sm) {
      margin-bottom: 11px;
    }
  }
}

.choiceOptionWrapper {
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 8px;
    }
    @media (min-width: $sm) {
      margin-bottom: 11px;
    }
  }
}

.choiceOption {
  border-radius: 24px;
  text-align: center;
  max-width: 100%;
  height: fit-content;
  padding: 4px 10px;

  @media (max-width: ($sm - 0.02)) {
    min-height: 32px;
  }
  @media (min-width: $sm) {
    min-height: 46px;
  }

  &.selected {
    border: 3px solid;
  }

  &.choiceOptionLarge {
    @media (max-width: ($sm - 0.02)) {
      width: 320px;
    }
    @media (min-width: $sm) {
      width: 528px;
    }
  }

  &.choiceOptionSmall {
    @media (max-width: ($sm - 0.02)) {
      width: 150px;
    }
    @media (min-width: $sm) {
      width: 335px;
    }
  }
}

.textHolder {
  width: fit-content;
}

.blankSpace {
  @media (max-width: ($sm - 0.02)) {
    font-size: 20px;
  }
  @media (min-width: $sm) {
    font-size: 30px;
  }
}

.exerciseStack {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.givenContainer {
  max-width: 100%;
  flex: 1 1 auto;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 15px;
  }
  @media (min-width: $sm) {
    margin-bottom: 25px;
  }

  &.givenCenter {
    .givenTemplateContainer {
      &:global(.giventype-column),
      &:global(.giventype-column-reverse) {
        align-items: center;
      }

      &:global(.giventype-row),
      &:global(.giventype-row-reverse) {
        justify-content: center;
      }
    }
  }
}

.givenTemplateContainer {
  display: flex;

  .text {
    max-width: 100%;
  }

  img.image {
    object-fit: contain;
  }

  &:global(.giventype-column) {
    flex-direction: column;

    .image {
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
      @media (max-width: ($sm - 0.02)) {
        width: 100%;
        height: 100px;
        margin-bottom: 10px;
      }
      @media (min-width: $sm) {
        width: 100%;
        height: 140px;
        margin-bottom: 14px;
      }

      &:global(.shimmer) {
        width: auto;
      }
    }
  }

  &:global(.giventype-column-reverse) {
    flex-direction: column-reverse;

    .image {
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
      @media (max-width: ($sm - 0.02)) {
        width: 120px;
        max-height: 120px;
        margin-bottom: 10px;
      }
      @media (min-width: $sm) {
        width: 200px;
        max-height: 200px;
        margin-bottom: 14px;
      }
    }
  }

  &:global(.giventype-row) {
    flex-direction: row;

    .image {
      max-width: 50%;
      align-self: center;
      @media (max-width: ($sm - 0.02)) {
        width: 70px;
        max-height: 70px;
        margin-right: 10px;
      }
      @media (min-width: $sm) {
        width: 200px;
        max-height: 200px;
        margin-right: 14px;
      }
    }
  }

  &:global(.giventype-row-reverse) {
    flex-direction: row-reverse;
    justify-content: space-between;

    .image {
      max-width: 50%;
      align-self: center;
      @media (max-width: ($sm - 0.02)) {
        width: 70px;
        max-height: 70px;
        margin-right: 10px;
      }
      @media (min-width: $sm) {
        width: 200px;
        max-height: 200px;
        margin-right: 14px;
      }
    }
  }
}

.givenVoiceContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 5px;
  }
  @media (min-width: $sm) {
    margin-bottom: 10px;
  }
  display: flex;
  justify-content: center;
}

.middleInput {
  display: inline;
  outline: none;
  @media (max-width: ($sm - 0.02)) {
    border-bottom: 2px dotted;
  }
  @media (min-width: $sm) {
    border-bottom: 3.5px dotted;
  }

  &:empty {
    display: inline-block;
    @media (max-width: ($sm - 0.02)) {
      padding-right: 44px;
    }
    @media (min-width: $sm) {
      padding-right: 81px;
    }
  }
}

.cardDisabled {
  opacity: 0.6;
  pointer-events: none;
}

.comboBox {
  margin: 4px 0;
  max-width: 100%;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ($sm - 0.02)) {
    min-width: 53px;
    height: auto;
    border: 2px solid;
    padding: 0 5px;
  }
  @media (min-width: $sm) {
    min-width: 170px;
    height: auto;
    border: 3px solid;
    padding: 0 10px;
  }

  .comboBoxValue {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;

    @media (max-width: ($sm - 0.02)) {
      font-size: 16px;
    }
    @media (min-width: $sm) {
      font-size: 20px;
    }
  }
}

.exerciseCardFooter {
  display: inline-grid;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: ($sm - 0.02)) {
    height: 30px;
  }
  @media (min-width: $sm) {
    height: 43px;
  }

  &.footerLarge {
    @media (max-width: ($sm - 0.02)) {
      height: 33px;
    }
    @media (min-width: $sm) {
      height: 50px;
    }
  }
}

.selectedChoicesContainer {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.resetItemButton {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    width: 15px;
    height: 15px;
    margin: 0 8px;
  }
  @media (min-width: $sm) {
    width: 20px;
    height: 20px;
    margin: 0 12px;
  }
}

.highlightCardItem {
  transition: all 0.3s ease-out;
  box-shadow: 0 0 8px 6px var(--highlight-color);
  transform: scale(1.05);
  position: relative;
  z-index: 1;
}

@keyframes disappearDialog {
  to {
    transform: scale(0);
    opacity: 0;
    height: 0 !important;
    margin: 0;
  }
}
