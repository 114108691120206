@import '../../styles/globalConfigs';

.pageLayer {
  background: #2a2a2a;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1012;
  overflow: auto;
}
.mobile {
  overflow: auto;
}
