@import '../../styles/globalConfigs';

.templateViewTypeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    @media (max-width: ($sm - 0.02)) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: $sm) {
      width: 27px;
      height: 27px;
    }
  }
}
