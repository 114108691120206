@import './../../styles/globalConfigs';


.notFoundPage {
height: 100%;
  .container {
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

min-height: calc(100dvh - 70px);
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      text-align: center;

      h2 {
        //    padding: 20px 0;
        //    margin: auto;
        font-size: 40px !important;
        line-height: 52px !important;
        font-weight: 700 !important;
        @media (max-width: $sm) {
          font-size: 28px !important;
          line-height: 36px !important;
          font-weight: 700 !important;
        }
      }

      > p {
        margin-top: 30px;
        font-size: 22px;
        @media(max-width: $sm) {
          margin-top: 30px;
          font-size: 18px;

        }
      }
    }

    .vellsoTV {
      margin-bottom: 20px;
      width: 500px;
      height: 500px;
      @media(max-width: $sm) {
        width: 240px;
        height: 240px;

      }
    }
  }
}
