@import './../../../../../styles/globalConfigs';

.textComponent {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  border-bottom: 1px solid;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 4px;
    padding-bottom: 4px;
  }
  @media (min-width: $sm) {
    margin-bottom: 12px;
    padding-bottom: 8px;
  }
}
