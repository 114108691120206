@import './../../styles/globalConfigs';

.secondaryToplineTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .toplineTitleBefore,
  .toplineTitleAfter {
    width: 100%;
    @media (max-width: ($sm - 0.02)) {
      height: 1px;
    }
    @media (min-width: $sm) {
      height: 2px;
    }
  }

  .toplineTitle {
    width: fit-content;
    text-align: center;
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      border: 1px solid;
      border-radius: 10px;
      min-width: 94px;
      padding: 3px;
      font-size: 16px;
    }
    @media (min-width: $sm) {
      border: 2px solid;
      border-radius: 10px;
      min-width: 200px;
      padding: 5px;
      font-size: 22px;
    }
  }
}
