@import './../../../../styles/globalConfigs';

.generalTemplateContainer {
  display: grid;
  align-items: center;

  @media (max-width: ($sm - 0.02)) {
    gap: 14px;
  }

  @media (min-width: $sm) {
    gap: 20px;
  }

  .imageContainer {
    grid-area: image;
    //@media (max-width: ($sm - 0.02)) {
    //  width: 80px;
    //}
    //@media (min-width: $sm) {
    //  width: 230px;
    //}
  }

  .subjectsContainer {
    grid-area: subjects;
  }

  &.withoutImage {
    display: block;
  }

  &.generalImageTopContainer {
    grid-template-areas: 'image' 'subjects';
  }

  &.generalImageLeftContainer {
    @media (max-width: ($xsm - 0.02)) {
      grid-template-areas: 'image' 'subjects';
    }

    @media (min-width: $xsm) {
      grid-template-areas: 'image subjects';
      grid-template-columns: auto 70%;
    }
  }

  &.generalImageRightContainer {
    @media (max-width: ($xsm - 0.02)) {
      grid-template-areas: 'image' 'subjects';
    }

    @media (min-width: $xsm) {
      grid-template-areas: 'subjects image';
      grid-template-columns: 70% auto;
    }
  }
}

.mdTextCenter {
  @media (max-width: ($xsm - 0.02)) {
    text-align: center;
  }
}
