@import "../../styles/globalConfigs";

[data-jsx="PhrasesOptions"] {
  &.phrase-options {
    display: flex;
    align-items: center;

    &:not(:empty) {
      @media (max-width: ($sm - 0.02)) {
        margin: 0 7px;
      }
      @media (min-width: $sm) {
        margin-right: 10px;
      }
    }

    .option-item {
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        @media (max-width: ($sm - 0.02)) {
          margin-right: 6px;
        }
        @media (min-width: $sm) {
          margin-right: 10px;
        }

        &.speaker {
          @media (max-width: ($sm - 0.02)) {
            margin-right: 4px;
          }
          @media (min-width: $sm) {
            margin-right: 5px;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .speaker {
      @media (max-width: ($sm - 0.02)) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: $sm) {
        width: 30px;
        height: 30px;
      }
    }

    .more {
      @media (max-width: ($sm - 0.02)) {
        width: 20px;
        height: 20px;
      }
      @media (min-width: $sm) {
        width: 22px;
        height: 22px;
      }
    }
  }
}