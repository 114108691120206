@import './../../../../../styles/globalConfigs';

.imageContainer {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  cursor: pointer;
  max-width: 100%;

  img {
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      max-height: 200px;
    }
    @media (min-width: $sm) {
      max-height: 300px;
    }
  }
}

.imageCaption {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  @media (max-width: ($sm - 0.02)) {
    margin-top: 4px;
  }
  @media (min-width: $sm) {
    margin-top: 7px;
  }
}

.imageModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ($sm - 0.02)) {
    font-size: 15px;
  }
  @media (min-width: $sm) {
    font-size: 20px;
  }

  img {
    width: auto;
    height: auto;
    object-fit: contain;
    max-height: calc(100dvh - 100px);

    @media (max-width: ($sm - 0.02)) {
      max-width: calc(100vw - 25px);
    }
    @media (min-width: $sm) {
      max-width: calc(100vw - 100px);
    }
    //filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.5));
  }
}
