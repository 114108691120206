@import './../../styles/globalConfigs';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  position: relative;

  @media (max-width: ($sm - 0.02)) {
    width: 38px;
    height: 38px;
  }
  @media (min-width: $sm) {
    width: 45px;
    height: 45px;
  }

  > svg {
    width: 65%;
    height: 65%;
  }
}
