@import './../../styles/globalConfigs';

.introLeftLineText {
  transform: translateY(-3px);

  @media (max-width: ($sm - 0.02)) {
    height: 4px;
    width: 50px;
    margin-right: 10px;
  }
  @media (min-width: $sm) {
    height: 7px;
    width: 90px;
    margin-right: 10px;
  }
}
