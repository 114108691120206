@import "../../styles/globalConfigs";

[data-jsx="ResetButton"] {
  &.reset-button {
    display: flex;
    align-items: center;

    @media (max-width: ($sm - 0.02)) {
      font-size: 17px;
      margin-right: 20px;
    }
    @media (min-width: $sm) {
      font-size: 24px;
    }

    .reset-svg-icon {
      display: block;
      @media (max-width: ($sm - 0.02)) {
        margin-right: 5px;
        width: 17px;
      }
      @media (min-width: $sm) {
        margin-right: 7px;
        width: 24px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

