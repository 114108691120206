@import './../../../../styles/globalConfigs';

.imagesTemplateContainer {
  display: grid;
  align-items: center;

  @media (max-width: ($sm - 0.02)) {
    gap: 14px;
  }
  @media (min-width: $sm) {
    gap: 20px;
  }

  img {
    @media (max-width: ($sm - 0.02)) {
      max-height: 108px;
    }
    @media (min-width: $sm) {
      max-height: 200px;
    }
  }

  &.oneColumnContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  &.twoColumnContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  &.threeColumnContainer {
    @media (max-width: ($sm - 0.02)) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: $sm) {
      grid-template-columns: repeat(3, 1fr);
    }

    img,
    :global(.shimmer) {
      object-fit: contain;
      @media (min-width: $sm) {
        width: 160px;
        height: 160px;
      }
    }
  }
}
