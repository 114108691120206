@import 'styles/globalConfigs.scss';

.button {
  position: relative;
  @media (max-width: ($sm - 0.02)) {
    width: 42px;
    height: 42px;
  }
  @media (min-width: $sm) {
    width: 50px;
    height: 50px;
  }
}
