@import "../../../styles/globalConfigs";

[data-jsx="ReadingAndConversationIntro"] {
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      margin: 10px auto;
      height: 300px;
      width: 300px;
    }
    @media (min-width: $sm) {
      margin: 15px auto;
      height: 356px;
      width: 356px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      @media (max-width: ($sm - 0.02)) {
        height: 300px;
        width: 300px;
      }
      @media (min-width: $sm) {
        height: 360px;
        width: 360px;
      }
    }
  }
}