
.notificationWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.notificationContainer {
  margin: 14px 0;
  width: 528px;
  pointer-events: all;
  transform: translateY(0);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 0 20px;
}

.notificationEnter {
  opacity: 0;
  transform: translateY(-100%);
}

.notificationEnterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.notificationExit {
  opacity: 1;
  transform: translateY(0);
}

.notificationExitActive {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
