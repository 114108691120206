@import "../../styles/globalConfigs";

.circle-navigation-button {
  @media (max-width: ($sm - 0.02)) {
    width: 32px;
    height: 32px;
  }
  @media (min-width: $sm) {
    width: 40px;
    height: 40px;
  }
}