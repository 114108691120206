@import "../../../styles/globalConfigs";

[data-jsx="Reading"] {
  .reading-page-container {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 20px;
      min-height: calc(100dvh - 260px);
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      height: calc(100dvh - 237px);
      min-height: 300px;
    }
    @media (min-width: $md) {
      height: calc(100dvh - 290px);
      min-height: 300px;
    }
  }

  .reading-info-container {
    @media (max-width: ($sm - 0.02)) {
      top: -42px;
      right: -15px;
      position: absolute;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      top: -60px;
      right: -20px;
      position: absolute;
    }
    @media (min-width: ($md - 0.02)) {
      width: inherit;
      margin-right: 35px;
    }

    .reading-image-container {
      position: relative;
      margin: auto;

      @media (max-width: ($sm - 0.02)) {
        width: 91px;
        height: 91px;
      }
      @media (min-width: $sm) and (max-width: ($md - 0.02)) {
        width: 128px;
        height: 128px;
      }
      @media (min-width: $md) and (max-width: ($lg - 0.02)) {
        width: 257px;
      }
      @media (min-width: $lg) {
        width: 350px;
      }

      .play-btn-wrapper {
        position: absolute;
        right: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        @media (max-width: ($sm - 0.02)) {
          width: 38px;
          height: 38px;
          top: -15px;
          right: -5px;
        }
        @media (min-width: $sm) and (max-width: ($md - 0.02)) {
          width: 50px;
          height: 50px;
          top: -26px;
          right: -10px;
        }
        @media (min-width: $md) {
          width: 62px;
          height: 62px;
          top: -26px;
          right: -10px;
        }

        > button {
          width: 100%;
          height: 100%;
        }
      }

      .clock-wrapper {
        position: absolute;
        right: 0;
        border-radius: 50%;
        border: 1px solid;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .38);
        justify-content: center;
        align-items: center;
        display: flex;

        @media (max-width: ($sm - 0.02)) {
          width: 38px;
          height: 38px;
          top: -15px;
          right: -5px;
        }
        @media (min-width: $sm) {
          width: 68px;
          height: 68px;
          top: -30px;
          right: -13px;
        }

        > svg {
          width: 86%;
          height: 86%;
        }

        > .content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 600;
          font-style: italic;
          color: #F80;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);

          @media (max-width: ($sm - 0.02)) {
            font-size: 8px;
          }
          @media (min-width: $sm) and (max-width: ($md - 0.02)) {
            font-size: 13px;
          }
          @media (min-width: $md) {
            font-size: 13px;
          }
        }
      }

      .reading-image {
        border-radius: 10px;
        box-shadow: 0 3px 0 rgba(187, 175, 175, 0.45);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 7px;
        @media (min-width: $md) and (max-width: ($lg - 0.02)) {
          width: 257px;
          min-height: 240px;
        }
        @media (min-width: $lg) {
          width: 350px;
          min-height: 350px;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .warm-up-button-wrapper {
    display: flex;
    justify-content: center;

    .warm-up-button {
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      @media (max-width: ($sm - 0.02)) {
        width: 152px;
        height: 33px;
        font-size: 15px;
      }
      @media (min-width: $sm) {
        width: 213px;
        height: 46px;
        font-size: 21px;
      }
    }
  }

  .reading-title-card-container {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 15px;
      margin-left: 10px;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      margin-right: 40px;
      margin-left: 40px;
    }
    @media (min-width: $md) {
      width: 895px;
      max-width: 100%;
    }

    .mobile-title {
      display: flex;
      flex-direction: column;
      justify-content: end;
      font-style: italic;
      font-weight: 800;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      width: 100%;
      margin-bottom: 6px;

      @media (max-width: ($sm - 0.02)) {
        padding-right: 80px;
        min-height: 50px;
        font-size: 15px;
      }
      @media (min-width: $sm) and (max-width: ($md - 0.02)) {
        padding-right: 115px;
        min-height: 80px;
        font-size: 20px;
      }
      @media (min-width: $md) {
        display: none;
      }
    }
  }

  .reading-card-container {
    position: relative;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      padding: 6px;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      padding: 9px;
    }
    @media (min-width: $md) {
      padding: 12px;
    }

    .reading-card-wrapper {
      max-height: 100%;
      border-radius: 10px;
      border: 2px solid;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16) inset, 0 3px 6px rgba(0, 0, 0, .16);

      .reading-card {
        overflow: auto;
        padding-right: 12px;
        @media (max-width: ($sm - 0.02)) {
          max-height: calc(100dvh - 285px);
          padding: 15px 12px 20px 15px;
        }
        @media (min-width: $sm) and (max-width: ($md - 0.02)) {
          max-height: calc(100dvh - 372px);
          padding: 20px 18px;
        }
        @media (min-width: $md) {
          max-height: calc(100dvh - 370px);
          padding: 30px 27px;
        }

        .text {
          &:first-child {
            @media (max-width: ($sm - 0.02)) {
              margin-right: 58px;
            }
            @media (min-width: $sm) and (max-width: ($md - 0.02)) {
              margin-right: 85px;
            }
          }

          &:not(:last-child) {
            @media (max-width: ($sm - 0.02)) {
              margin-bottom: 12px;
            }
            @media (min-width: $sm) {
              margin-bottom: 20px;
            }
          }

          p {
            display: inline-block;
          }

          .phrase-options-wrapper {
            display: inline-block;
            padding: 6px 8px 0 8px;
          }
        }

        /* width */
        &::-webkit-scrollbar {
          width: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: rgba(112, 112, 112, 0.8);
          border-radius: 50rem;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
      }
    }
  }
}

[data-jsx="ReadingWarmUp"] {
  .warm-up-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100svh - 193px);
    @media (max-width: ($sm - 0.02)) {
      margin-top: 18px;
    }
    @media (min-width: $sm) {
      margin-top: 25px;
    }
  }

  .warm-up-vocabularies {
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      padding: 4px;
    }
    @media (min-width: $sm) {
      border-radius: 15px;
      padding: 6px;
    }

    .vocabulary-item {
      touch-action: none;
      border-radius: 50rem;
      overflow: hidden;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      a {
        pointer-events: none;
      }

      @media (max-width: ($sm - 0.02)) {
        font-size: 15px;
        margin: 7px;
        padding: 1px 9px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
        margin: 11px;
        padding: 1px 12px;
      }

      & * {
        transition: all 0.3s ease-in-out;
      }

      &.used {
        cursor: default;

        * {
          opacity: 0;
        }
      }
    }

    &.clickable {
      .vocabulary-item {
        cursor: pointer;

        > * {
          pointer-events: none;
        }
      }
    }
  }

  .warm-up-quiz {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ($sm - 0.02)) {
      margin: 27px 0;
    }
    @media (min-width: $sm) {
      margin: 39px 0;
    }

    .translate-wrapper {
      .translate-button {
        border-radius: 50%;
        @media (max-width: ($sm - 0.02)) {
          width: 29px;
          height: 29px;
          margin-left: 10px;
        }
        @media (min-width: $sm) {
          width: 41px;
          height: 41px;
          margin-left: 14px;
        }

        img {
          width: 64%;
          height: 64%;
          object-fit: contain;
        }
      }
    }

    .answer-box-options-wrapper {
      display: flex;
      align-items: center;
      @media (max-width: ($sm - 0.02)) {
        margin-left: 7px;
      }
      @media (min-width: $sm) {
        width: 32px;
        margin-right: 13px;
        justify-content: flex-end;
      }
    }

    .quiz-container {
      max-width: available;
      @media (max-width: ($sm - 0.02)) {
        padding: 7px 18px;
        border-radius: 10px;
        width: 100%;
      }
      @media (min-width: $sm) {
        padding: 10px 26px;
        border-radius: 15px;
        width: 994px;
      }

      .answer-box {
        touch-action: none;
        cursor: pointer;
        color: #FFF;
        border-radius: 20px;
        max-width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;

        * {
          transition: none;
        }

        &.selected {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }

        &.filled {
          cursor: grab;

          a {
            pointer-events: none;
          }

          &:active {
            cursor: grabbing;
          }
        }

        @media (max-width: ($sm - 0.02)) {
          min-width: 132px;
          margin-right: 4px;
          min-height: 26px;
          padding: 2px 10px;
        }
        @media (min-width: $sm) {
          min-width: 186px;
          margin-right: 6px;
          min-height: 36px;
          padding: 2px 10px;
        }
      }

      .quiz-text {
        margin-bottom: 0;
        align-self: center;

        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
          margin-left: 10px;
          margin-top: 5px;
          display: inline;
        }
        @media (min-width: $sm) {
          margin-left: 21px;
          font-size: 23px;
        }
      }
    }
  }
}

.reading-book-container {
  position: relative;
  height: calc(100dvh - 150px);
}

.reading-book {
  position: absolute;
  top: 50%;
  transform: translate(25%, -50%);
  right: 50%;
  margin: auto;
  animation: moveBook 0.8s cubic-bezier(.18, .89, .45, 1.36) 1.6s forwards;

  @media (max-width: ($sm - 0.02)) {
    width: 260px;
    height: 160px;
  }
  @media (min-width: $sm) {
    width: 520px;
    height: 300px;
  }
  perspective: 130rem;

  .cover {
    z-index: 1;
    border-radius: 10px 0 0 10px;
    background: #C6C6C5;
    border: 2px solid #FFF;
    transform: rotateY(160deg);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    @media (max-width: ($sm - 0.02)) {
      width: 130px;
      height: 160px;
    }
    @media (min-width: $sm) {
      width: 260px;
      height: 300px;
    }

    &.turn {
      z-index: 7;
      transform: rotateY(140deg);
      background: linear-gradient(to bottom, #C6C6C5, #FFFFFF);
      animation: bookOpenStep1 0.8s cubic-bezier(.18, .89, .45, 1.36) 0.8s forwards,
      bookOpenStep2 0.8s cubic-bezier(.18, .89, .45, 1.36) 1.6s forwards,
      bookOpenStep2Background 0.8s step-end 1.6s forwards;
    }
  }

  .page {
    top: 1%;
    left: 2.5%;
    background: #FFF;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px 3px 3px 10px;
    color: rgba(0, 0, 0, 0.59);
    font-style: italic;
    font-weight: 500;

    @media (max-width: ($sm - 0.02)) {
      width: 124px;
      height: 157px;
      font-size: 8px;
    }
    @media (min-width: $sm) {
      width: 247px;
      height: 294px;
      font-size: 16px;
    }

    &.page-1 {
      z-index: 6;
      animation: bookTurnPage1 0.6s ease-in-out 2.4s forwards;
      transform: rotateY(148deg);

      .page-content {
        animation: bookTurnPage1Content 0.6s ease-out 2.4s forwards;
      }
    }

    &.page-2 {
      z-index: 5;
      animation: bookTurnPage2 0.6s ease-in-out 2.42s forwards;
      transform: rotateY(150deg);
    }

    &.page-3 {
      z-index: 4;
      animation: bookTurnPage3 0.6s ease-in-out 3s forwards;
      transform: rotateY(153deg);

      .page-content {
        opacity: 0;
        animation: bookShowLastPages 0.6s ease-in 3s forwards;
      }
    }

    &.page-4 {
      z-index: 3;
      transform: rotateY(158deg);

      @media (max-width: ($sm - 0.02)) {
        left: 13px;
        width: 118px;
      }
      @media (min-width: $sm) {
        left: 25px;
        width: 237px;
      }
    }

    &.page-5 {
      z-index: 2;
      background: #f7f7f7;
      transform: rotateY(160deg);
    }

    .page-content {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &.revert {
        transform: rotateY(180deg);
      }
    }
  }

  .cover, .page {
    position: absolute;
    @media (max-width: ($sm - 0.02)) {
      padding: 0.5rem 0.9rem 0.5rem 0.5rem;
    }
    @media (min-width: $sm) {
      padding: 1rem;
    }
    transform-origin: 100% 0;
    box-sizing: border-box;
  }

  .cover-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: bookOpenStep2HideContent 0.8s ease-in 1.6s forwards;

    .cover-title {
      flex-grow: 1;
      min-height: fit-content;
      text-align: center;
      transform: rotateY(180deg);
      font-weight: 600;
      font-style: italic;
      @media (max-width: ($sm - 0.02)) {
        font-size: 16px;
        padding-bottom: 4px;
      }
      @media (min-width: $sm) {
        font-size: 20px;
        padding-bottom: 8px;
      }
    }

    .cover-image {
      flex-grow: 1;
      min-height: 0;
      min-width: 0;

      img {
        transform: rotateY(180deg);
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

@keyframes bookOpenStep1 {
  100% {
    transform: rotateY(120deg);
  }
}

@keyframes moveBook {
  100% {
    transform: translate(50%, -50%);
  }
}

@keyframes bookOpenStep2 {
  30% {
    z-index: -1;
  }
  100% {
    transform: rotateY(20deg);
    z-index: -1;
  }
}

@keyframes bookOpenStep2Background {
  10% {
    background: #C6C6C5;
  }
  100% {
    background: #C6C6C5;
  }
}

@keyframes bookOpenStep2HideContent {
  14% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bookTurnPage1 {
  100% {
    transform: rotateY(20deg);
    z-index: 2;
  }
}

@keyframes bookTurnPage1Content {
  30%, 100% {
    opacity: 0;
  }
}

@keyframes bookTurnPage2 {
  100% {
    transform: rotateY(22deg);
    z-index: 3;
  }
}

@keyframes bookTurnPage3 {
  100% {
    transform: rotateY(28deg);
    z-index: 4;
  }
}

@keyframes bookShowLastPages {
  30% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}