@import '../../styles/globalConfigs';

.micButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pendingDots {
  user-select: none;
  display: block;
  text-align: center;
  @media (max-width: ($sm - 0.02)) {
    font-size: 32px;
  }
  @media (min-width: $sm) {
    font-size: 65px;
  }
}

.speechModal {
  position: relative;
  overflow: hidden;
}

.loadingContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    @media (max-width: ($sm - 0.02)) {
      width: 56px;
      height: 56px;
    }
    @media (min-width: $sm) {
      width: 94px;
      height: 94px;
    }
  }
}

.warningIcon {
  display: inline-block;
  width: 15px;
  height: 15px;

  > svg {
    width: 100%;
    height: 100%;
  }
}
