@import './../../styles/globalConfigs';

.watchVideosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  padding: 27px 15px 22.5px 15px;
  border-radius: 20px 0 0 20px;
  position: fixed;
  right: 0;
  top: 30%;
  z-index: 2;

  @media (max-width: $sm) {
    gap: 10px;
    border: 1px solid;
    margin-right: 13px;
    top: 85%;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25);
  }

  > span {
    writing-mode: vertical-lr;
    text-decoration: underline;
    transform: rotate(180deg);
    font-size: 16px;
    @media (max-width: $sm) {
      font-size: 14px;
      display: none;
    }
  }

  > div {
    width: 27px;
    height: 27px;
    transform: rotate(-90deg);
    @media (max-width: $sm) {
      width: 24px;
      height: 24px;
      transform: rotate(0deg);
    }

    > svg {
      height: 100%;
      width: 100%;
    }
  }
}

.topImage {
  width: 700px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 40px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.descCard {
  padding: 24px 10px;
  text-align: center;
  border: 2px solid;
  border-radius: 15px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 20px;

  .title {
    margin-bottom: 15px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .iconContainer {
    border-radius: 50%;
    padding: 10px;

    .icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }
}

.helpItemContainer {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &[dir='rtl'] {
    .itemNumber {
      border-top-right-radius: 50rem;
      border-bottom-right-radius: 50rem;
      border-left: 0;
    }
  }

  &[dir='ltr'] {
    .itemNumber {
      border-top-left-radius: 50rem;
      border-bottom-left-radius: 50rem;
      border-right: 0;
    }
  }

  .itemNumber {
    width: 30px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 800;
    border: 2px solid;
    flex: 0 0 auto;
  }

  .itemCard {
    border-radius: 15px;
    border: 2px solid;
    min-height: 87px;
    width: 100%;
    padding: 20px;

    .title {
      display: flex;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;

      .icon {
        max-height: 30px;
        max-width: 105px;
      }
    }
  }
}
