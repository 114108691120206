@import './../../../../styles/globalConfigs';

.container {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    margin-bottom: 14px;
  }
}
