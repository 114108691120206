@import './../../../styles/globalConfigs.scss';

.wrapper {
  font-family: $Sahel, 'Roboto Flex', $SegoeUI, sans-serif;
  padding: 32px;
}

.title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.logo {
  width: 100%;
  max-width: 270px;
  margin-bottom: 72px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.card {
  border: 1px solid;
  border-radius: 10px;
  padding: 24px 12px;

  .descItem {
    color: #575756;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 20px;
  }
}

.inlineSvgIcon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
