@import './../../../../styles/globalConfigs';

.cardsContainer {
  display: grid;

  @media (max-width: ($sm - 0.02)) {
    grid-template-columns: repeat(2, calc(50% - 7px));
    gap: 14px;
  }
  @media (min-width: $sm) {
    grid-template-columns: repeat(2, calc(50% - 15px));
    gap: 30px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  img {
    width: 250px;
    max-height: 180px;
    aspect-ratio: 1;
    object-fit: contain;
  }
}

.picturesContainer > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: ($sm - 0.02)) {
    padding-bottom: 10px;
  }
  @media (min-width: $sm) {
    padding-bottom: 14px;
  }
}
