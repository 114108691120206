@import "../../styles/globalConfigs";

.circle-color-palette {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media (max-width: ($sm - 0.02)) {
    width: 235px;
    max-width: 100%;
  }
  @media (min-width: $sm) {
    width: 330px;
  }

  > svg {
    width: 100%;
    height: auto;
  }

  .color-item {
    cursor: pointer;
    &:hover {
      opacity: 90%;
    }
  }
}