@import "../../styles/globalConfigs";

.play-button-primary {
  border: 0;
  background: transparent;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  @media (max-width: ($sm - 0.02)) {
    width: 43px;
    height: 43px;
  }
  @media (min-width: $sm) {
    width: 60px;
    height: 60px;
  }

  .icon-container {
    display: flex;
    width: 50%;
    height: 50%;
    object-fit: contain;

    &.play-icon {
      transform: translateX(10%);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}