@import './../../../../styles/globalConfigs';

.singleImageTemplateContainer {
  img {
    @media (max-width: ($sm - 0.02)) {
      max-height: 300px;
    }
    @media (min-width: $sm) {
      max-height: 400px;
    }
  }
}
