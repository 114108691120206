.textAndIcon {
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  span {
    font-size: 18px;
  }
}
