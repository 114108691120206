@import "./colors.scss";

$xlg: 1800px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xsm: 576px;
$xxsm: 360px;

$SegoeUI: "Segoe UI";
$HelveticaRounded: "HelveticaRounded LT BoldCn";
$Sahel: "Sahel";
$RobotoFlex: "Roboto Flex";

$fontSize-3xSmall: 10px;
$fontSize-2xSmall: 11px;
$fontSize-xSmall: 12px;
$fontSize-smaller: 13px;
$fontSize-small: 14px;
$fontSize-medium: 16px;
$fontSize-large: 18px;
$fontSize-larger: 20px;
$fontSize-xLarge: 24px;
$fontSize-2xLarge: 28px;
$fontSize-3xLarge: 32px;
$fontSize-superLarge: 48px;

@mixin responsiveFontSize($fontSize) {
  font-size: $fontSize;
  @media (min-width: $sm) {
    font-size: calc($fontSize * 1.125);
  }
}

@mixin golden-background($direction) {
  background: linear-gradient(to $direction, #EBCA83, #FFFFD0, #E2B854, #F4C96C, #D09F26, #C2973B);
}

@mixin pink-background($direction) {
  background: linear-gradient(to $direction, #FD8A8F, #ED78A3, #D45CC2, #CB52CE, #7F32A7);
}