@import './../../../../../styles/globalConfigs';

.titleComponent {
  scroll-margin-top: 20vh;
  font-weight: 600;
  transition: opacity 300ms;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    font-size: 18px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 12px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    font-size: 22px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 18px;
  }
}
