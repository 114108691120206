@import './../../../styles/globalConfigs';

.exerciseSectionStacksContainer {
  display: flex;
  flex-direction: column;

  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 213px);
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 229px);
  }

  &.havePagination {
    @media (max-width: ($sm - 0.02)) {
      min-height: calc(100dvh - 250px);
    }
    @media (min-width: $sm) {
      min-height: calc(100dvh - 269px);
    }
  }
}

.skipButtonContainer {
  height: 24px;
  @media (min-width: 430px) {
    position: absolute;
  }
}

.skipButtonWrapper {
  position: relative;

  .skipButton {
    right: 0;
    position: absolute;
  }
}
