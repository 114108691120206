@import './../../styles/globalConfigs';

.container {
  will-change: transform;
  width: auto;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;
  margin-right: auto;
  margin-left: auto;

  span {
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 2px;
    display: block;
    border-radius: 50%;
    opacity: 0.3;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * 0.3333s) ease-in-out;
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0.6;
    transform: translateY(-2px);
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
