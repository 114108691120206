@import './../../styles/globalConfigs';

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 800;
  margin-bottom: 15px;

  .icon {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    > svg {
      width: 60%;
      height: 60%;
    }
  }
}

.textarea {
  width: 100%;
  outline: none;
  resize: none;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid;

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.options {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .optionItem {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.svgAndDescription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: $sm) {
    gap: 15px;
  }
  .svg {
    height: 50px;
    width: 50px;
    background-color: #fcfcfc;
    border-radius: 50%;
    @media (max-width: $sm) {
      height: 50px;
      width: 50px;
    }
    > svg {
      height: 100%;
      width: 100%;
    }
  }

  .description {
    font-size: 22px;
    @media (max-width: $sm) {
      font-size: 18px;
    }
  }
}
