@import './globalConfigs';

.sidebar-menu-wrapper {
  transition: background-color 0.4s;
  position: fixed;
  left: 0;
  top: 65px;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  width: 100vw;
  z-index: 1010;
  height: calc(100% - 65px);
.copy-right-text{
  font-size: 12px;
  padding: 0 35px 10px 10px;

}
  &.type--panel {
    width: 100%;

    @media (min-width: $md) {
      width: 320px;
    }

    &.active {
      @media (min-width: $md) {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }

  &.active {
    pointer-events: auto;
    background-color: #231f20cc;

    .sidebar-menu {
      left: 0;
      opacity: 1;
    }
  }
}

.sidebar-menu {
  position: fixed;
  height: inherit;
  left: -360px;
  transition: opacity 300ms, left 300ms;
  z-index: 9999;
  opacity: 0;
  overflow: auto;

  @media (max-width: ($sm - 0.02)) {
    border-radius: 0 30px 30px 0;
    width: 280px;
  }
  @media (min-width: $sm) {
    border-radius: 0 20px 20px 0;
    width: 320px;
  }

  .top-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: ($sm - 0.02)) {
      padding-left: 30px;
      padding-top: 23px;
      padding-right: 30px;
      margin-bottom: 18px;
    }
    @media (min-width: $sm) {
      padding-left: 36px;
      padding-top: 30px;
      padding-right: 40px;
      margin-bottom: 22px;
    }

    .buy-btn {
      width: 100%;
    }
  }

  .user-info {
    display: flex;
    align-items: flex-end;
    @media (max-width: ($sm - 0.02)) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: $sm) {
      padding-left: 36px;
      padding-right: 30px;
    }

    .user_name {
      font-weight: 300;
      margin-left: 13px;
      font-size: 16px;
    }
  }

  .heart-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .archive-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .back-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .menu-list {
    list-style: none;
    width: auto;
    @media (max-width: ($sm - 0.02)) {
      padding: 18px 30px 10px 0;
    }
    @media (min-width: $sm) {
      padding: 22px 40px 10px 0;
    }

    .menu-list-items {
      list-style: none;
      border-radius: 0 10px 10px 0;
      overflow: hidden;
      @media (max-width: ($sm - 0.02)) {
        padding-left: 30px;
        margin-bottom: 6px;
      }
      @media (min-width: $sm) {
        padding-left: 36px;
        margin-bottom: 9px;
      }
    }

    .menu-item {
      margin: {
        top: 9px;
        bottom: 9px;
      }

      //&:hover {
      //  .menu-item-link {
      //    opacity: 1;
      //  }
      //}
      //
      //&.active {
      //  .menu-item-link {
      //    opacity: 1;
      //  }
      //}

      .menu-item-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
      }

      .menu-item-icon {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 6px;
        width: 17px;
        height: 17px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .beta-version {
        position: absolute;
        right: 15px;
        font-size: 12px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.6;
      }
    }

    .sidebar-hr {
      height: 3px;
      background: linear-gradient(270deg, #464f85, #f2eff5);
      opacity: 1;
    }
  }

  .locked-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    display: flex;
    z-index: 0;
    opacity: 0.8;

    @media (max-width: ($sm - 0.02)) {
      right: calc(100% + 5px);
    }
    @media (min-width: $sm) {
      right: calc(100% + 10px);
    }

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .logout-btn {
    padding: 0 0 12px 28px;
  }

  .logout-icon {
    display: flex;
    width: 15px;
    height: 15px;
    margin-right: 4px;

    svg {
      width: 100%;
      height: auto;
    }
  }
}
