@import "../../styles/globalConfigs";

[data-jsx="Drill"] {
  .drill-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    min-height: calc(100vh - 65px - 65px);
    @supports (height: 100svh) {
      min-height: calc(100svh - 65px - 65px);
    }

    > * {
      width: 100%;
    }
  }

  .skip-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .drill-container {
    margin: auto;
    @media (max-width: ($sm - 0.02)) {
      max-width: 100%;
      margin-top: 30px;
    }
    @media (min-width: $sm) {
      max-width: 900px;
    }
  }

  .pagination-container {
    width: fit-content;
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      margin: 18px auto;
    }
    @media (min-width: $sm) {
      margin: 29px auto;
    }

    .pagination-wrapper {
      border: 1px solid #707070;
      border-radius: 50rem;
      @media (max-width: ($sm - 0.02)) {
        padding: 4px;
      }
      @media (min-width: $sm) {
        padding: 7px;
      }

      .pagination {
        border-radius: 50rem;
        width: 100%;
        overflow: auto;
          overscroll-behavior: none;
        // Hide scrollbar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        ul {
          list-style: none;
          margin: 0;
          display: flex;
          border-radius: 50rem;
          width: fit-content;
          @media (max-width: ($sm - 0.02)) {
            padding: 3px 6px;
          }
          @media (min-width: $sm) {
            padding: 4px 10px;
          }

          li {
            position: relative;
            display: block;
            border-radius: 50%;
            cursor: pointer;

            @media (max-width: ($sm - 0.02)) {
              width: 18px;
              height: 18px;
              font-size: 8px;
            }
            @media (min-width: $sm) {
              width: 25px;
              height: 25px;
              font-size: 14px;
            }

            &:not(:last-child) {
              @media (max-width: ($sm - 0.02)) {
                margin-right: 8px;
              }
              @media (min-width: $sm) {
                margin-right: 17px;
              }
            }

            text-align: center;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .sentence-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sentence-wrapper {
      flex: 1 1;
      @media (max-width: ($sm - 0.02)) {
        padding: 0 8px;
      }
      @media (min-width: $sm) {
        padding: 0 15px;
      }

      p {
        text-align: center;
        margin: 0;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 23px;
        }
      }
    }
  }

  .drill-box {
    width: 100%;
    box-shadow: 4px 3px 6px rgba(0, 0, 0, 0.30);
    border: 2px solid;

    @media (max-width: ($sm - 0.02)) {
      min-height: 136px;
      border-radius: 15px;
      padding: 7px 10px;
      margin: 18px auto;
    }
    @media (min-width: $sm) {
      min-height: 192px;
      border-radius: 24px;
      padding: 10px 14px;
      margin: 29px auto 12px;
    }

    .gender-icon-wrapper {
      > svg {
        @media (max-width: ($sm - 0.02)) {
          height: 36px;
          width: 36px;
        }
        @media (min-width: $sm) {
          width: 48px;
          height: 48px;
        }
      }

    }

    .drill-input-wrapper {
      position: relative;
      flex: 1 1 auto;
      margin: auto;
      @media (max-width: ($sm - 0.02)) {
        font-size: 16px;
        width: calc(100% - 50px);
        min-height: 40px;
      }
      @media (min-width: $sm) {
        font-size: 23px;
        width: calc(100% - 80px);
        min-height: 50px;
      }
    }

    .drill-input {
      overflow: hidden;
      top: 0;
      left: 0;
      position: absolute;
      border: none;
      outline: none;
      margin: auto;
      resize: none;
      width: 100%;
      height: 100%;
      background-color: transparent;
      padding: 0;
      @media (max-width: ($sm - 0.02)) {
        background-size: 13.7px;
      }
      @media (min-width: $sm) {
        background-size: 19.7px;
      }
    }

    .drill-input-cover {
      pointer-events: none;
      color: transparent;
      transition: none !important;
    }

    .phrase-options {
      margin: 0;
      justify-content: center;
    }

    .over-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: ($sm - 0.02)) {
        height: 32px;
      }
      @media (min-width: $sm) {
        height: 45px;
      }
    }

    .under-input {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @media (max-width: ($sm - 0.02)) {
        margin-top: 10px;
      }
      @media (min-width: $sm) {
        margin-top: 20px;
      }
    }

    .reset-drill-item {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ($sm - 0.02)) {
        width: 15px;
        height: 15px;
        margin: 0 8px;
      }
      @media (min-width: $sm) {
        width: 20px;
        height: 20px;
        margin: 0 12px;
      }
    }
  }

  .clue-and-number-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 20px;
    }
    //@media (min-width: $sm) {
    //  margin-bottom: 30px;
    //}

    .organizer {
      @media (max-width: ($sm - 0.02)) {
        width: 43px;
        height: 43px;
      }
      @media (min-width: $sm) {
        width: 60px;
        height: 60px;
      }
    }
  }
}