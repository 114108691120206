@import './../../styles/globalConfigs';

.voiceButton {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    position: relative;
    width: 36px;
    height: 36px;

    img.flag {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 34px;
      height: 34px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      z-index: 1;
    }

    .speaker {
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 50%;
        width: auto;
      }
    }
  }
}
