@import '../../../styles/globalConfigs';

[data-jsx='RoboChat'] {
  .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ($sm - 0.02)) {
      margin-top: 19px;
    }
    @media (min-width: $sm) {
      margin-top: 27px;
    }
  }

  .robo-chat-container {
    min-height: calc(100dvh - 183px);
    display: flex;
    flex-direction: column;
  }

  .chat-preview-wrapper {
    flex: 1 1 auto;
    background-blend-mode: multiply;
    margin: {
      right: auto;
      left: auto;
    }
    max-width: 100%;
    position: relative;
    overflow: auto;
    min-height: 150px;

    @media (max-width: ($sm - 0.02)) {
      width: 100%;
      height: 270px;
      border-radius: 8px;
      margin-top: 7px;
    }
    @media (min-width: $sm) {
      width: 1006px;
      height: 330px;
      border-radius: 12px;
      margin-top: 10px;
    }

    .speaker-playlist {
      display: flex;
      margin-left: auto;
      @media (max-width: ($sm - 0.02)) {
        width: 22px;
        height: 14px;
        margin-right: -6px;
      }
      @media (min-width: $sm) {
        width: 30px;
        height: 19px;
        margin-right: -6px;
      }
    }

    .chat-preview-inner {
      @media (max-width: ($sm - 0.02)) {
        padding: 14px 18px;
      }
      @media (min-width: $sm) {
        padding: 19px 25px;
      }
    }

    .chat-box-wrapper {
      display: flex;
      align-items: flex-start;

      &.left {
        flex-direction: unset;
        transform-origin: 0 50%;
      }

      &.right {
        flex-direction: row-reverse;
        transform-origin: 100% 50%;
      }

      &.chat-box-motion-with-delay {
        opacity: 0;
        transform: scale(0);

        &:not(:first-child) {
          animation: show-dialog 0.3s linear forwards 1.2s;
        }

        &:first-child {
          animation: show-dialog 0.3s linear forwards 0.3s;
        }
      }

      @keyframes show-dialog {
        from {
          opacity: 0;
          transform: scale(0);
        }
        to {
          opacity: 1;
          transform: scale(1);
        }
      }

      &.robo-chat-wrong-dialog {
        animation: show-dialog 0.3s ease-in-out forwards, show-dialog 0.3s ease-in-out 3s forwards reverse;
      }

      .profile {
        .avatar {
          border-radius: 50%;
          overflow: hidden;

          @media (max-width: ($sm - 0.02)) {
            width: 29px;
            height: 29px;
          }
          @media (min-width: $sm) {
            width: 40px;
            height: 40px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .username {
          margin-top: 3px;
          display: block;
          text-align: center;
          font-weight: 500;
        }
      }

      .chat-box {
        width: 50%;

        &.toRight {
          @media (max-width: ($sm - 0.02)) {
            margin-right: 10px;
          }
          @media (min-width: $sm) {
            margin-right: 14px;
          }
        }

        &.toLeft {
          @media (max-width: ($sm - 0.02)) {
            margin-left: 10px;
          }
          @media (min-width: $sm) {
            margin-left: 14px;
          }
        }
      }

      .cancel-icon {
        @media (max-width: ($sm - 0.02)) {
          font-size: 28px;
        }
        @media (min-width: $sm) {
          font-size: 36px;
        }
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    .phrase-options {
      @media (max-width: ($sm - 0.02)) {
        height: 42px;
      }
      @media (min-width: $sm) {
        height: 58px;
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      transition: all 0.3s;
      background: transparent;
      border-radius: 50rem;
    }

    /* Handle on hover */
    &:hover::-webkit-scrollbar-thumb {
      background: rgba(112, 112, 112, 0.8);

      &:active {
        background: #707070;
      }
    }
  }

  .ready-chats {
    @media (max-width: ($sm - 0.02)) {
      margin: {
        top: 18px;
        bottom: 7px;
      }
    }
    @media (min-width: $sm) {
      display: flex;
      flex-wrap: wrap;
      margin: {
        top: 22px;
        bottom: 10px;
      }
    }

    .ready-chat-container {
      display: flex;
      padding-bottom: 18px;

      @media (max-width: ($sm - 0.02)) {
        flex-direction: row-reverse;
        justify-content: center;
      }
      @media (min-width: $sm) {
        width: 50%;
        &:nth-child(even) {
          justify-content: flex-start;
          padding-left: 20px;
        }

        &:nth-child(odd) {
          justify-content: flex-end;
          padding-right: 20px;
        }
      }

      .send-button-wrapper {
        button {
          @media (max-width: ($sm - 0.02)) {
            width: 25px;
            height: 25px;
            margin-left: 9px;
          }
          @media (min-width: $sm) {
            width: 36px;
            height: 36px;
            margin-right: 14px;
          }
        }
      }

      .ready-chat {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: ($sm - 0.02)) {
          border-radius: 15px;
          padding: 4px 6px 4px 8px;
          width: 268px;
          min-height: 27px;
        }
        @media (min-width: $sm) {
          border-radius: 20px;
          padding: 5px 16px 5px 24px;
          width: 372px;
          min-height: 34px;
        }

        p {
          margin: 0;
        }

        .sentence-options {
          display: flex;
          align-items: center;

          span {
            display: inline-flex;

            img {
              width: auto;
              height: auto;
              max-height: 100%;
              max-width: 100%;
              object-fit: fill;
            }
          }

          .speaker {
            @media (max-width: ($sm - 0.02)) {
              width: 14px;
            }
            @media (min-width: $sm) {
              width: 20px;
            }
          }

          .more {
            @media (max-width: ($sm - 0.02)) {
              width: 20px;
              margin-left: 12px;
            }
            @media (min-width: $sm) {
              width: 28px;
              margin-left: 15px;
            }
          }
        }

        .robo-chat-option {
          * {
            pointer-events: none;
          }
        }
      }
    }
  }

  .chat-input-container {
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .input-wrapper {
      flex: 1 1;
      @media (min-width: $sm) {
        max-width: 786px;
      }

      input {
        border-radius: 50rem;
        border: 0;
        outline: unset;
        width: 100%;

        @media (max-width: ($sm - 0.02)) {
          padding: 2px 23px;
          height: 40px;
          font-size: 15px;
        }
        @media (min-width: $sm) {
          padding: 2px 23px;
          height: 57px;
          font-size: 17px;
        }
      }
    }

    .submit-button-wrapper {
      button {
        @media (max-width: ($sm - 0.02)) {
          width: 40px;
          height: 40px;
        }
        @media (min-width: $sm) {
          width: 57px;
          height: 57px;
        }
      }
    }

    .ai-chat-button {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      @media (max-width: ($sm - 0.02)) {
        width: 40px;
        height: 40px;
      }
      @media (min-width: $sm) {
        width: 57px;
        height: 57px;
      }

      > svg {
        width: 65%;
        height: 65%;
      }
    }
  }

  .finish-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: ($sm - 0.02)) {
      margin-top: 31px;
    }
    @media (min-width: $sm) {
      margin-top: 43px;
      max-width: 1006px;
    }
  }
}
