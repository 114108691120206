@import './../../styles/globalConfigs';

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 800;
  margin-bottom: 15px;

  .icon {
    flex: 0 0 auto;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    > svg {
      width: 65%;
      height: 65%;
    }
  }
}

.contentCard {
  border-radius: 7px;
  padding: 15px 10px;
  border: 1px solid;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), inset 0 3px 6px rgba(0, 0, 0, 0.16);

  .feedbackIcons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    > button {
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
