@import './../../../styles/globalConfigs';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    width: 12px;
  }
  @media (min-width: $sm) {
    width: 15px;
  }

  > svg {
    width: 100%;
    height: auto;
  }
}
