@import './../../../../styles/globalConfigs';

.cards {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    gap: 15px;
    width: 300px;
  }
  @media (min-width: $sm) {
    gap: 20px;
    width: 500px;
  }
}

.card {
  position: relative;
  border-radius: 10px;
  border: 2px solid;
  @media (max-width: ($sm - 0.02)) {
    padding: 57px 15px 10px;
    margin-top: 26px;
  }
  @media (min-width: $sm) {
    padding: 65px 15px 15px;
    margin-top: 31px;
  }

  .circle {
    text-align: center;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.25), 0 3px 6px rgba(0, 0, 0, 0.25);

    @media (max-width: ($sm - 0.02)) {
      width: 90px;
      height: 90px;
      top: -30px;
    }
    @media (min-width: $sm) {
      width: 100px;
      height: 100px;
      top: -40px;
    }
  }

  .radioButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
