@import './../../../styles/globalConfigs';

.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 213px);
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 229px);
  }
}
.image {
  margin: 0 auto;
  @media (max-width: $sm) {
    width: 200px;
  }
  width: 300px;
  height: auto;
  > img {
    height: 100%;
    width: 100%;
  }
}
.reviewMistakesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > div {
    @media (max-width: ($sm - 0.02)) {
      margin: 10px;
    }
    @media (min-width: $sm) {
      margin: 10px;
    }
  }
}

.pageImage {
  text-align: center;
}
