.container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1013;
  top: 0;
  left: 0;
  transition: 0.1s linear;
  pointer-events: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  &.dragging {
    .card {
      transition: none !important;
    }
  }

  &.show {
    opacity: 1;
    pointer-events: auto;

    .card {
      transform: translateY(0);
    }
  }
}

.sheetOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
}

.card {
  border-radius: 20px 20px 0 0;
  width: 100%;
  max-width: 1150px;
  min-height: 120px;
  max-height: 100dvh;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(100%);
  transition: 0.3s ease;

  &.hasSpace {
    border-radius: 20px;
    max-height: calc(100dvh - 20px);
    width: calc(100% - 20px);
    bottom: 10px;
  }

  .header {
    cursor: grab;
    padding: 15px 0 15px;
    display: flex;
    justify-content: center;

    .topLine {
      pointer-events: none;
      width: 45px;
      height: 3px;
      border-radius: 50rem;
    }
  }

  .body {
    padding: 5px 25px 15px;
    max-height: calc(100dvh - 53px);
    overflow: auto;
  }
}
