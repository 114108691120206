@import '../../styles/globalConfigs';

.helpButton {
  display: flex;

  &.absolute {
    position: absolute;
    cursor: pointer;

    @media (max-width: ($sm - 0.02)) {
      right: 20px;
      top: 28px;
    }
    @media (min-width: $sm) {
      right: 48px;
      top: 33px;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  > svg {
    @media (max-width: ($sm - 0.02)) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: $sm) {
      width: 27px;
      height: 27px;
    }
  }
}
