@import './../../styles/globalConfigs';

.pageContainerSingleView {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 65px - 52px - 10px);
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 65px - 55px - 10px);
  }
}

.pageContainerListView {
  .structureTemplatesContainer {
    @media (max-width: ($sm - 0.02)) {
      min-height: calc(100dvh - 65px - 52px - 10px - 40px);
    }
    @media (min-width: $sm) {
      min-height: calc(100dvh - 200px);
    }
  }
}

.paginateContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.singleViewBottomSection {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sliderContainer {
  position: relative;
  height: 28px;
  width: 100px;

  :global(.swiper-pagination) {
    bottom: 50%;
    transform: translate(-50%, -50%);

    :global(.swiper-pagination-bullet) {
      opacity: 1 !important;
    }
  }

  &:global(.theme-dark-purple) {
    :global(.swiper-pagination-bullet) {
      background: #fcfcfc80 !important;
    }
    :global(.swiper-pagination-bullet-active) {
      background: #ff8800 !important;
    }
  }

  &:global(.theme-light-purple) {
    :global(.swiper-pagination-bullet) {
      background: #e2dfe5 !important;
    }
    :global(.swiper-pagination-bullet-active) {
      background: #604581 !important;
    }
  }

  &:global(.theme-scandinavian) {
    :global(.swiper-pagination-bullet) {
      background: #fcfcfc80 !important;
    }
    :global(.swiper-pagination-bullet-active) {
      background: #0494b0 !important;
    }
  }

  &:global(.theme-light-scandinavian) {
    :global(.swiper-pagination-bullet) {
      background: #fcfcfc !important;
    }
    :global(.swiper-pagination-bullet-active) {
      background: #007991 !important;
    }
  }

  &:global(.theme-dark) {
    :global(.swiper-pagination-bullet) {
      background: #464a61 !important;
    }
    :global(.swiper-pagination-bullet-active) {
      background: #e4e5ec !important;
    }
  }
}

.bottomPageContainer {
  z-index: 5;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  @media (max-width: ($sm - 0.02)) {
    bottom: max(var(--safe-area-bottom), 10px);
  }
  @media (min-width: $sm) {
    bottom: max(var(--safe-area-bottom), 20px);
  }
}

.AIButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;

  > svg {
    width: 100%;
    height: 100%;
  }
}
