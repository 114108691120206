@import "../../styles/globalConfigs";

.colorpalette-popover {
  @media (max-width: ($sm - 0.02)) {
    width: 160px;
  }
  @media (min-width: $sm) {
    width: 210px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:not(:last-child) {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #DBDEF1;
      }
    }
  }

  .word-title {
    flex: 1;
    margin-bottom: 0;
    @media (max-width: ($sm - 0.02)) {
      font-size: 12px;
    }
    @media (min-width: $sm) {
      font-size: 16px;
    }
  }

  .color-badge {
    display: block;
    border-radius: 50%;
    @media (max-width: ($sm - 0.02)) {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    @media (min-width: $sm) {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
}