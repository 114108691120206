@import './../../styles/globalConfigs';

.input {
  border-radius: 8px;
  border: 0;
  width: 100%;
  height: 42px;

  @media (max-width: ($sm - 0.02)) {
    padding: 3px 12px;
    font-size: 16px;
  }
  @media (min-width: $sm) {
    padding: 5px 16px;
    font-size: 18px;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}

.inputContainer {
  position: relative;
}

.visibilityButton {
  position: absolute;
  cursor: pointer;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;

  @media (max-width: ($sm - 0.02)) {
    right: 10px;
  }
  @media (min-width: $sm) {
    right: 14px;
  }

  svg {
    width: 100%;
    height: auto;
  }
}
