@import './../../styles/globalConfigs';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  @media (max-width: $sm) {
    gap: 7px;
  }
  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    object-fit: cover;

    @media (max-width: $sm) {
      height: 20px;
      width: 20px;
    }
  }
  span {
    text-transform: capitalize !important;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: $sm) {
      font-size: 14px;
    }
  }
}
