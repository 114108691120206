@import './../../../../../styles/globalConfigs';

.textTitleComponent {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    margin-bottom: 4px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    margin-bottom: 12px;
  }
}
