@import './../../styles/globalConfigs';

.pillSmButton {
  border: 1px solid;
  border-radius: 50rem;
  @media (max-width: ($sm - 0.02)) {
    font-size: 13px;
    height: 25px;
    min-width: 55px;
  }
  @media (min-width: $sm) {
    font-size: 16px;
    min-height: 33px;
    min-width: 70px;
  }
}
