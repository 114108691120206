@import '../../styles/globalConfigs';

.exerciseItemCheckContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    margin-top: 0;
  }
  @media (min-width: $sm) {
    margin-top: 0;
  }
}

.exerciseItemCheckButton {
  border-radius: 50rem;
  min-width: 85px;
  height: 27px;
  border: 0.75px solid;
  font-size: 14px;
  font-weight: 600;

  &.disabled {
    background: linear-gradient(to right, #ffffff, #d0d2d3) !important;
    cursor: default;
    color: #c4c9ce !important;
  }
}
