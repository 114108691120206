@import './../../styles/globalConfigs';

.toplineTitleWrapper {
  @media (max-width: ($sm - 0.02)) {
    margin-top: 20px;
  }

  .toplineTitle {
    position: relative;
    width: fit-content;
    min-width: 140px;
    max-width: calc(100% - 20px);
    text-align: center;
    border-radius: 15px;
    padding: 6px 20px;
    margin: 0 auto;
    z-index: 2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.29);

    @media (min-width: $md) {
      border-radius: 17px;
      min-width: 200px;
      padding: 8px 24px;
    }
    .text {
      font-weight: 500;
      margin-bottom: 0;

      * {
        margin-bottom: 0;
        color: inherit;
      }
    }

    .toplineTitleBefore,
    .toplineTitleAfter {
      position: absolute;
      display: inline-block;
      height: 2px;
      top: calc(50% - 1px);
      width: calc(50vw - 100% / 2 - 10px);
      z-index: 1;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }

    .toplineTitleBefore {
      right: calc(100% + 10px);
    }

    .toplineTitleAfter {
      left: calc(100% + 10px);
    }
  }
}
