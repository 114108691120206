@import './../../styles/globalConfigs';

.mistakeInfoButton {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.typoWord {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1.5px;
}
