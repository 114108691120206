@import '../../styles/globalConfigs';

.phraseOptionsWrapper {
  display: inline-flex;
  align-items: center;
  transform: translateY(20%);
}

.textHolder {
  scroll-margin-top: 20vh;

  & * {
    transition: none;
  }
}

:global(.textHolderColors-disabled) {
  .textHolder * {
    color: inherit !important;
  }
}

:global(.disable-words-link) .word {
  pointer-events: none;
}

.word {
  white-space: pre;
  display: inline-block;

  &:before {
    //transition: 300ms;
  }
}

.highlighted {
  position: relative;
  z-index: 1;
  text-shadow: 0 0 7px #ffff00b5, 0 0 10px #ffff00b5, 0 0 21px #ffff00b5, 0 0 42px #ffff00b5, 0 0 82px #ffff00b5,
    0 0 92px #ffff00b5, 0 0 102px #ffff00b5, 0 0 151px #ffff00b5;
  transition: text-shadow 0.2s;

  //&:after {
  //  content:'';
  //  z-index: -1;
  //  display: block;
  //  position: absolute;
  //  background: rgba(0, 0, 0, 0.6);
  //  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
  //  width: 100%;
  //  height: 100%;
  //  border-radius: 5px;
  //}
  //animation: scale-up-motion 300ms linear forwards, scale-up-motion 300ms linear 1000ms reverse forwards;
}

@keyframes scale-up-motion {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
