@import './../../styles/globalConfigs';

.skip {
  .fixed {
    position: absolute;
    right: 50px;
    bottom: 25px;
    @media (min-width: 1200px) {
      right: 80px;
      bottom: 36px;
    }
  }

  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
  }
  @media (min-width: $sm) {
    font-size: 20px;
  }

  svg {
    display: inline-block;

    @media (max-width: ($sm - 0.02)) {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }
    @media (min-width: $sm) {
      width: 20px;
      height: 20px;
      margin-left: 12px;
    }
  }
}
