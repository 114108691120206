@import './../../../../../styles/globalConfigs';

.subjectContainer {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 25px;
    }
    @media (min-width: $sm) {
      margin-bottom: 45px;
    }
  }

  > div:last-child {
    margin-bottom: 0 !important;
  }
}
