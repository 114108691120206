@import '../../styles/globalConfigs';

.speechCircleButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 50%;

  @media (max-width: ($sm - 0.02)) {
    width: 38px;
    height: 38px;
  }
  @media (min-width: $sm) {
    width: 57px;
    height: 57px;
  }
  > div {
    > svg {
      width: 53%;
      height: auto;
      margin: auto;
    }
  }

  &.isSmall {
    @media (max-width: ($sm - 0.02)) {
      width: 32px;
      height: 32px;
    }
    @media (min-width: $sm) {
      width: 45px;
      height: 45px;
    }
  }

  &.isLarge {
    @media (max-width: ($sm - 0.02)) {
      width: 52px;
      height: 52px;
    }
    @media (min-width: $sm) {
      width: 90px;
      height: 90px;
    }
  }

  &.recording {
    > div {
      > svg {
        animation: wave_scale 1.4s ease-in-out infinite;
      }
    }
  }

  &.isReset {
    border: 0;
    > div {
      > svg {
        width: 100%;
      }
    }
  }
}

.loadingContainer {
  position: absolute;
  width: 110%;
  height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .spinner {
    width: 100%;
    height: 100%;
  }
}

@keyframes wave_scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
