@import './../../../../styles/globalConfigs';

.tableContainer {
  border: 1px solid;
  border-radius: 20px;
  overflow: hidden;
  margin: auto;

  .outer {
    margin: -1.5px;
  }

  .inner {
    min-width: 100%;
    min-height: 100%;
    width: fit-content;
    height: fit-content;
  }
}

.table {
  overflow: auto;
  display: grid;

  &.alignLeft {
    .textContainer {
      text-align: left;
    }

    .rotated270 {
      align-items: flex-start;
    }

    .rotated90 {
      align-items: flex-end;
    }
  }

  &.alignRight {
    .textContainer {
      text-align: right;
    }

    .rotated270 {
      align-items: flex-end;
    }

    .rotated90 {
      align-items: flex-start;
    }
  }

  &.alignCenter {
    .textContainer {
      text-align: center;
    }

    .rotated270,
    .rotated90 {
      align-items: center;
    }
  }

  .tableCell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid;
    border-right: 1px solid;

    &.tableHeaderCell {
      font-weight: 600;
    }
  }
}

.fullScreenButtonWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 10px;
    margin-right: 10px;
  }
  @media (min-width: $sm) {
    margin-bottom: 14px;
    margin-right: 14px;
  }
}

.fullScreenButton {
  @media (max-width: ($sm - 0.02)) {
    width: 16px;
    height: 16px;
  }
  @media (min-width: $sm) {
    width: 30px;
    height: 30px;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}

.textContainer {
  flex: 1;

  @media (max-width: ($sm - 0.02)) {
    padding: 6px 4px;
  }
  @media (min-width: $sm) {
    padding: 15px 10px;
  }

  &.rotated90 {
    writing-mode: vertical-rl;

    :global(.phrase-options) {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }

  &.rotated270 {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: flex;

    :global(.phrase-options) {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}

.pictureContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: ($sm - 0.02)) {
    padding: 3px;
  }
  @media (min-width: $sm) {
    padding: 5px;
  }

  button {
    max-width: 150px;
  }

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
}

.tableModal {
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (orientation: portrait) {
    height: 100vh;
    width: 100vw;
  }

  @media (orientation: landscape) {
    transform: translate(-50%, -50%) rotate(90deg);
    height: 100vw;
    width: 100vh;
  }

  @media (max-width: ($sm - 0.02)) {
    transform: translate(-50%, -50%);
    height: calc(100dvh - 70px);
    width: calc(100vw - 20px);
    top: calc(50% + 20px);
  }
  @media (min-width: $sm) {
    transform: translate(-50%, -50%);
    height: calc(100dvh - 100px);
    width: calc(100vw - 100px);
  }

  .tableContainer {
    width: 100%;

    .inner {
      height: 100%;
      overflow: auto;
      @media (max-width: ($sm - 0.02)) {
        max-height: calc(100dvh - 70px);
        max-width: calc(100vw - 20px);
      }
      @media (min-width: $sm) {
        max-height: calc(100dvh - 100px);
        max-width: calc(100vw - 100px);
      }
    }
  }
}
