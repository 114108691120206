@import './../../../styles/globalConfigs';

.generalTemplateWrapper {
  scroll-margin-top: 20vh;
}

.templateContainer {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 15px;
    font-size: 16px;
  }
  @media (min-width: $sm) {
    margin-bottom: 22px;
    font-size: 18px;
  }

  &:not(.cardsSeparated) {
    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      padding: 10px;
    }
    @media (min-width: $sm) {
      border-radius: 20px;
      padding: 20px;
    }
  }

  &.cardsSeparated {
    background: transparent !important;
  }

  &.border {
    @media (max-width: ($sm - 0.02)) {
      border: 2px solid transparent;
    }
    @media (min-width: $sm) {
      border: 3px solid transparent;
    }
  }
}

.templateCard {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  &.joined:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 36px;
    }
    @media (min-width: $sm) {
      margin-bottom: 60px;
    }
  }
}

.headerAndTemplatesContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 36px;
  }
  @media (min-width: $sm) {
    margin-bottom: 60px;
  }
}

.roboTeacherView {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
}
