@import '../../styles/globalConfigs';

.inputGroup {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 15px;
  }
  @media (min-width: $sm) {
    margin-bottom: 20px;
  }
}

.modalInner {
  margin: auto;
  max-width: calc(100% - 40px);
  @media (max-width: ($sm - 0.02)) {
    width: 360px;
    margin: 15px auto;
  }
  @media (min-width: $sm) {
    width: 446px;
    margin: 20px auto;
  }
}

.label {
  display: block;
  margin-bottom: 10px;
  @media (max-width: ($sm - 0.02)) {
    font-size: 14px;
  }
  @media (min-width: $sm) {
    font-size: 16px;
  }
}

.input {
  background: transparent;
  border: none;
  outline: 0;
  border-bottom: 1px solid;
  width: 100%;
  @media (max-width: ($sm - 0.02)) {
    font-size: 17px;
  }
  @media (min-width: $sm) {
    font-size: 21px;
  }
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: end;
}

.button {
  @media (max-width: ($sm - 0.02)) {
    font-size: 15px;
  }
  @media (min-width: $sm) {
    font-size: 18px;
  }

  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 40px;
    }
    @media (min-width: $sm) {
      margin-right: 54px;
    }
  }
}
