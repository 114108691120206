@import './../../styles/globalConfigs';

.gameStatisticsContainer {
  @media (max-width: ($sm - 0.02)) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  @media (min-width: $sm) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
}

.gameRanksContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .gameRankItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      margin: 0 14px 14px;
    }
    @media (min-width: $sm) {
      margin: 0 20px 20px;
    }

    .imageContainer {
      position: relative;
      @media (max-width: ($sm - 0.02)) {
        width: 85px;
        height: 85px;
      }
      @media (min-width: $sm) {
        width: 150px;
        height: 150px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .rankTitle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        text-align: center;

        @media (max-width: ($sm - 0.02)) {
          font-size: 12px;
          margin-bottom: 7px;
        }
        @media (min-width: $sm) {
          font-size: 21px;
          margin-bottom: 10px;
        }
      }
    }

    .gameType {
      font-weight: 600;
      font-style: italic;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      text-align: center;
      white-space: nowrap;

      @media (max-width: ($sm - 0.02)) {
        font-size: 13px;
        margin-bottom: 3px;
      }
      @media (min-width: $sm) {
        font-size: 28px;
        margin-bottom: 6px;
      }
    }

    .clockTime {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 3px;
      }
      @media (min-width: $sm) {
        margin-bottom: 6px;
      }

      .clockIcon {
        @media (max-width: ($sm - 0.02)) {
          width: 15px;
          height: 15px;
          margin-right: 4px;
        }
        @media (min-width: $sm) {
          width: 28px;
          height: 28px;
          margin-right: 6px;
        }

        > svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .text {
        @media (max-width: ($sm - 0.02)) {
          font-size: 13px;
        }
        @media (min-width: $sm) {
          font-size: 22px;
        }
      }
    }

    .yourTime {
      @media (max-width: ($sm - 0.02)) {
        font-size: 12px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
      }
    }
  }
}

.vicabMasterStatistic {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    flex-direction: column;
  }

  .medalTitle {
    font-weight: 600;
    font-style: italic;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    @media (max-width: ($sm - 0.02)) {
      font-size: 22px;
    }
    @media (min-width: $sm) {
      font-size: 30px;
    }
  }

  .icon {
    height: auto;
    margin-right: 6px;
    @media (max-width: ($sm - 0.02)) {
      width: 22px;
    }
    @media (min-width: $sm) {
      width: 34px;
    }

    > svg {
      width: 100%;
    }
  }

  .progress {
    display: flex;
    border-radius: 50rem;
    @media (max-width: ($sm - 0.02)) {
      width: 261px;
      height: 17px;
    }
    @media (min-width: $sm) {
      width: 382px;
      height: 25px;
    }

    .progressBarSection {
      flex: 1;
      height: 100%;
      width: 100%;

      &:first-child {
        border-radius: 50rem 0 0 50rem;
        border-right: 1px solid;
      }

      &:last-child {
        border-radius: 0 50rem 50rem 0;
        border-left: 1px solid;
      }
    }
  }
}
