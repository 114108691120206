@import './../../../styles/globalConfigs';

.card {
  border-radius: 20px;
  width: 470px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 20px;

  &.isMobile {
    padding: 0;
  }
}

.cardTitle {
  font-family: 'Roboto Flex', $SegoeUI, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 17.6px; /* 110% */
  margin-bottom: 12px;
}

.list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .item {
    border-radius: 20px;
    list-style: none;

    > button {
      padding: 9px;
      display: flex;
      align-items: center;
      gap: 18px;
    }

    .videoCover {
      border-radius: 20px;
      width: 122px;
      height: 80px;
    }

    .itemTitle {
      font-family: 'Roboto Flex', $Sahel, $SegoeUI, sans-serif;
      font-size: 18px;
      font-weight: 200;
    }
  }
}
