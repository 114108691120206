@import './../../styles/globalConfigs';

.container {
  display: flex;
  justify-content: flex-end;

  @media (max-width: ($sm - 0.02)) {
    width: 161px;
    height: auto;
  }
  @media (min-width: $sm) {
    width: 251px;
    height: auto;
  }

  .circleContainer {
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      width: 142px;
      height: 142px;
    }
    @media (min-width: $sm) {
      width: 224px;
      height: 224px;
    }

    .exerciseTitle {
      position: absolute;
      top: 0;
      letter-spacing: 0;
      z-index: 0;

      @media (max-width: ($sm - 0.02)) {
        font-size: 10px;
      }
      @media (min-width: $sm) {
        font-size: 15px;
      }
      .curveSvgText {
        transform: translate(-40px, -6px);
        @media (max-width: $sm) {
          transform: translate(-2px, -6px);
        }
      }
    }

    .reviewButton {
      position: absolute;
      border-radius: 50%;
      border: 1px solid;
      bottom: 0;
      right: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (max-width: ($sm - 0.02)) {
        width: 50px;
        height: 50px;
        font-size: 12px;
        line-height: 13px;
      }
      @media (min-width: $sm) {
        width: 78px;
        height: 78px;
        font-size: 18px;
        line-height: 20px;
      }
    }

    .sectionCircle {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 3px solid;

      @media (max-width: ($sm - 0.02)) {
        width: 32px;
        height: 32px;
        top: 39px;
        left: -10px;
      }
      @media (min-width: $sm) {
        width: 48px;
        height: 48px;
        top: 60px;
        left: -16px;
      }

      svg {
        width: 70%;
        height: 70%;
        object-fit: contain;
      }

      .exerciseBadge {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: ($sm - 0.02)) {
          left: -10px;
          width: 16px;
          height: 16px;
          font-size: 12px;
        }
        @media (min-width: $sm) {
          left: -10px;
          width: 20px;
          height: 20px;
          font-size: 15px;
        }

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        svg {
          width: 100%;
          height: auto;
        }
      }
    }

    .circleInner {
      overflow: hidden;
      border-radius: 50%;
      @media (max-width: ($sm - 0.02)) {
        width: 117px;
        height: 117px;
      }
      @media (min-width: $sm) {
        width: 184px;
        height: 184px;
      }

      .sectionNumberContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: ($sm - 0.02)) {
          margin-top: 17px;
          margin-bottom: 14px;
        }
        @media (min-width: $sm) {
          margin-top: 25px;
          margin-bottom: 23px;
        }

        .line {
          flex: 1 1 auto;
          height: 1px;
        }

        .sectionNumber {
          font-style: italic;

          @media (max-width: ($sm - 0.02)) {
            font-size: 10px;
            margin: 0 5px;
          }
          @media (min-width: $sm) {
            font-size: 12px;
            margin: 0 8px;
          }
        }
      }

      .inCorrectsBadge {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;

        @media (max-width: ($sm - 0.02)) {
          width: 77px;
          height: 18px;
          font-size: 14px;
        }
        @media (min-width: $sm) {
          width: 121px;
          height: 29px;
          font-size: 18px;
        }
      }

      .outOf {
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          font-size: 14px;
        }
        @media (min-width: $sm) {
          font-size: 18px;
        }
      }
    }
  }
}
