@import './../../styles/globalConfigs';

.headRobo {
  width: 46px;
  height: 46px;
  box-shadow: 0 0 14px 3px #ffffff66;
  border-radius: 50%;
  border: 1px solid;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;

  .imageHeadRobo {
    width: auto;
    height: 32px;
  }
}

.container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  top: 15%;
  position: relative;
  @media (max-width: $sm) {
    font-size: 16px;
    top: 15%;
  }

  .picture {
    width: 400px;
    height: 300px;
    position: relative;
    transform: translateY(20px);
    z-index: 1;

    @media (max-width: $sm) {
      height: auto;
      width: 300px;
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .card {
    text-align: center;
    z-index: 2;
    gap: 19px;
    width: 400px;
    max-width: 100%;
    padding: 25px 25px 17px 25px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $sm) {
      height: auto;
      width: 320px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      :not(:last-child) {
        margin-bottom: 16px;
      }

      .flag {
        margin: auto 5px auto 5px;

        > img {
          width: 24px;
          height: 24px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
  }

  .skipButton {
    font-size: 18px;
    @media (max-width: $sm) {
      font-size: 14px;
    }
  }
}
