@import './../../../styles/globalConfigs';

.buttonPrimary {
  border-radius: 100px;
  width: fit-content;
  display: flex;
  border: 1px solid;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
  transition: 0.1s ease-out;
  font-weight: 600;

  &:not(.buttonDisabled):active {
    @media (max-width: ($sm - 0.02)) {
      transform: translateY(2px);
    }
    @media (min-width: $sm) {
      transform: translateY(3px);
    }
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  }

  @media (max-width: ($sm - 0.02)) {
    font-size: 14px;
    padding: 7px 14px;
    min-width: 66px;
  }
  @media (min-width: $sm) {
    min-width: 99px;
    font-size: 21px;
    padding: 5px 16px;
  }

  &.buttonDisabled {
    background: linear-gradient(to right, #e8e8e8, #c6c6c6) !important;
    cursor: default;
    color: #7b7b7b !important;
  }

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 16px;
    margin-left: 0;
    position: relative;
    transition: width 0.3s ease-out, margin-left 0.3s ease-out;

    &:after {
      display: none;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-radius: 50%;
      border: 3px solid var(--spinner-color);
      border-left-color: transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    &.startSpin {
      width: 16px;
      margin-left: 6px;

      &:after {
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
