@import "../../styles/globalConfigs";

[data-jsx="SecondaryHeader"] {
  &.secondary-header {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.fixed {
      position: fixed;
      width: 100%;
      z-index: 960;
      top: 65px;
    }

    > a, > button {
      width: 100%;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 100%;
      opacity: 0.5;
      padding-top: 7px;
      padding-right: 5px;
      padding-left: 5px;

      @media (max-width: ($sm - 0.02)) {
        flex-direction: column;
        gap: 2px;
      }
      @media (min-width: $sm) {
        flex-direction: row;
        gap: 6px;
      }

      &.active {
        opacity: 1;
      }

      .icon {
        display: flex;
        align-items: center;

        @media (max-width: ($sm - 0.02)) {
          width: 16px;
          height: 16px;
        }
        @media (min-width: $sm) {
          width: 20px;
          height: 20px;
        }

        > * {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    span {
      height: 24px;
      width: 1px;
    }
  }
}