@import './../../../../styles/globalConfigs';

.cardsContainer {
  display: grid;

  @media (max-width: ($sm - 0.02)) {
    grid-template-columns: repeat(2, calc(50% - 5px));
    gap: 10px;
  }
  @media (min-width: $sm) {
    grid-template-columns: repeat(2, calc(50% - 9px));
    gap: 18px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  :global(.shimmer) {
    width: 250px;
    max-width: 100%;
    max-height: 180px;
    aspect-ratio: 1;
    object-fit: contain;
  }

  img {
    width: 250px;
    max-height: 180px;
    aspect-ratio: 1;
    object-fit: contain;
  }
}
