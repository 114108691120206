@import './../../styles/globalConfigs';

.levelNumberBox {
  width: fit-content;

  &.withBorder {
    border: 1px solid;
    border-radius: 50rem;
    padding: 0 12px;
  }

  @media (max-width: ($sm - 0.02)) {
    font-size: 14px;
  }
  @media (min-width: $sm) {
    font-size: 16px;
  }
}
