@import './../../styles/globalConfigs';

.addToCategoryButton {
  transition: opacity 150ms;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translate(-50%);
  text-decoration: underline;
  white-space: nowrap;
  font-size: 14px !important;
  @media (max-width: $sm) {
    font-size: 12px !important;
    bottom: calc(100% + 2px);
  }
}
