@import 'globalConfigs';

@keyframes darkBackground {
  to {
    background: rgba(35, 31, 32, 0.8);
  }
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1013;
  overflow: auto;
  animation: darkBackground 0.3s ease-out forwards;

  > *:not(.close-modal) {
    max-height: calc(100% - 100px);
  }

  &:not(:last-child) {
    overflow: hidden;
  }
}

.close-modal {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  @media (max-width: ($sm - 0.02)) {
    width: 20px;
    height: 20px;
    top: 20px;
    right: 30px;
  }
  @media (min-width: $sm) and (max-width: ($lg - 0.02)) {
    width: 25px;
    height: 25px;
    top: 20px;
    right: 30px;
  }
  @media (min-width: $lg) {
    width: 30px;
    height: 30px;
    top: 36px;
    right: 68px;
  }

  > svg {
    width: 100%;
    height: 100%;
  }
}

.modal-type-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: calc(100% - 20px);
  border-radius: 20px;
  @media (max-width: ($sm - 1)) {
    padding: 25px;
    min-width: 246px;
  }
  @media (min-width: $sm) {
    padding: 35px;
    min-width: 470px;
  }
}

.modal-type-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  max-width: 92%;

  @media (max-width: ($sm - 0.02)) {
    width: 450px;
  }
  @media (min-width: $sm) {
    width: 580px;

    &.width-lg {
      width: 640px;
    }
  }

  &.scrollable {
    max-height: calc(100% - 110px);
    overflow: auto;
  }

  .modal-title {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: $sm) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }

  .modal-card {
    border-radius: 20px;
    overflow: auto;

    @media (max-width: ($sm - 0.02)) {
      max-height: calc(100vh - 150px);
    }
    @media (min-width: $sm) {
      max-height: calc(100vh - 60px);
    }

    &.have-scroll {
      @media (max-width: ($sm - 0.02)) {
        height: calc(100vh - 150px);
      }
      @media (min-width: $sm) {
        height: calc(100vh - 60px);
      }
    }
  }
}

.modal-type-3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;

  @media (max-width: ($sm - 0.02)) {
    width: 450px;
  }
  @media (min-width: $sm) {
    width: 880px;
  }

  .modal-title {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: $sm) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }

  .modal-card {
    border-radius: 20px;
    overflow: auto;
    @media (max-width: ($sm - 0.02)) {
      padding: 18px;
      max-height: calc(100vh - 150px);
    }
    @media (min-width: $sm) {
      padding: 25px;
      max-height: calc(100vh - 60px);
    }
  }
}

.modal-type-4 {
  position: absolute;
  top: 10%;
  right: 0px;
  max-width: 100%;
  height: 80%;
  animation: move-to-left 3s ease-in-out;

  @keyframes move-to-left {
    0% {
      transform: translateX(100%);
      opacity: 0.4;
    }
    20% {
      transform: translateX(80%);
    }
    40% {
      transform: translateX(80%);
    }
    60% {
      transform: translateX(100%);
      opacity: 0.6;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @media (max-width: ($sm - 0.02)) {
    width: 450px;
  }
  @media (min-width: $sm) {
    width: 770px;
  }

  .modal-title {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      font-size: 20px;
      margin-bottom: 20px;
    }
    @media (min-width: $sm) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }

  .modal-card {
    border-radius: 20px 0px 0px 20px;
    overflow: auto;
    flex-grow: 1;
    height: 100%;

    @media (max-width: ($sm - 0.02)) {
      padding: 18px 0px;
    }
    @media (min-width: $sm) {
      padding: 25px 0px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      animation: fade-in 3s ease-in-out;
    }

    .body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 20px;
      padding-top: 10px;
      width: 100%;
      height: 100%;
      animation: fade-in 3s ease-in-out;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .close-modal-4 {
      background: transparent;
      margin-right: 20px;

      @media (max-width: ($sm - 0.02)) {
        width: 20px;
        height: 20px;
      }
      @media (min-width: $sm) and (max-width: ($lg - 0.02)) {
        width: 25px;
        height: 25px;
      }
      @media (min-width: $lg) {
        width: 30px;
        height: 30px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.settings-modal {
  overflow: auto;

  .settings {
    display: flex;
    position: relative;

    .settings-column {
      position: absolute;
      border-radius: 50rem;
      border: 1px solid #464f85;
      height: 100%;
      left: 0;
      width: 32px;
      pointer-events: none;
      @media (min-width: $xlg) {
        width: 40px;
      }
    }

    .settings-list {
      list-style: none;
      padding: 0;
      margin-bottom: 0;

      li.setting-item {
        margin: 10px 0;

        .setting-icon {
          display: flex;
          width: 21px;
          height: 21px;

          > * {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .setting-name {
          transition: 150ms;
          display: flex;
          align-items: center;
          margin-left: 14px;
          color: #464f85b2;
          font-size: (25px * 0.7) !important;
          @media (max-width: ($sm - 0.02)) {
            font-size: (25px * 0.55) !important;
          }
          @media (min-width: $xlg) {
            font-size: 25px !important;
          }
        }

        .left-side {
          width: 32px;
          @media (min-width: $xlg) {
            width: 40px;
          }
        }

        button {
          display: flex;
          border: 0;
          padding: 0;
          align-items: center;
          justify-content: flex-start;
        }

        .option {
          display: block;
          position: relative;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          border: 2px solid;
          margin-right: auto;
          margin-left: auto;
        }

        &.selected {
          .setting-name {
            color: #464f85;
            font-size: (29px * 0.7) !important;
            @media (max-width: ($sm - 0.02)) {
              font-size: (29px * 0.55) !important;
            }
            @media (min-width: $xlg) {
              font-size: 29px !important;
            }
          }

          .option:after {
            content: '';
            display: block;
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translate(50%, -50%);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #464f85;
          }
        }

        &.default {
          .translate-icon {
            display: flex;
            margin-right: auto;
            margin-left: auto;
            width: 20px;
            height: 20px;
            @media (min-width: $xlg) {
              width: 25px;
              height: 25px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .setting-name {
            color: #464f85;
            font-weight: 700;
          }
        }
      }
    }
  }

  .done-btn {
    border: 0;
    color: white;
    border-radius: 50rem;
    min-width: 130px;
    min-height: 36px;
    @media (min-width: $xlg) {
      min-width: 178px;
      min-height: 48px;
    }
  }
}

.confirm-modal {
  max-width: calc(100% - 30px);
  @media (max-width: ($sm - 0.02)) {
    width: 360px;
    padding: 20px;
  }
  @media (min-width: $sm) {
    width: 450px;
    padding: 32px 26px;
  }

  .modal-content {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 20px;
    }
    @media (min-width: $sm) {
      margin-bottom: 30px;
    }
  }

  .control-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      gap: 18px;
    }
    @media (min-width: $sm) {
      gap: 24px;
    }

    button {
      @media (max-width: ($sm - 0.02)) {
        min-width: 84px;
        min-height: 30px;
        border-radius: 5px;
        padding: {
          right: 12px;
          left: 12px;
        };
      }
      @media (min-width: $sm) {
        min-width: 100px;
        min-height: 36px;
        border-radius: 8px;
        padding: {
          right: 12px;
          left: 12px;
        };
      }
    }
  }
}
