@import '../../styles/globalConfigs';

.moreButton {
  @media (max-width: ($sm - 0.02)) {
    width: 30px;
    margin-right: 30px;
  }
  @media (min-width: $sm) {
    width: 44px;
    margin-right: 50px;
  }
}

.circleButton {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  @media (max-width: ($sm - 0.02)) {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  @media (min-width: $sm) {
    width: 44px;
    height: 44px;
    margin-right: 25px;
  }

  > svg {
    width: 60%;
    height: 60%;
    object-fit: contain;
  }
}

.dictionaryModal {
  max-width: calc(100% - 20px);

  @media (max-width: ($sm - 0.02)) {
    width: 360px;
    max-height: calc(100% - 75px);
  }
  @media (min-width: $sm) {
    width: 520px;
    max-height: calc(100% - 101px);
  }
}

.dictionaryModalCard {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  width: 980px;

  * {
    user-select: text;
  }

  @media (max-width: ($sm - 0.02)) {
    border-radius: 18px;
  }
  @media (min-width: $sm) {
    border-radius: 24px;
  }

  .dictionaryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 20px;
    @media (max-width: ($sm - 0.02)) {
      margin: 12px 26px;
    }
    @media (min-width: $sm) {
      margin: 17px 37px;
    }

    .word {
      font-weight: 500;
      margin-right: 12px;
      display: inline-block;
    }

    .phonetic {
      display: inline-block;
      opacity: 75%;
    }

    .listen {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > * {
        margin: 7px 12px;
      }

      .listenItem {
        cursor: pointer;
        text-align: center;
        margin: 7px 12px;

        .circle {
          position: relative;
          width: 36px;
          height: 36px;

          img.flag {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 34px;
            height: 34px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            z-index: 1;
          }

          .speaker {
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;

            img {
              width: 60%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .dictionaryContent {
    flex: 1 1 auto;
    display: grid;
    @media (max-width: ($sm - 0.02)) {
      border-radius: 5px;
      margin: 13px;
      padding: 6px;
    }
    @media (min-width: $sm) {
      border-radius: 10px;
      margin: 20px;
      padding: 9px;
    }

    .inner {
      overflow: auto;
      height: fit-content;
      border: 2px solid;
      box-shadow: inset 0 1px 3px rgb(0 0 0 / 16%), 0 1px 3px rgb(0 0 0 / 16%);

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(112, 112, 112, 0.62);
        border-radius: 20px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba(112, 112, 112, 0.8);
      }

      @media (max-width: ($sm - 0.02)) {
        padding: 6px 0;
        border-radius: 5px;
      }
      @media (min-width: $sm) {
        padding: 9px 0;
        border-radius: 10px;
      }
    }

    .pos {
      display: flex;
      align-items: center;
      margin-bottom: 11px;

      .line {
        display: inline-block;
        width: 50px;
        height: 4px;
        margin-right: 12px;
      }

      &.noun {
        .line {
          background-color: #e5007e;
        }

        .name {
          color: #e5007e;
        }
      }

      &.verb {
        .line {
          background-color: #990098;
        }

        .name {
          color: #990098;
        }
      }
    }

    .text {
      line-height: 1.9;
      padding: 0 15px;
      @media (min-width: $md) {
        padding: 0 25px;
      }
    }
  }
}

.topCard {
  position: relative;
  display: flex;
  @media (max-width: ($sm - 0.02)) {
    height: 70px;
  }
  @media (min-width: $sm) {
    height: 80px;
  }

  .cornerShape {
    position: absolute;
    right: 0;
    top: 1px;

    @media (max-width: ($sm - 0.02)) {
      height: 70px;
    }
    @media (min-width: $sm) {
      height: 80px;
    }

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .optionsList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
