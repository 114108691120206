@import "globalConfigs";

.three-dot-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  max-width: calc(100% - 20px);

  @media (max-width: ($sm - 0.02)) {
    margin-top: 40px;
  }
  @media (min-width: $sm) {
    margin-top: 70px;
  }

  .circle-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      width: 432px;
      row-gap: 24px;
    }
    @media (min-width: $sm) {
      width: 980px;
      row-gap: 35px;
    }

    .button-wrapper {
      .circle-button {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        margin: auto;

        @media (max-width: ($sm - 0.02)) {
          width: 54px;
          height: 54px;
          margin-bottom: 4px;
        }
        @media (min-width: $sm) {
          width: 70px;
          height: 70px;
          margin-bottom: 6px;
        }

        > svg {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }

      .label {
        display: block;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 18px;
        }
      }
    }
  }
}

[data-jsx="Tools"] {
  .container-tools {
    @media (min-width: $lg) {
      max-width: 1200px;
    }
  }

  .tools-3dot-container {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 19px;
      width: 20px;
    }
    @media (min-width: $sm) {
      margin-right: 28px;
      width: 23px;
    }

    .tools-3dot {
      width: 100%;

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.top-section-long {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;
      @media (max-width: ($md - 0.02)) {
        flex-direction: column;
        justify-content: center;
      }
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 25px;
      }
      @media (min-width: $sm) {
        margin-bottom: 36px;
        width: 900px;
      }
    }

    @media (max-width: ($sm - 0.02)) {
      margin-top: 19px;
    }
    @media (min-width: $sm) {
      margin-top: 27px;
    }

    .type-method-buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        @media (max-width: ($sm - 0.02)) {
          width: 40px;
          height: 40px;
        }
        @media (min-width: $sm) {
          width: 57px;
          height: 57px;
        }
      }

      .voice-btn {
        @media (max-width: ($sm - 0.02)) {
          margin-left: 8px;
        }
        @media (min-width: $sm) {
          margin-left: 11px;
        }
      }
    }

    .num-rep-wrapper {
      display: flex;
      align-items: center;
      @media (max-width: ($md - 0.02)) {
        margin-bottom: 20px;
      }

      .num-rep-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        @media (max-width: ($sm - 0.02)) {
          height: 30px;
        }
        @media (min-width: $sm) {
          height: 38px;
        }
      }

      .num-rep-label {
        @media (max-width: ($sm - 0.02)) {
          min-width: 142px;
          font-size: 12px;
          margin-right: 7px;
        }
        @media (min-width: $sm) {
          min-width: 242px;
          font-size: 18px;
          margin-right: 10px;
        }
      }

      .num-rep {
        font-weight: 600;
        @media (max-width: ($sm - 0.02)) {
          width: 40px;
          font-size: 17px;
          margin-right: 10px;
        }
        @media (min-width: $sm) {
          width: 74px;
          font-size: 24px;
          margin-right: 30px;
        }
      }

      .num-rep-controller {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        button {
          @media (max-width: ($sm - 0.02)) {
            width: 15px;
            height: auto;
          }
          @media (min-width: $sm) {
            width: 20px;
            height: auto;
          }

          &:first-child {
            @media (max-width: ($sm - 0.02)) {
              margin-bottom: 12px;
            }
            @media (min-width: $sm) {
              margin-bottom: 16px;
            }
          }

          svg {
            display: block;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .reset-btn {
      @media (max-width: ($sm - 0.02)) {
        margin-left: 15px;
      }
      @media (min-width: $sm) {
        margin-left: 30px;
      }
    }

    .tools-top-circle-buttons {
      display: flex;
      align-items: center;

      > * {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }

  .tools-page-container {
    margin: auto;
    @media (max-width: ($sm - 0.02)) {
      max-width: 100%;
    }
    @media (min-width: $sm) {
      max-width: 900px;
    }
  }

  .pagination-sentence-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ($sm - 0.02)) {
      margin-top: 20px;
    }
    @media (min-width: $sm) {
      margin-top: 28px;
    }

    .sentence-wrapper {
      flex: 1 1;
      @media (max-width: ($sm - 0.02)) {
        padding: 0 8px;
      }
      @media (min-width: $sm) {
        padding: 0 15px;
      }

      p {
        text-align: center;
        margin: 0;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 23px;
        }
      }
    }

    .drill-navigation-button {
      @media (max-width: ($sm - 0.02)) {
        width: 36px;
        height: 36px;
      }
      @media (min-width: $sm) {
        width: 51px;
        height: 51px;
      }
    }
  }

  .memorize-box {
    width: 100%;
    border: 2px solid;
    position: relative;

    @media (max-width: ($sm - 0.02)) {
      min-height: 136px;
      border-radius: 15px;
      padding: 7px 10px 20px;
      margin: 18px auto;
    }
    @media (min-width: $sm) {
      min-height: 192px;
      border-radius: 24px;
      padding: 10px 14px 28px;
      margin: 29px auto;
    }

    .lock-button-container {
      position: absolute;

      @media (max-width: ($sm - 0.02)) {
        top: 10px;
        right: 10px;
      }
      @media (min-width: $sm) {
        top: 15px;
        right: 15px;
      }
    }

    .inputs-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 28px;
        margin-top: 38px;
        gap: 4px;
      }
      @media (min-width: $sm) {
        margin-bottom: 34px;
        margin-top: 53px;
        gap: 5px;
      }

      .input-wrapper {
        position: relative;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
          min-width: 12px;
        }
        @media (min-width: $sm) {
          min-width: 18px;
          font-size: 23px;
        }
      }

      .memorize-input {
        border: 0;
        outline: none;
        padding: 0;
        border-bottom: 1px solid;
        background: transparent;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    .speech-button {
      position: absolute;
      @media (max-width: ($sm - 0.0)) {
        bottom: 18px;
        right: 18px;
      }
      @media (min-width: $sm) {
        bottom: 24px;
        right: 24px;
      }
    }

    .on-off-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ($sm - 0.0)) {
        height: 35px;
      }
      @media (min-width: $sm) {
        height: 49px;
      }

      button {
        border-radius: 50%;
        @media (max-width: ($sm - 0.02)) {
          width: 35px;
          height: 35px;
          margin: 0 10px;
        }
        @media (min-width: $sm) {
          width: 49px;
          height: 49px;
          margin: 0 15px;
        }
      }
    }

    .phrase-options {
      margin: 0;
      justify-content: center;
      @media (max-width: ($sm - 0.02)) {
        margin-top: 20px;
      }
      @media (min-width: $sm) {
        margin-top: 30px;
      }
    }
  }

  .spelling-box {
    width: 100%;
    border: 2px solid;

    @media (max-width: ($sm - 0.02)) {
      min-height: 136px;
      border-radius: 10px;
      padding: 10px 19px 12px;
    }
    @media (min-width: $sm) {
      min-height: 192px;
      border-radius: 14px;
      padding: 14px 27px 17px;
    }

    .box-title {
      margin-bottom: 15px;
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        font-size: 16px;
      }
      @media (min-width: $sm) {
        font-size: 18px;
      }
    }

    .input-box {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: ($sm - 0.02)) {
        min-height: 114px;
        border-radius: 25px;
        padding: 0 20px;
      }
      @media (min-width: $sm) {
        min-height: 160px;
        border-radius: 36px;
        padding: 0 28px;
      }

      .inputs {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 7px;
      }

      .input-item {
        position: relative;
      }

      .input-shadow, .user-input-shadow {
        pointer-events: none;

        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 21px;
        }
      }

      .user-input-shadow {
        white-space: nowrap;
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
      }

      input, p {
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 21px;
        }
      }

      input {
        border: 0;
        outline: none;
        border-bottom: 1px solid;
        background: transparent;
        width: 100%;
        padding: 0;

        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .under-spelling-box-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: ($sm - 0.02)) {
      margin-top: 18px;
      margin-bottom: 32px;
    }
    @media (min-width: $sm) {
      margin-top: 26px;
      margin-bottom: 45px;
    }

    .spelling-num-circle-badges-container {
      display: flex;
      align-items: center;
      justify-content: center;

      &.hidden {
        pointer-events: none;
        visibility: hidden;
      }

      .spelling-num-circle-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border-radius: 50%;

        @media (max-width: ($sm - 0.02)) {
          width: 30px;
          height: 30px;
          font-size: 15px;
        }
        @media (min-width: $sm) {
          width: 40px;
          height: 40px;
          font-size: 21px;
        }

        &:not(:last-child) {
          @media (max-width: ($sm - 0.02)) {
            margin-right: 19px;
          }
          @media (min-width: $sm) {
            margin-right: 26px;
          }
        }
      }
    }
  }

  .clue-and-number-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .drill-number {
      margin: auto;
      width: fit-content;
      @media (max-width: ($sm - 0.02)) {
        font-size: 15px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
      }
    }

    .organizer {
      @media (max-width: ($sm - 0.02)) {
        width: 50px;
        height: 50px;
      }
      @media (min-width: $sm) {
        width: 70px;
        height: 70px;
      }
    }
  }

  .page-number {
    width: fit-content;
    margin: auto;
    border: 2px solid;
    border-radius: 50rem;
    @media (max-width: ($sm - 0.02)) {
      padding: 4px 11px;
      font-size: 15px;
      margin-bottom: 28px;
    }
    @media (min-width: $sm) {
      padding: 6px 16px;
      font-size: 21px;
      margin-bottom: 42px;
    }
  }

  .page-down-buttons {
    display: flex;
    align-items: center;

    &.one-button {
      justify-content: center;
    }

    &.multiple-buttons {
      justify-content: space-between;
    }
  }

  .semi-circular-progress-bar {
    position: relative;
    margin: 4px;
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      height: 190px;
    }
    @media (min-width: $sm) {
      height: 271px;
    }

    .bar-overflow {
      margin-right: auto;
      margin-left: auto;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: ($sm - 0.02)) {
        width: 380px;
        height: 190px;
      }
      @media (min-width: $sm) {
        width: 542px;
        height: 271px;
      }
    }

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      box-sizing: border-box;
      border: 5px solid;
      @media (max-width: ($sm - 0.02)) {
        width: 380px;
        height: 380px;
      }
      @media (min-width: $sm) {
        width: 542px;
        height: 542px;
      }
    }

    .content {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      &.type-1 {
        @media (max-width: ($sm - 0.02)) {
          padding-top: 116px;
        }
        @media (min-width: $sm) {
          padding-top: 163px;
        }
      }

      &.type-2 {
        @media (max-width: ($sm - 0.02)) {
          padding-top: 15px;
        }
        @media (min-width: $sm) {
          padding-top: 21px;
        }
      }
    }
  }

  .circle-audio-control-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      @media (max-width: ($sm - 0.02)) {
        width: 53px;
        height: 53px;
      }
      @media (min-width: $sm) {
        width: 75px;
        height: 75px;
      }

      svg, img {
        display: block;
        width: 50%;
        height: 50%;
        object-fit: contain;
      }
    }
  }

  .tools-rating-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 30px;
    }
    @media (min-width: $sm) {
      margin-bottom: 44px;
    }

    .star {
      display: block;
      @media (max-width: ($sm - 0.02)) {
        width: 24px;
        margin: {
          right: 2px;
          left: 2px;
        };
      }
      @media (min-width: $sm) {
        width: 34px;
        margin: {
          right: 3px;
          left: 3px;
        };
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }

  .spelling-letter-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;

    @media (max-width: ($sm - 0.02)) {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      font-size: 23px;
      margin: 4px;
    }
    @media (min-width: $sm) {
      width: 42px;
      height: 66px;
      border-radius: 10px;
      font-size: 32px;
      margin: 6px;
    }
  }

  .spelling-unscramble-box {
    border: 2px solid;

    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      padding: 14px;
      margin-bottom: 18px;
    }
    @media (min-width: $sm) {
      border-radius: 15px;
      padding: 28px;
      margin-bottom: 26px;
    }
  }

  .letter-boxes-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .top-letter-boxes {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 16px;
    }
    @media (min-width: $sm) {
      margin-bottom: 32px;
    }

    .spelling-letter-box {
      border: 1px solid;
      align-items: center;

      @media (max-width: ($sm - 0.02)) {
        width: 26px;
        height: 26px;
        border-radius: 5px;
        font-size: 16px;
        margin: 2px;
      }
      @media (min-width: $sm) {
        width: 32px;
        height: 32px;
        border-radius: 7px;
        font-size: 24px;
        margin: 4px;
      }
    }
  }

  .bottom-letter-boxes {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 46px;
    }
    @media (min-width: $sm) {
      margin-bottom: 65px;
    }
  }

  .syllabus-word {
    transition: opacity 150ms;
    text-align: center;
    font-weight: 500;
    @media (max-width: ($sm - 0.02)) {
      font-size: 15px;
    }
    @media (min-width: $sm) {
      font-size: 21px;
    }
  }

  .spelling-word-options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 35px;
      margin-top: 14px;
    }
    @media (min-width: $sm) {
      margin-bottom: 50px;
      margin-top: 20px;
    }
    @media (max-width: ($md - 0.02)) {
      display: block;
      width: 400px;
      max-width: 100%;
      margin-right: auto;
      margin-left: auto;

      > *:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .spelling-pyramid-letters {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .spelling-pyramid-start-container {
      z-index: 1;
      width: 100%;
      height: calc(100% + 20px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .spell-pyramid-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 5px;

      > * {
        width: 50%;
      }

      .questions {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        gap: 7px;

        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 23px;
        }

        span {
          display: block;
        }
      }

      .answers {
        .answer-item {
          position: relative;
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 7px;

          .input-container {
            width: fit-content;
            position: relative;

            * {
              @media (max-width: ($sm - 0.02)) {
                font-size: 16px;
              }
              @media (min-width: $sm) {
                font-size: 23px;
              }
            }

            .answer-hidden {
              margin: 0;
              visibility: hidden;
            }

            .bottom-line {
              position: absolute;
              width: 100%;
              left: 0;
              @media (max-width: ($sm - 0.02)) {
                bottom: 5px;
              }
              @media (min-width: $sm) {
                bottom: 7px;
              }
            }

            input {
              border: 0;
              background: transparent;
              padding: 0;
              outline: none;
              width: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }

          .reset-item-btn {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translate(10px, -50%);
            display: flex;
            @media (max-width: ($sm - 0.02)) {
              width: 15px;
              height: 15px;
            }
            @media (min-width: $sm) {
              width: 20px;
              height: 20px;
            }

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .letter-separation-syllabus-box {
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      margin: 30px auto 19px;
      padding: 10px 10px 19px;
    }
    @media (min-width: $sm) {
      width: fit-content;
      border-radius: 15px;
      margin: 43px auto 27px;
      padding: 16px 16px 26px;
      min-width: 678px;
    }

    .vocab-parts {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @media (max-width: ($sm - 0.02)) {
        margin: 10px 0 12px;
      }
      @media (min-width: $sm) {
        margin: 14px 42px 15px;
      }

      .vocab-part-item {
        transition: none;
        cursor: pointer;
        max-width: calc(20% - 4px);
        @media (max-width: ($sm - 0.02)) {
          width: 66px;
        }
        @media (min-width: $sm) {
          width: 93px;
        }

        * {
          text-align: center;
          @media (max-width: ($sm - 0.02)) {
            font-size: 16px;
          }
          @media (min-width: $sm) {
            font-size: 22px;
          }
        }

        .part-text {
          transition: 150ms;
          border-bottom: 1px solid;
          @media (max-width: ($sm - 0.02)) {
            margin-bottom: 3px;
            padding-bottom: 3px;
          }
          @media (min-width: $sm) {
            margin-bottom: 5px;
            padding-bottom: 5px;
          }
        }

        .part-number {
          transition: 150ms;
        }
      }
    }

    .vocab-letters-boxes {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .vocab-letter-box {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;

        @media (max-width: ($sm - 0.02)) {
          font-size: 20px;
          margin: 7px;
          width: 32px;
          height: 32px;
          border-radius: 5px;
        }
        @media (min-width: $sm) {
          font-size: 29px;
          margin: 10px;
          width: 46px;
          height: 46px;
          border-radius: 9px;
        }
      }
    }
  }

  .pagination-container {
    width: fit-content;
    max-width: 100%;
    @media (max-width: ($sm - 0.02)) {
      margin: 18px auto;
    }
    @media (min-width: $sm) {
      margin: 29px auto;
    }

    .pagination-wrapper {
      border: 1px solid;
      border-radius: 50rem;
      @media (max-width: ($sm - 0.02)) {
        padding: 4px;
      }
      @media (min-width: $sm) {
        padding: 7px;
      }

      .pagination {
        border-radius: 50rem;
        width: 100%;
        overflow: auto;

        // Hide scrollbar
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        ul {
          list-style: none;
          margin: 0;
          display: flex;
          border-radius: 50rem;
          width: fit-content;
          @media (max-width: ($sm - 0.02)) {
            padding: 3px 6px;
          }
          @media (min-width: $sm) {
            padding: 4px 10px;
          }

          li {
            position: relative;
            display: block;
            border-radius: 50%;
            cursor: pointer;

            @media (max-width: ($sm - 0.02)) {
              width: 18px;
              height: 18px;
              font-size: 8px;
            }
            @media (min-width: $sm) {
              width: 25px;
              height: 25px;
              font-size: 14px;
            }

            &:not(:last-child) {
              @media (max-width: ($sm - 0.02)) {
                margin-right: 8px;
              }
              @media (min-width: $sm) {
                margin-right: 17px;
              }
            }

            text-align: center;

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  .text-card-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (max-width: ($sm - 0.02)) {
      gap: 6px;
    }
    @media (min-width: $sm) {
      gap: 12px;
    }

    .text-card {
      flex: 1;
      max-width: 700px;
      border: 1px solid;
      @media (max-width: ($sm - 0.02)) {
        border-radius: 5px;
        padding: 8px;
        font-size: 16px;
      }
      @media (min-width: $sm) {
        border-radius: 10px;
        padding: 14px;
        font-size: 18px;
      }

      .text-holder {
        .speaker-container {
          display: inline-block;
          align-items: center;
          transform: translateY(20%);
          @media (max-width: ($sm - 0.02)) {
            margin-right: 2px;
          }
          @media (min-width: $sm) {
            margin-right: 5px;
          }

          .speaker {
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: ($sm - 0.02)) {
              width: 22px;
              height: 22px;
            }
            @media (min-width: $sm) {
              width: 30px;
              height: 30px;
            }

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .result-card {
    width: 100%;
    border-radius: 10px;
    border: 3px solid transparent;
    @media (max-width: ($sm - 0.02)) {
      min-height: 148px;
      border-radius: 10px;
      padding: 10px;
      font-size: 16px;
    }
    @media (min-width: $sm) {
      min-height: 188px;
      border-radius: 15px;
      padding: 15px;
      font-size: 18px;
    }

    > p {
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 10px;
      }
      @media (min-width: $sm) {
        margin-bottom: 15px;
      }
    }

    .inner-card {
      border: 1px solid transparent;
      text-align: center;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
      @media (max-width: ($sm - 0.02)) {
        border-radius: 10px;
        padding: 5px;
      }
      @media (min-width: $sm) {
        border-radius: 15px;
        padding: 10px;
      }

      .inner-card-content {
        font-style: italic;
      }

      .percentage {
        font-weight: bold;
      }
    }

    .rank-stars {
      margin-top: 15px;
    }
  }
}