@import '../../styles/globalConfigs';

.choicesCardContainer {
  position: sticky;
  display: flex;
  z-index: 2;
  transition: top 0.2s ease-in-out;

  &.sectionNavMenu {
    @media (min-width: $sm) {
      top: 125px;
    }
  }

  @media (min-width: $sm) {
    top: 70px;
  }
  @media (max-width: ($sm - 0.02)) {
    top: 65px;
  }
}

.choicesCard {
  margin: 6px 0;
  white-space: nowrap;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: sticky;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    padding: 4px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    padding: 6px;
  }

  .choiceItem {
    touch-action: none;
    border-radius: 50rem;
    overflow: hidden;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    @media (max-width: ($sm - 0.02)) {
      font-size: 15px;
      margin: 7px;
      padding: 1px 9px;
    }
    @media (min-width: $sm) {
      font-size: 21px;
      margin: 11px;
      padding: 1px 12px;
    }

    & * {
      transition: all 0.3s ease-in-out;
    }

    &.used {
      cursor: default;
      border-color: transparent !important;
      color: transparent !important;
    }
  }

  &.clickable {
    .choiceItem {
      cursor: pointer;

      > * {
        pointer-events: none;
      }
    }
  }
}
