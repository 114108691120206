@import './../../../../styles/globalConfigs';

.grammarFormulaTitle {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    border: 1px solid;
    font-size: 14px;
    padding: 5px 10px;
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    border: 2px solid;
    font-size: 18px;
    padding: 6px 12px;
    margin-bottom: 14px;
    min-width: 410px;
  }
}
