@import '../../../styles/globalConfigs';

[data-jsx='Conversation'] {
  .conversation-wrapper {
    justify-content: center;
    @media (max-width: $lg - 0.02) {
      display: block;
    }
    @media (min-width: $lg) {
      display: flex;
    }

    .hint-button-container {
      display: flex;
      align-items: center;
      height: fit-content;
      gap: 20px;
    }

    .first-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @media (max-width: ($sm - 0.02)) {
        padding-right: 14px;
        padding-left: 14px;
      }
      @media (min-width: $sm) {
        padding-right: 20px;
        padding-left: 20px;
      }

      .first-section-inner {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        max-width: 100%;
      }

      .buttons-control {
        display: flex;
        justify-content: space-between;

        @media (max-width: ($sm - 0.02)) {
          margin-bottom: 10px;
        }
        @media (min-width: $sm) {
          margin-bottom: 25px;
        }

        .characters-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;

          .character-container {
            &:first-child {
              @media (max-width: ($sm - 0.02)) {
                margin-right: 20px;
              }
              @media (min-width: $sm) {
                margin-right: 28px;
              }
            }

            .avatar {
              text-align: center;
              border-radius: 50%;
              overflow: hidden;
              margin-right: auto;
              margin-left: auto;
              @media (max-width: ($sm - 0.02)) {
                width: 38px;
                height: 38px;
              }
              @media (min-width: $sm) {
                width: 54px;
                height: 54px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            &.active {
              .avatar {
                @media (max-width: ($sm - 0.02)) {
                  border: 2px solid;
                }
                @media (min-width: $sm) {
                  border: 3px solid;
                }
              }

              .character-name {
                font-weight: 500;
              }
            }

            .character-name {
              text-align: center;
              @media (max-width: ($sm - 0.02)) {
                font-size: 11px;
              }
              @media (min-width: $sm) {
                font-size: 16px;
              }
            }
          }
        }

        .memorize-controls-extra-space {
          width: 136px;
        }

        .type-method-buttons {
          display: flex;
          justify-content: center;

          button {
            @media (max-width: ($sm - 0.02)) {
              width: 40px;
              height: 40px;
            }
            @media (min-width: $sm) {
              width: 57px;
              height: 57px;
            }
          }

          .voice-btn {
            @media (max-width: ($sm - 0.02)) {
              margin-left: 8px;
            }
            @media (min-width: $sm) {
              margin-left: 11px;
            }
          }
        }
      }

      .start-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid;
        font-weight: 500;
        margin: auto;
        @media (max-width: ($sm - 0.02)) {
          width: 45px;
          height: 45px;
          font-size: 14px;
        }
        @media (min-width: $sm) {
          width: 60px;
          height: 60px;
          font-size: 21px;
        }

        > svg {
          width: 60%;
          height: 60%;
          object-fit: contain;
        }
      }

      .recording-section-right-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        @media (max-width: ($sm - 0.02)) {
          width: 80px;
        }
        @media (min-width: $sm) {
          width: 100px;
        }
      }

      .cancel-button-container {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: ($sm - 0.02)) {
          height: 45px;
        }
        @media (min-width: $sm) {
          height: 60px;
        }
      }

      .convs-img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 462px;
        max-width: 100%;
        height: auto;
        object-fit: contain;
        max-height: 228px;
        margin: {
          right: auto;
          left: auto;
        }
        @media (max-width: ($sm - 0.02)) {
          margin-bottom: 10px;
        }
        @media (min-width: $sm) {
          margin-bottom: 15px;
        }

        &.hidden-mobile {
          img {
            @media (max-width: ($lg - 0.02)) {
              visibility: hidden;
              display: none;
            }
          }
        }

        .convs-img {
          max-width: 100%;
          @media (max-width: ($sm - 0.02)) {
            height: auto;
            width: 200px;
          }
          @media (min-width: $sm) {
            max-height: 228px;
          }
          object-fit: contain;
        }
      }
    }

    .second-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      @media (max-width: $lg - 0.02) {
        margin-right: auto;
        margin-left: auto;
      }
      @media (max-width: ($sm - 0.02)) {
        max-width: 100%;
        padding-right: 14px;
        padding-left: 14px;
      }
      @media (min-width: $sm) and (max-width: ($xlg - 0.02)) {
        max-width: 699px;
        padding-right: 20px;
        padding-left: 20px;
      }
      @media (min-width: $lg) {
        margin-right: 6px;
      }
      @media (min-width: $xlg) {
        max-width: 900px;
        padding-right: 20px;
        padding-left: 20px;
      }

      .convs-card-container {
        * {
          transition: all 0.5s;
        }

        overflow: hidden;
        border-radius: 20px;
        position: relative;
        @media (max-width: ($lg - 0.02)) {
          height: calc(100dvh - 348px);
          min-height: 300px;
          max-height: 500px;
        }
        @media (min-width: $lg) {
          height: calc(100dvh - 225px);
          min-height: 350px;
          max-height: 580px;
        }
      }

      .convs-card {
        position: relative;
        max-width: 100%;
        border-radius: 20px;
        overflow: visible;

        @media (max-width: ($sm - 0.02)) {
          width: 100%;
          margin-top: 12px;
          height: calc(100% - 12px);
        }
        @media (min-width: $sm) and (max-width: ($xlg - 0.02)) {
          width: 660px;
          margin-top: 20px;
          height: calc(100% - 20px);
        }
        @media (min-width: $xlg) {
          width: 860px;
          margin-top: 20px;
          height: calc(100% - 20px);
        }

        .convs-card-inner {
          @media (max-width: ($sm - 0.02)) {
            padding: 35px 10px 1px;
          }
          @media (min-width: $sm) {
            padding: 46px 15px 5px;
          }

          &.isMobile {
            height: 100%;
            overflow: auto;
          }

          &.with_hint_button {
            @media (max-width: ($sm - 0.02)) {
              padding-bottom: 50px !important;
            }
            @media (min-width: $sm) {
              padding-bottom: 80px !important;
            }
          }
        }

        &.hidden-mobile {
          .title-box {
            @media (max-width: ($lg - 0.02)) {
              display: none;
            }
          }

          .convs-card-inner {
            @media (max-width: ($sm - 0.02)) {
              padding: 12px 0 12px 12px;
              overflow: auto;
            }
            @media (min-width: $sm) and (max-width: ($lg - 0.02)) {
              padding: 20px;
            }
          }
        }

        .title-box {
          position: absolute;
          height: auto;
          top: 0;
          left: 25px;
          transform: translateY(-25%);
          max-width: 85%;
          z-index: 1;

          @media (max-width: ($sm - 0.02)) {
            width: 280px;
          }
          @media (min-width: $sm) {
            width: 317px;
          }

          .title-value {
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            right: 0;
            display: block;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            color: white;
            white-space: nowrap;
            text-shadow: 0 3px 6px rgb(0 0 0 / 70%);
            line-height: 39px;
            width: calc(85%);
            transform: translateX(-50%);
            @media (max-width: ($sm - 0.02)) {
              font-size: 16px;
            }
            @media (min-width: $sm) {
              font-size: 20px;
            }

            * {
              color: inherit;
            }
          }
        }

        .single-convs-wrapper {
          display: flex;
          align-items: center;

          .profile {
            transition: all 0.3s ease-out;
            @media (max-width: ($sm - 0.02)) {
              width: 29px;
            }
            @media (min-width: $sm) {
              width: 40px;
            }

            display: flex;
            flex-direction: column;
            align-items: center;

            .avatar {
              position: relative;
              border: 2px solid transparent;
              border-radius: 50%;

              @media (max-width: ($sm - 0.02)) {
                width: 29px;
                height: 29px;
              }
              @media (min-width: $sm) {
                width: 40px;
                height: 40px;
              }

              .reset-cover {
                cursor: pointer;
                border-radius: 50%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.42);

                > svg {
                  width: 60%;
                  height: 60%;
                }
              }

              img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .username {
              margin-top: 3px;
              display: block;
              text-align: center;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;

              @media (max-width: ($sm - 0.02)) {
                max-width: 43px;
              }
              @media (min-width: $sm) and (max-width: ($xlg - 0.02)) {
                max-width: 60px;
              }
            }
          }

          .chat-box {
            transition: all 0.3s ease-out;
            max-width: 100%;
            @media (max-width: ($sm - 0.02)) {
              margin-left: 15px;
              width: 100%;
            }
            @media (min-width: $sm) and (max-width: ($xlg - 0.02)) {
              margin-left: 22px;
              width: 455px;
            }
            @media (min-width: $xlg) {
              width: 650px;
            }

            input {
              width: 100%;
              border: 0;
              background: transparent;
              outline: none;
            }
          }

          &.dialog-on-voice {
            .chat-box {
              transform: scale(1.03);
              @media (max-width: ($sm - 0.02)) {
                margin-left: 22px;
              }
              @media (min-width: $sm) and (max-width: ($xlg - 0.02)) {
                margin-left: 30px;
                width: 441px;
              }
              @media (min-width: $xlg) {
                margin-left: 36px;
              }
            }

            .profile {
              transform: scale(1.2);
            }
          }

          &.rp-dialog-active {
            .profile {
              transform: scale(1.2);
            }
          }

          .phrases-options {
            display: flex;
            align-items: center;

            span {
              display: inline-flex;

              img {
                width: auto;
                height: auto;
                max-height: 100%;
                max-width: 100%;
                object-fit: fill;
              }
            }

            .speaker {
              @media (max-width: ($sm - 0.02)) {
                width: 14px;
              }
              @media (min-width: $sm) {
                width: 20px;
              }
            }

            .more {
              @media (max-width: ($sm - 0.02)) {
                width: 20px;
              }
              @media (min-width: $sm) {
                width: 28px;
              }
            }
          }

          .dialog-right-items {
            @media (max-width: ($sm - 0.02)) {
              width: 105px;
              min-height: 55px;
              justify-content: center;
            }
            @media (min-width: $sm) {
              width: 105px;
              min-height: 69px;
              margin-left: 20px;
            }

            display: flex;
            align-items: center;

          }

          .dialog-wrapper {
            position: relative;
            overflow-wrap: anywhere;
          }

          .dialog-textarea {
            overflow: hidden;
            top: 0;
            left: 0;
            position: absolute;
            border: none;
            background-color: transparent;
            resize: none;
            width: 100%;
            height: 100%;
            padding: 0;
            outline: 0;
            @media (max-width: ($sm - 0.02)) {
              background-size: 14.15px;
            }
            @media (min-width: $sm) {
              background-size: 18px;
            }
          }

          .dialog-textarea-cover {
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }

  .button-group {
    font-family: 'Roboto Flex', $SegoeUI, sans-serif;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 113px;
    font-size: 21px;
    @media(max-width: ($lg - 0.02)) {
      gap: 0;
      margin-right: 0;
      font-size: 16px;
      justify-content: space-around;
    }

    @media(max-width: (430px - 0.02)) {
      justify-content: center;
      margin-bottom: 40px;
      margin-right: 20px;
      .archive-button {
        position: relative;
        transform: translate(46px, 50px);
      }
      .skip-button {
        position: relative;
        transform: translate(-31px, 50px);
      }
    }
  }

  .select-character-page-container {
    font-family: 'Roboto Flex', $SegoeUI, sans-serif;
    display: flex;
    flex-direction: column;
    min-height: calc(100dvh - 309px);
    margin-bottom: 30px;

    .select-title-text {
      text-align: center;
      font-weight: 700;
      @media (max-width: ($sm - 0.02)) {
        font-size: 26px;
        margin-bottom: 36px;
      }
      @media (min-width: $sm) {
        font-size: 32px;
        margin-bottom: 56px;
      }
    }

    .select-text {
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        font-size: 18px;
        margin-bottom: 50px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
        margin-bottom: 100px;
      }
    }

    .characters-wrapper {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      width: 900px;
      max-width: 100%;
      @media (max-width: ($sm - 0.02)) {
        gap: 82px;
        flex-wrap: wrap;
      }
      @media (min-width: $sm) {
        gap: 115px;
      }

      .character-container {
        cursor: pointer;
        perspective: 700px;

        > * {
          display: flex;
          flex-direction: column;
          align-items: center;
          transition: all 0.4s ease-in-out;
        }


        .avatar {
          transition: border-color 0.4s ease-in-out;
          text-align: center;
          border-radius: 50%;
          overflow: hidden;
          margin-left: auto;
          margin-right: auto;
          @media (max-width: ($sm - 0.02)) {
            width: 78px;
            height: 78px;
            border: 4px solid transparent;
          }
          @media (min-width: $sm) {
            width: 110px;
            height: 110px;
            border: 6px solid transparent;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .selected {
          animation: rotateAvatarStep1 0.4s ease-in-out forwards, rotateAvatarStep2 0.4s ease-in-out 0.4s forwards,
          rotateAvatarStep3 0.4s ease-in-out 0.8s forwards;
        }

        .unselected {
          animation: unselectAvatar 0.4s ease-in-out forwards;
        }

        .character-name {
          text-align: center;
          @media (max-width: ($sm - 0.02)) {
            font-size: 18px;
          }
          @media (min-width: $sm) {
            font-size: 23px;
          }
        }
      }
    }
  }

  @keyframes rotateAvatarStep1 {
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes rotateAvatarStep2 {
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes rotateAvatarStep3 {
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes unselectAvatar {
    0% {
      transform: scale(1.2) rotateY(0deg);
    }
    100% {
      transform: scale(1) rotateY(0deg);
    }
  }
}

.character-role-play-svg {
  margin-top: 10px;
  height: 50px;
  width: 50px;
  @media (max-width: $sm) {
    width: 30px;
    height: 30px;
  }

  > svg {
    height: 100%;
    width: 100%;
  }
}

.selected-role-play-svg {
  height: 60px;
  width: 60px;
  @media (max-width: $sm) {
    width: 40px;
    height: 40px;

  }
}