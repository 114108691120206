@import './../../styles/globalConfigs';

.card {
  position: relative;
  padding: 0 30px 30px !important;

  :global(.add-category-card) {
    @media (max-width: ($sm - 0.02)) {
      padding: 0;
    }
    background: transparent !important;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 50px;
  border-bottom: 0.75px solid;
  margin-bottom: 20px;

  .backButton {
    display: flex;
    width: 20px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .headerTitle {
    position: absolute;
    transform: translateX(50%);
    right: 50%;
    width: fit-content;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    align-self: center;
  }
}

.categories {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    gap: 8px;
    margin-bottom: 50px;
  }
  @media (min-width: $sm) {
    gap: 15px;
    margin-bottom: 60px;
  }

  .categoryItem {
    width: 80px;

    .categoryColor {
      border-radius: 10px;
      width: 100%;
      aspect-ratio: 1;
      margin-bottom: 10px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 16%);
    }

    .categoryName {
      display: block;
      line-height: 18px;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }
  }
}
