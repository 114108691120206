@import './../../styles/globalConfigs';

.flag {
  display: inline-block;
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 6px;
}
