@import './../../../styles/globalConfigs';

.text {
  flex: 1;
  @media (max-width: ($sm - 0.02)) {
    margin-right: 10px;
    font-size: 14px;
  }
  @media (min-width: $sm) {
    margin-right: 10px;
    font-size: 18px;
  }
}

.toggleOptionWrapper {
  height: fit-content;
  display: flex;
  align-items: center;

  .toggleOption {
    transition: background 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    background: #d0d2d3;
    border-radius: 50rem;
    width: 50px;
    height: 30px;
    border: 1px solid #ffffff;

    &:focus {
      .circle {
        box-shadow: 0 0 1px 4px var(--box-shadow-circle-color);
      }
    }

    &.active {
      &:focus {
        .circle {
          box-shadow: 0 0 1px 8px var(--box-shadow-circle-color);
        }
      }
    }

    .circle {
      display: block;
      position: absolute;
      width: 53%;
      height: 97%;
      scale: 0.65;
      //transform: translate(-7px, -79%);
      left: 0;
      //top: 50%;
      transition: all 300ms ease;
      border-radius: 50%;
      background: var(--bg-circle-color, #fcfcfc);
      box-shadow: none;

      &:focus {
        box-shadow: 0 0 1px 4px var(--box-shadow-circle-color);
      }

      &.active {
        transform: translateX(21px);
        scale: 1;

        &:focus {
          box-shadow: 0 0 1px 8px var(--box-shadow-circle-color);
        }
      }

      .tickSvg {
        transition: all 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 12px;
        width: 12px;

        > svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &.pending {
    .toggleOption {
      background: rgba(208, 210, 211, 0.6);
      border-color: rgba(255, 255, 255, 0.6);

      &.active {
        background: rgba(234, 150, 181, 0.6);
      }

      &:after {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

.toggleContainer {
  position: relative;

  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(2px, -50%);
    left: 4px;
    width: 17px;
    height: 17px;
    color: #010101;

    > * {
      border-width: 2px;
      width: 100%;
      height: 100%;
    }

    &.active {
      transform: translate(23px, -50%);
    }
  }
}
