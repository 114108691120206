@import './../../styles/globalConfigs';

.container {
  display: flex;
  align-items: center;
  gap: 4px;

  .badgeContainer {
    width: 80px;
    height: auto;
    position: relative;

    .badge {
      svg {
        width: 100%;
        height: auto;
      }
    }

    .badgeContent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 8px), -50%);
    }
  }
}
