@import './../../../../styles/globalConfigs';

.dividerContainer {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  overflow: hidden;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 15px;
  }
  @media (min-width: $sm) {
    margin-bottom: 22px;
  }

  div {
    width: 100%;
  }

  .templateWidth {
    margin-right: auto;
    margin-left: auto;
    @media (min-width: $xsm) {
      max-width: 540px;
    }

    @media (min-width: $sm) {
      max-width: 720px;
    }

    @media (min-width: $md) {
      max-width: 900px;
    }
  }

  hr {
    margin: revert;
    //margin: {
    //  top: revert;
    //  bottom: revert;
    //  left: 20px;
    //  right: 20px;
    //}
    opacity: 1;
  }
}
