@import './../../styles/globalConfigs';

.radioButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  &.border {
    border: 2px solid;
  }

  .inner {
    opacity: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: opacity 0.3s ease-out;
  }

  &.selected {
    //border: 1px solid;

    .inner {
      opacity: 1;
    }
  }
}
