@import 'src/styles/globalConfigs';

.lottieBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.portraitLottieBackgroundContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  @media (orientation: portrait) {
    height: 100vh;
    width: 100vw;
  }

  @media (orientation: landscape) {
    transform: translate(-50%, -50%) rotate(90deg);
    height: 100vw;
    width: 100vh;
  }
}

.landscapeLottieBackgroundContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  @media (orientation: portrait) {
    transform: translate(-50%, -50%) rotate(90deg);
    height: 100vw;
    width: 100vh;
  }

  @media (orientation: landscape) {
    height: 100vh;
    width: 100vw;
  }
}

.phonoMetrixLottieBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-height: 100%;
  max-width: 600px;
  transform: rotateY(180deg);
}

.portraitLottieBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  > div {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

.gameCards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: auto;
  @media (min-width: $sm) {
    max-width: 1000px;
  }
}

.gameCardContainer {
  @media (max-width: ($sm - 0.02)) {
    padding-top: 9px;
    padding-bottom: 24px;
  }
  @media (min-width: $sm) {
    padding-top: 13px;
    padding-bottom: 34px;
  }

  .gameCard {
    display: block;
    margin: auto;
    position: relative;
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      width: 281px;
      max-height: 66px;
      border-radius: 18px;
    }
    @media (min-width: $sm) {
      width: 395px;
      max-height: 94px;
      border-radius: 25px;
    }

    .iconCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;

      @media (max-width: ($sm - 0.02)) {
        width: 43px;
        height: 43px;
        left: 16px;
        top: -9px;
      }
      @media (min-width: $sm) {
        width: 61px;
        height: 61px;
        left: 23px;
        top: -13px;
      }

      img {
        width: 35px;
        height: 35px;
        object-fit: cover;
      }
    }

    .cardContent {
      @media (max-width: ($sm - 0.02)) {
        padding: 4px 4px 7px;
      }
      @media (min-width: $sm) {
        padding: 6px 6px 10px;
      }
    }

    .cardTitle {
      color: #ffffff;
      text-align: center;
      font-style: italic;
      @media (max-width: ($sm - 0.02)) {
        margin-bottom: 4px;
        font-size: 16px;
      }
      @media (min-width: $sm) {
        margin-bottom: 6px;
        font-size: 22px;
      }
    }

    .cardDesc {
      color: #ffffff;
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        font-size: 8px;
      }
      @media (min-width: $sm) {
        font-size: 12px;
      }

      & * {
        margin-bottom: 0;
      }
    }
  }
}

.gamePageHeaderContainer {
  display: inline-block;
  width: 100%;

  .headerFirstPart {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media (max-width: ($sm - 0.02)) {
      margin: 20px 29px 0;
    }
    @media (min-width: $sm) {
      margin: 25px 50px 0;
    }

    .backButton {
      @media (max-width: ($sm - 0.02)) {
        height: 14px;
        width: 14px;
      }
      @media (min-width: $sm) {
        width: 24px;
        height: 24px;
      }

      > svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .headerTitle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (max-width: ($sm - 0.02)) {
        font-size: 18px;
      }
      @media (min-width: $sm) {
        font-size: 25px;
      }
    }
  }
}

.pageBorderedTitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .beforeLine,
  .afterLine {
    display: block;
    height: 1px;
    width: 100%;
    flex: 1 0;
  }

  .titleBox {
    border: 1px solid;
    border-radius: 10px;
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      font-size: 17px;
      padding: 3px 6px;
      min-width: 148px;
    }
    @media (min-width: $sm) {
      font-size: 24px;
      padding: 5px 10px;
      min-width: 250px;
    }
  }

  .textBox {
    border: 1px solid;
    text-align: center;
    max-width: 80%;
    @media (max-width: ($sm - 0.02)) {
      font-size: 13px;
      padding: 7px 10px;
      border-radius: 8px;
    }
    @media (min-width: $sm) {
      font-size: 18px;
      padding: 14px 30px;
      min-width: 250px;
      width: 755px;
      border-radius: 14px;
    }
  }
}

.levelsColorGuideContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  @media (max-width: ($sm - 0.02)) {
    max-width: 360px;
    margin: 12px auto;
  }
  @media (min-width: $sm) {
    max-width: 690px;
    margin: 30px auto;
  }

  .colorGuideItem {
    display: flex;
    align-items: center;

    .colorBadge {
      display: block;
      border-radius: 50%;
      @media (max-width: ($sm - 0.02)) {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
      @media (min-width: $sm) {
        width: 27px;
        height: 27px;
        margin-right: 12px;
      }
    }

    .colorObjective {
      @media (max-width: ($sm - 0.02)) {
        font-size: 12px;
      }
      @media (min-width: $sm) {
        font-size: 24px;
      }
    }
  }
}

.gameLevelsCardContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 20px;
  }
  @media (min-width: $sm) {
    margin-bottom: 32px;
  }
}

.vicabMasterStatusContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    font-size: 16px;
    margin-bottom: 16px;
  }
  @media (min-width: $sm) {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .title {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 8px;
    }
    @media (min-width: $sm) {
      margin-right: 19px;
    }
  }

  .vicabMasterStatusCard {
    box-shadow: 1px 3px 6px #00000026;
    @media (max-width: ($sm - 0.02)) {
      padding: 0 12px;
      border-radius: 12px;
    }
    @media (min-width: $sm) {
      padding: 2px 18px;
      border-radius: 18px;
    }

    .badge {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50rem;
      @media (max-width: ($sm - 0.02)) {
        min-width: 44px;
        height: 21px;
        padding: 0 5px;
        margin: 3px 6px;
      }
      @media (min-width: $sm) {
        min-width: 75px;
        height: 35px;
        padding: 0 8px;
        margin: 5px 11px;
      }
    }
  }
}

.blinkPageContainer {
  background-size: contain;
  background-repeat: repeat-y;
  background-attachment: fixed;
}

.gameBadgesBarContainer {
  position: relative;
  margin: 20px 0;

  .gameBadgesBar {
    margin: auto;
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      width: 307px;
    }
    @media (min-width: $sm) {
      width: 440px;
    }

    > svg {
      width: 100%;
      height: auto;
    }
  }

  .gameTimerButton {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -30%);
    @media (max-width: ($sm - 0.02)) {
      width: 63px;
      height: 63px;
    }
    @media (min-width: $sm) {
      width: 95px;
      height: 95px;
    }
  }

  .gameTimerButtonImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .gameTimerButtonContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: ($sm - 0.02)) {
      font-size: 14px;
    }
    @media (min-width: $sm) {
      font-size: 18px;
    }
  }
}

.playgroundArea {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 395px);
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 478px);
  }

  .startButtonTypeCard {
    @media (max-width: ($sm - 0.02)) {
      font-size: 24px;
      width: 105px;
      height: 105px;
    }
    @media (min-width: $sm) {
      font-size: 34px;
      width: 105px;
      height: 105px;
    }
  }

  .startButtonTypeCircle {
    border-radius: 50%;
    @media (max-width: ($sm - 0.02)) {
      font-size: 24px;
      width: 105px;
      height: 105px;
    }
    @media (min-width: $sm) {
      font-size: 34px;
      width: 105px;
      height: 105px;
    }
  }

  .startButtonTypeInput {
    @media (max-width: ($sm - 0.02)) {
      font-size: 24px;
      width: 150px;
      height: 47px;
    }
    @media (min-width: $sm) {
      font-size: 34px;
      width: 210px;
      height: 66px;
    }
  }
}

.circularStartButton {
  border-radius: 50%;
  @include pink-background(right);
  color: #fcfcfc;

  @media (max-width: ($sm - 0.02)) {
    font-size: 18px;
    width: 80px;
    height: 80px;
  }
  @media (min-width: $sm) {
    font-size: 24px;
    width: 120px;
    height: 120px;
  }
}

.rounded {
  border-radius: 12px;
}

.boxShadow {
  box-shadow: 2.8px 2.8px 5px rgba(0, 0, 0, 0.53);
}

.gameInputBox {
  text-align: center;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 150ms;

  .inputBoxCharacter {
    transition: color 150ms;
    text-transform: capitalize;
  }
}

.phonoMetrixPlaygroundArea {
  .lettersContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: $lg;

    .letterButton {
      text-transform: capitalize;

      @media (max-width: ($sm - 0.02)) {
        margin: 5px;
        font-size: 24px;
        width: 40px;
        height: 40px;
      }
      @media (min-width: $sm) {
        margin: 10px;
        font-size: 34px;
        width: 60px;
        height: 60px;
      }

      > span {
        transition: all 150ms ease-out;
      }

      &.hide {
        cursor: default;

        > span {
          opacity: 0;
        }
      }
    }
  }
}

.gameInputBox {
  @media (max-width: ($sm - 0.02)) {
    margin: 0 auto 13px;
    border-radius: 12px;
    font-size: 24px;
    width: 287px;
    height: 43px;
  }
  @media (min-width: $sm) {
    margin: 0 auto 14px;
    border-radius: 15px;
    font-size: 34px;
    width: 430px;
    height: 60px;
  }
}

.blinkQuestionCard {
  @media (max-width: ($sm - 0.02)) {
    font-size: 18px;
    padding: 2px;
    text-align: center;
    margin-bottom: 18px;
  }
  @media (min-width: $sm) {
    font-size: 24px;
    padding: 4px;
    text-align: center;
    margin-bottom: 24px;
  }
}

.accentQuestionCard {
  text-align: center;
  width: fit-content;

  @media (max-width: ($sm - 0.02)) {
    font-size: 18px;
    padding: 2px 8px;
    min-width: 173px;
    margin: 0 auto 18px;
  }
  @media (min-width: $sm) {
    font-size: 24px;
    padding: 4px 12px;
    min-width: 260px;
    margin: 0 auto 24px;
  }
}

.squareItemsGrid {
  display: grid;
  grid-template-columns: auto auto auto;

  @media (max-width: ($sm - 0.02)) {
    gap: 9px;
  }
  @media (min-width: $sm) {
    gap: 12px;
  }

  &.isCircle {
    .gridItem {
      border-radius: 50% !important;
    }
  }

  .gridItem {
    transition: background-color 150ms ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    @media (max-width: ($sm - 0.02)) {
      font-size: 12px;
      width: 95px;
      height: 60px;
    }
    @media (min-width: $sm) {
      font-size: 15px;
      width: 138px;
      height: 78px;
    }
  }
}

.limbicPowerPlaygroundArea {
  position: relative;

  .squareItemsGrid {
    perspective: 800px;
  }

  .countDown {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;

    @media (max-width: ($sm - 0.02)) {
      font-size: 52px;
    }
    @media (min-width: $sm) {
      font-size: 62px;
    }
  }

  .gridItem {
    transition: 300ms;
    transform-style: preserve-3d;
    transform: rotateY(0);

    &.flipped {
      transform: rotateY(-180deg);
    }

    .gridItemContent {
      backface-visibility: hidden;
    }
  }
}

.gameFooter {
  display: inline-block;
  width: 100%;
  @media (max-width: ($sm - 0.02)) {
    margin-top: 8px;
  }
  @media (min-width: $sm) {
    margin-top: 12px;
  }

  .levelNumberBoxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.footerContainer {
  display: grid;

  @media (max-width: ($sm - 0.02)) {
    max-width: 262px;
    margin: 8px auto 10px;
    grid-template-areas:
      'card card'
      'reset-button button';
  }
  @media (min-width: $sm) {
    max-width: 1200px;
    margin: 12px auto 20px;
    grid-template-areas: 'reset-button card button';
    grid-template-columns: auto;
  }
}

.bestRankCard {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  border-radius: 12px;
  margin: auto;
  grid-area: card;

  @media (max-width: ($sm - 0.02)) {
    width: 262px;
    padding: 5px 8px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  @media (min-width: $sm) {
    width: 334px;
    padding: 7px 12px;
    font-size: 16px;
  }

  .text {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 6px;
    }
    @media (min-width: $sm) {
      margin-right: 15px;
    }
  }

  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    @media (max-width: ($sm - 0.02)) {
      height: 17px;
      width: 76px;
    }
    @media (min-width: $sm) {
      height: 22px;
      width: 95px;
    }
  }
}

.gamesPrimaryButton {
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    height: 21px;
    width: 90px;
    font-size: 11px;
  }
  @media (min-width: $sm) {
    height: 45px;
    width: 210px;
    font-size: 20px;
  }
}

.rightButtonContainer {
  grid-area: button;
  display: flex;
  align-items: center;
  @media (max-width: ($sm - 0.02)) {
    justify-content: flex-start;
  }
  @media (min-width: $sm) {
    justify-content: center;
  }
}

.gameResetButtonContainer {
  max-width: 100%;
  grid-area: reset-button;
  display: flex;
  align-items: center;

  @media (max-width: ($sm - 0.02)) {
    justify-content: flex-end;
  }
  @media (min-width: $sm) {
    justify-content: center;
  }

  button {
    @media (max-width: ($sm - 0.02)) {
      width: 90px;
      justify-content: flex-end;
    }
    @media (min-width: $sm) {
      width: 210px;
      justify-content: center;
    }
  }
}

.gameResultContainer {
  padding-top: 25px;
  padding-bottom: 25px;

  .title {
    text-align: center;
    font-weight: 600;
    font-style: italic;
    text-shadow: 1px 1px 1px #00000080;
    text-transform: uppercase;

    @media (max-width: ($sm - 0.02)) {
      font-size: 40px;
    }
    @media (min-width: $sm) {
      font-size: 52px;
    }
  }

  .userAvatarImage {
    margin-right: auto;
    margin-left: auto;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 17px;
    }
    @media (min-width: $sm) {
      margin-bottom: 26px;
    }
  }

  .gameResultDetailsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 14px;
    }
    @media (min-width: $sm) {
      margin-bottom: 20px;
    }

    .leftSection {
      margin-top: 5px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      span {
        white-space: nowrap;
        @media (max-width: ($sm - 0.02)) {
          font-size: 18px;
        }
        @media (min-width: $sm) {
          font-size: 22px;
        }
      }

      .circleGameAndText {
        display: flex;
        gap: 10px;
        width: fit-content;
        margin-top: 10px;
      }

      .iconCircle {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 7px;
        width: 33px;
        height: 33px;

        > svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .rightSection {
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        margin-left: 16px;
      }
      @media (min-width: $sm) {
        margin-left: 22px;
      }

      p {
        @media (max-width: ($sm - 0.02)) {
          font-size: 22px;
        }
        @media (min-width: $sm) {
          font-size: 26px;
        }

        span {
          @media (max-width: ($sm - 0.02)) {
            font-size: 19px;
          }
          @media (min-width: $sm) {
            font-size: 24px;
          }
        }
      }

      .clockIcon {
        @media (max-width: ($sm - 0.02)) {
          width: 23px;
          margin-right: 5px;
        }
        @media (min-width: $sm) {
          width: 31px;
          margin-right: 8px;
        }

        > svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .doneButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pageMiddleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    margin-top: 9px;
    min-height: calc(100dvh - 158px);
  }
  @media (min-width: $sm) {
    margin-top: 12px;
    min-height: calc(100dvh - 192px);
  }
}

.userAvatarImage {
  border-radius: 50%;
  @include golden-background(right);
  display: flex;
  align-items: center;
  justify-content: center;

  &:global(.size-xl) {
    @media (max-width: ($sm - 0.02)) {
      width: 118px;
      height: 118px;
    }
    @media (min-width: $sm) {
      width: 158px;
      height: 158px;
    }
  }

  &:global(.size-md) {
    @media (max-width: ($sm - 0.02)) {
      width: 63px;
      height: 63px;
    }
    @media (min-width: $sm) {
      width: 90px;
      height: 90px;
    }
  }

  &:global(.size-sm) {
    @media (max-width: ($sm - 0.02)) {
      width: 51px;
      height: 51px;
    }
    @media (min-width: $sm) {
      width: 72px;
      height: 72px;
    }
  }

  &:global(.size-xs) {
    @media (max-width: ($sm - 0.02)) {
      width: 40px;
      height: 40px;
    }
    @media (min-width: $sm) {
      width: 60px;
      height: 60px;
    }
  }

  > img {
    border-radius: 50%;
    width: 92%;
    height: 92%;
    object-fit: cover;
  }
}

.userNameAndFlag {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 8px;
  }
  @media (min-width: $sm) {
    margin-bottom: 11px;
  }

  .flag {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    @media (max-width: ($sm - 0.02)) {
      height: 27px;
      margin-right: 7px;
    }
    @media (min-width: $sm) {
      height: 35px;
      margin-right: 10px;
    }
    > img {
      width: auto;
      height: 100%;
    }
  }

  p {
    @media (max-width: ($sm - 0.02)) {
      font-size: 20px;
    }
    @media (min-width: $sm) {
      font-size: 25px;
    }
  }
}

.goldRibbon {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: ($sm - 0.02)) {
    width: 220px;
  }
  @media (min-width: $sm) {
    width: 290px;
  }

  > svg {
    width: 100%;
    height: auto;
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    @media (max-width: ($sm - 0.02)) {
      font-size: 15px;
    }
    @media (min-width: $sm) {
      font-size: 18px;
    }
  }
}

.winnerRibbonContainer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ($sm - 0.02)) {
    margin-top: 68px;
    margin-bottom: 8px;
  }
  @media (min-width: $sm) {
    margin-top: 87px;
    margin-bottom: 11px;
  }

  .userAvatarImage {
    z-index: 0;
    position: absolute;
    bottom: 60%;
  }

  .winnerRibbon {
    z-index: 1;

    svg {
      width: 100%;
      height: auto;
    }

    @media (max-width: ($sm - 0.02)) {
      width: 288px;
    }
    @media (min-width: $sm) {
      width: 389px;
    }

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: ($sm - 0.02)) {
        font-size: 20px;
      }
      @media (min-width: $sm) {
        font-size: 25px;
      }
    }
  }
}

.starsContainer {
  display: flex;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 32px;
  }
  @media (min-width: $sm) {
    margin-bottom: 45px;
  }

  > span {
    &:not(:last-child) {
      @media (max-width: ($sm - 0.02)) {
        margin-right: 10px;
      }
      @media (min-width: $sm) {
        margin-right: 15px;
      }
    }

    > svg {
      width: 100%;
    }
  }

  .blankStar {
    @media (max-width: ($sm - 0.02)) {
      width: 20px;
    }
    @media (min-width: $sm) {
      width: 26px;
    }
  }

  .fillStar {
    @media (max-width: ($sm - 0.02)) {
      width: 21px;
    }
    @media (min-width: $sm) {
      width: 28px;
    }
  }
}

.gameRecordDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: ($sm - 0.02)) {
    margin: 10px auto 16px;
  }
  @media (min-width: $sm) {
    margin: 15px auto 15px;
  }

  .leftSection {
    .title {
      font-weight: 600;
      font-style: italic;
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        font-size: 30px;
      }
      @media (min-width: $sm) {
        font-size: 47px;
      }
    }

    p {
      @media (max-width: ($sm - 0.02)) {
        font-size: 15px;
      }
      @media (min-width: $sm) {
        font-size: 22px;
      }
    }

    span {
      @media (max-width: ($sm - 0.02)) {
        font-size: 20px;
      }
      @media (min-width: $sm) {
        font-size: 32px;
      }
    }

    .clockIcon {
      display: flex;
      @media (max-width: ($sm - 0.02)) {
        margin-right: 10px;
        width: 24px;
      }
      @media (min-width: $sm) {
        margin-right: 15px;
        width: 39px;
      }

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .rightSection {
    .rankCircleBadge {
      position: relative;
      @media (max-width: ($sm - 0.02)) {
        width: 112px;
        height: 112px;
      }
      @media (min-width: $sm) {
        width: 185px;
        height: 185px;
      }

      > img {
        width: 100%;
        height: 100%;
      }

      .rankTitle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
        }
        @media (min-width: $sm) {
          font-size: 25px;
        }
      }
    }
  }
}

.vicabMasterSection {
  > .title {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      margin: 12px auto 10px;
      font-size: 16px;
    }
    @media (min-width: $sm) {
      margin: 16px auto 14px;
      font-size: 26px;
    }
  }

  > .card {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: ($sm - 0.02)) {
      width: 690px;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 12px;
    }
    @media (min-width: $sm) {
      width: 690px;
      border-radius: 16px;
      margin-bottom: 32px;
      padding: 16px;
    }

    .vicabMasterCircles {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 22px;

      .circleItem {
        @media (max-width: ($sm - 0.02)) {
          width: 50%;
          max-width: 235px;
          padding: 10px;
        }
        @media (min-width: $sm) {
          width: 235px;
          padding: 10px;
        }

        > svg {
          width: 100%;
          height: auto;
        }
      }
    }

    .vicabMasterStatistic {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;

      .title {
        @media (max-width: ($sm - 0.02)) {
          font-size: 13px;
          margin-right: 10px;
        }
        @media (min-width: $sm) {
          font-size: 20px;
          margin-right: 20px;
        }
      }

      .progress {
        display: flex;
        border-radius: 50rem;
        @media (max-width: ($sm - 0.02)) {
          width: 162px;
          height: 11px;
        }
        @media (min-width: $sm) {
          width: 282px;
          height: 18px;
        }

        .progressBarSection {
          flex: 1;
          height: 100%;
          width: 100%;

          &:first-child {
            border-radius: 50rem 0 0 50rem;
            border-right: 1px solid;
          }

          &:last-child {
            border-radius: 0 50rem 50rem 0;
            border-left: 1px solid;
          }
        }
      }
    }
  }
}

.allRecordsPageContainer {
  @media (max-width: ($sm - 0.02)) {
    padding-bottom: 10px;
  }
  @media (min-width: $sm) {
    padding-bottom: 14px;
  }

  .gameCircularRanks {
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.5);
      padding-top: 5px;

      @media (max-width: ($sm - 0.02)) {
        margin: 8px;
        width: 66px;
        height: 66px;
      }
      @media (min-width: $sm) {
        margin: 14px;
        width: 84px;
        height: 84px;
      }

      p {
        @media (max-width: ($sm - 0.02)) {
          font-size: 13px;
        }
        @media (min-width: $sm) {
          font-size: 16px;
        }
      }

      span {
        @media (max-width: ($sm - 0.02)) {
          font-size: 18px;
        }
        @media (min-width: $sm) {
          font-size: 23px;
        }
      }
    }
  }

  .ranksCardContainer {
    @media (max-width: ($sm - 0.02)) {
      padding: 10px 5px;
    }
    @media (min-width: $sm) {
      padding: 15px 10px;
    }

    .cardTitle {
      text-align: center;
      font-style: italic;

      @media (max-width: ($sm - 0.02)) {
        font-size: 27px;
        margin-bottom: 10px;
      }
      @media (min-width: $sm) {
        font-size: 32px;
        margin-bottom: 14px;
      }
    }

    .ranksCard {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: auto;
      width: 424px;
      max-width: 100%;

      @media (max-width: ($sm - 0.02)) {
        border-radius: 7px;
      }
      @media (min-width: $sm) {
        border-radius: 10px;
      }

      .gameRankItem {
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          width: 68px;
          padding-top: 10px;
        }
        @media (min-width: $sm) {
          width: 83px;
          padding-top: 11px;
        }
      }

      .separatorLine {
        display: block;
        width: 100%;
        height: 2px;
      }

      .gameRankTitle {
        @media (max-width: ($sm - 0.02)) {
          font-size: 13px;
          margin-bottom: 1px;
        }
        @media (min-width: $sm) {
          font-size: 15px;
          margin-bottom: 3px;
        }
      }

      .gameRankCount {
        @media (max-width: ($sm - 0.02)) {
          font-size: 22px;
        }
        @media (min-width: $sm) {
          font-size: 27px;
        }
      }
    }
  }

  .gamesRanksContainer {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      display: flex;
      justify-content: center;
      margin-bottom: 14px;
    }

    > div {
      @media (min-width: $sm) {
        width: 50%;
      }
    }
  }

  .dualSection {
    .titleCard {
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      @media (max-width: ($sm - 0.02)) {
        width: 207px;
        height: 49px;
        font-size: 27px;
        margin: 10px auto 15px;
      }
      @media (min-width: $sm) {
        width: 335px;
        height: 80px;
        font-size: 43px;
        margin: 10px auto 30px;
      }

      .text {
        font-weight: 600;
        font-style: italic;
        color: #e1bd47;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }

    .numberOfWinsCard {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: ($sm - 0.02)) {
        font-size: 22px;
        width: 63px;
        height: 25px;
      }
      @media (min-width: $sm) {
        font-size: 35px;
        width: 102px;
        height: 41px;
      }
    }
  }

  .lastSection {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @media (max-width: ($sm - 0.02)) {
      margin-top: 20px;
    }
    @media (min-width: $sm) {
      margin-top: 40px;
    }

    .gameRankItem {
      text-align: center;
      @media (max-width: ($sm - 0.02)) {
        width: 110px;
      }
      @media (min-width: $sm) {
        width: 178px;
      }

      .separatorLine {
        display: block;
        width: 100%;
        height: 2px;
      }

      .gameRankTitle {
        margin-bottom: 2px;
        @media (max-width: ($sm - 0.02)) {
          font-size: 14px;
        }
        @media (min-width: $sm) {
          font-size: 21px;
        }
      }

      .gameRankCount {
        @media (max-width: ($sm - 0.02)) {
          font-size: 22px;
        }
        @media (min-width: $sm) {
          font-size: 35px;
        }
      }
    }
  }
}

.offlineSectionLobbyPage {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  bottom: 0;
  left: 0;
  right: 0;

  .circlesContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .topCircle {
    position: absolute;
    border-radius: 50%;

    @media (max-width: ($sm - 0.02)) {
      width: 300px;
      height: 300px;
      top: -138px;
      right: -96px;
    }
    @media (min-width: $sm) {
      width: 480px;
      height: 480px;
      top: -238px;
      right: -125px;
    }

    .content {
      position: absolute;

      @media (max-width: ($sm - 0.02)) {
        font-size: 19px;
        bottom: 85px;
        left: 40px;
      }
      @media (min-width: $sm) {
        font-size: 30px;
        bottom: 125px;
        left: 88px;
      }
    }
  }

  .bottomCircle {
    position: absolute;
    border-radius: 50%;

    @media (max-width: ($sm - 0.02)) {
      width: 564px;
      height: 564px;
      //bottom: -134px;
      bottom: -190px;
      left: -124px;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      width: 850px;
      height: 850px;
      bottom: -350px;
      left: -150px;
    }
    @media (min-width: $md) {
      width: 850px;
      height: 850px;
      bottom: -430px;
      left: -150px;
    }

    .content {
      position: absolute;
      @media (max-width: ($sm - 0.02)) {
        top: 52px;
        right: 154px;
      }
      @media (min-width: $sm) {
        top: 85px;
        right: 204px;
      }
    }

    .playerContainer {
      display: flex;
      align-items: center;

      &.firstPlayer {
        .buttons {
          @media (max-width: ($sm - 0.02)) {
            margin-right: 11px;
          }
          @media (min-width: $sm) {
            margin-right: 18px;
          }
        }
      }

      &.secondPlayer {
        justify-content: center;

        @media (max-width: ($sm - 0.02)) {
          margin-right: -8px;
          margin-left: -8px;
          border: 1px solid #fcfcfc;
          padding: 12px 8px;
          margin-top: 34px;
        }
        @media (min-width: $sm) {
          margin-right: -12px;
          margin-left: -12px;
          border: 2px solid #fcfcfc;
          padding: 16px 12px;
          margin-top: 45px;
        }

        .buttons {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        border-radius: 12px;
      }
    }

    .buttons {
      :not(:last-child) {
        @media (max-width: ($sm - 0.02)) {
          margin-bottom: 9px;
        }
        @media (min-width: $sm) {
          margin-bottom: 16px;
        }
      }
    }
  }

  .startButton {
    position: absolute;

    bottom: 35px;
    @media (max-width: ($xsm - 0.02)) {
      left: 0;
      width: 380px;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: $xsm) and (max-width: ($sm - 0.02)) {
      left: 100px;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      left: 214px;
    }
    @media (min-width: $md) {
      right: 0;
      width: calc(100vw - 700px);
      display: flex;
      justify-content: center;
    }
  }
}

.nameInput {
  outline: 0;
  border: 0;
  min-width: unset !important;
  text-align: center;

  &::placeholder {
    color: rgba(252, 252, 252, 0.5);
  }
}

.smallButton {
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: ($sm - 0.02)) {
    min-width: fit-content;
    width: 124px;
    min-height: 26px;
    padding: 5px;
    font-size: 14px;
  }
  @media (min-width: $sm) {
    width: 215px;
    min-height: 42px;
    padding: 7px;
    font-size: 20px;
  }
}

.mediumButton {
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    min-width: 160px;
    min-height: 30px;
    padding: 5px;
    font-size: 14px;
  }
  @media (min-width: $sm) {
    min-width: 260px;
    min-height: 48px;
    padding: 7px;
    font-size: 20px;
  }
}

.roundedPill {
  border-radius: 50rem;
}

.blueGreenBackground {
  background: linear-gradient(to right, #009ee2, #93c01f);
}

.goldenBackground {
  @include golden-background(left);
}

.silverBackground {
  background: linear-gradient(to right, #988f8f, #d2d2d2, #988f8f, #d2d2d2, #988f8f, #988f8f);
}

.redPurpleBackground {
  background: linear-gradient(to right, #e84e1b, #9918ed);
}

.dualQuestionCircle {
  @include golden-background(right);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    width: 170px;
    height: 170px;
  }
  @media (min-width: $sm) {
    width: 248px;
    height: 248px;
  }

  .dualQuestionCircleInner {
    border-radius: 50%;
    background: linear-gradient(to right, #f0f0f1, #ebebeb, #dcdcdd, #c5c5c5, #b1b1b1);
    width: 96%;
    height: 96%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dualQuestionCircleContent {
      text-align: center;
      width: 75%;
      height: 75%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (max-width: ($sm - 0.02)) {
        font-size: 18px;
      }
      @media (min-width: $sm) {
        font-size: 22px;
      }
    }
  }
}

.offlineGamePage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 42px);
    padding-bottom: 20px;
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 55px);
    padding-bottom: 30px;
  }

  .rotate180 {
    transform: rotate(180deg);
  }

  .pageContent {
    max-width: 100%;

    @media (max-width: ($sm - 0.02)) {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media (min-width: $sm) {
      width: 866px;
    }
  }

  .userHandContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ($sm - 0.02)) {
      width: 100%;
    }
    @media (min-width: $sm) {
      width: 50%;
    }

    &.first {
      margin-right: auto;
    }

    &.second {
      margin-left: auto;
    }

    .messageButtonContainer {
      position: relative;

      .button {
        position: absolute;
        transform: translate(20px, 10px);
        width: 36px;
        height: 36px;

        &:after {
          display: none;
        }

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .userProfileContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .userInfo {
      margin-top: 2px;
      @media (max-width: ($sm - 0.02)) {
        border-radius: 6px;
        padding: 3px;
      }
      @media (min-width: $sm) {
        border-radius: 10px;
        padding: 5px;
      }

      .userName {
        text-align: center;
        @media (max-width: ($sm - 0.02)) {
          font-size: 13px;
        }
        @media (min-width: $sm) {
          font-size: 16px;
        }
      }

      .userFlag {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        @media (max-width: ($sm - 0.02)) {
          width: 22px;
          height: 13px;
          border-radius: 3px;
        }
        @media (min-width: $sm) {
          width: 32px;
          height: 19px;
          border-radius: 6px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @media (max-width: ($sm - 0.02)) {
            border-radius: 3px;
          }
          @media (min-width: $sm) {
            border-radius: 6px;
          }
        }
      }
    }
  }

  .dualGameCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    overflow: hidden;

    @media (max-width: ($sm - 0.02)) {
      border-radius: 15px;
      padding: 10px 5px;
      flex-direction: column-reverse;
      margin: 6px auto;
      height: 100%;
    }
    @media (min-width: $sm) {
      border-radius: 24px;
      padding: 10px;
      flex-direction: row;
      height: 326px;
      margin: 8px auto;
    }

    // TODO: remove the following style
    .startButtonLayer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8px);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .startButton {
        border-radius: 10px;
        @include pink-background(right);

        @media (max-width: ($sm - 0.02)) {
          width: 117px;
          height: 40px;
          font-size: 18px;
        }
        @media (min-width: $sm) {
          width: 200px;
          height: 68px;
          font-size: 22px;
        }
      }
    }

    .firstSide,
    .secondSide {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media (max-width: ($sm - 0.02)) {
        height: 170px;
      }
      @media (min-width: $sm) {
        height: 100%;
      }
    }

    .firstSide {
      .playerPoints {
        @media (max-width: ($sm - 0.02)) {
          bottom: 9px;
          left: 9px;
        }
        @media (min-width: $sm) {
          bottom: 16px;
          left: 16px;
        }
      }
    }

    .secondSide {
      .playerPoints {
        @media (max-width: ($sm - 0.02)) {
          top: 9px;
          right: 9px;
        }
        @media (min-width: $sm) {
          top: 16px;
          right: 16px;
        }
      }
    }

    .playerPoints {
      position: absolute;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include golden-background(right);

      @media (max-width: ($sm - 0.02)) {
        width: 42px;
        height: 42px;
        font-size: 18px;
      }
      @media (min-width: $sm) {
        width: 56px;
        height: 56px;
        font-size: 22px;
      }

      .playerPointsLottie {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: ($sm - 0.02)) {
          width: calc(100% + 60px);
          height: calc(100% + 10px);
        }
        @media (min-width: $sm) {
          width: calc(100% + 95px);
          height: calc(100% + 20px);
        }

        > div {
          width: 100%;
          height: 100%;
        }
      }
    }

    .messageSpace {
      position: absolute;
      width: 80%;

      &.bottom {
        bottom: 0;

        .messageSeparatorLine {
          margin-bottom: 4px;
        }
      }

      &.top {
        top: 0;

        .messageSeparatorLine {
          margin-top: 4px;
        }
      }

      .messageSeparatorLine {
        @include golden-background(right);
        border-radius: 50rem;
        height: 2px;
        width: 100%;
      }
    }

    .sidesDividerContainer {
      display: flex;
      align-items: center;

      @media (max-width: ($sm - 0.02)) {
        justify-content: space-between;
        width: 100%;
      }
      @media (min-width: $sm) {
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }

      .dualGameCounter {
        @media (max-width: ($sm - 0.02)) {
          font-size: 25px;
          text-orientation: mixed;
          writing-mode: vertical-lr;
          transform: rotate(180deg);
        }
        @media (min-width: $sm) {
          font-size: 32px;
        }
      }

      .dualGameTimer {
        @media (max-width: ($sm - 0.02)) {
          font-size: 16px;
          text-orientation: mixed;
          writing-mode: vertical-lr;
        }
        @media (min-width: $sm) {
          font-size: 18px;
        }
      }

      .dualGameDivider {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: ($sm - 0.02)) {
          width: 100%;
          height: 3px;
        }
        @media (min-width: $sm) {
          width: 4px;
          height: 300px;
          flex-direction: column;
        }

        &.loadAnimation {
          > div {
            @media (max-width: ($sm - 0.02)) {
              animation: dualGameNextWordAnimationVertical 1s ease-in-out forwards;
            }
            @media (min-width: $sm) {
              animation: dualGameNextWordAnimationHorizontal 1s ease-in-out forwards;
            }
          }
        }

        > div {
          transition: 150ms;

          @media (max-width: ($sm - 0.02)) {
            width: 50%;
            height: 100%;
          }
          @media (min-width: $sm) {
            width: 100%;
            height: 50%;
          }

          &:first-child {
            @media (max-width: ($sm - 0.02)) {
              border-radius: 50rem 0 0 50rem;
              @include golden-background(right);
            }
            @media (min-width: $sm) {
              border-radius: 50rem 50rem 0 0;
              @include golden-background(bottom);
            }
          }

          &:last-child {
            @media (max-width: ($sm - 0.02)) {
              border-radius: 0 50rem 50rem 0;
              @include golden-background(left);
            }
            @media (min-width: $sm) {
              border-radius: 0 0 50rem 50rem;
              @include golden-background(top);
            }
          }
        }
      }
    }
  }
}

.trueFalseButton {
  border-radius: 50%;
  @media (max-width: ($sm - 0.02)) {
    width: 60px;
    height: 60px;
    font-size: 17px;
  }
  @media (min-width: $sm) {
    width: 85px;
    height: 85px;
    font-size: 21px;
  }

  &.disabled {
    background: #b1b1b1 !important;
    color: #706f6f !important;
  }
}

.dualGameUserInfoCardContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 14px;
  }
  @media (min-width: $sm) {
    margin-bottom: 20px;
  }
}

.dualGameUserInfoCard {
  height: 100%;
  margin: auto;
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    width: 320px;
    font-size: 15px;
    border-radius: 10px;
    padding: 12px;
  }
  @media (min-width: $sm) {
    width: 495px;
    font-size: 20px;
    border-radius: 15px;
    padding: 20px;
  }

  .cardHeader {
    display: flex;
    justify-content: space-between;
    gap: 4px;

    @media (max-width: ($sm - 0.02)) {
      padding: 6px 18px;
      border-radius: 10px;
      margin-bottom: 12px;
    }
    @media (min-width: $sm) {
      padding: 10px 24px;
      border-radius: 15px;
      margin-bottom: 20px;
    }

    .centerSection {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
    }

    .rightSection {
      display: flex;
      justify-content: flex-end;
      align-self: center;

      @media (max-width: ($sm - 0.02)) {
        width: 63px;
      }
      @media (min-width: $sm) {
        width: 90px;
      }

      .playerFlag {
        img {
          aspect-ratio: 1.6 / 1;
          overflow: hidden;
          @media (max-width: ($sm - 0.02)) {
            width: 40px;
            border-radius: 6px;
          }
          @media (min-width: $sm) {
            width: 60px;
            border-radius: 8px;
          }
          object-fit: cover;
        }
      }

      .gameWinnerSvg {
        @media (max-width: ($sm - 0.02)) {
          width: 63px;
        }
        @media (min-width: $sm) {
          width: 90px;
        }

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .leftSection {
      align-self: center;
    }
  }

  .pointsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: ($sm - 0.02)) {
      padding: 0 15px;
      gap: 20px;
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      padding: 0 20px;
      gap: 30px;
      margin-bottom: 14px;
    }

    .pointContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        flex: 1;
        @media (max-width: ($sm - 0.02)) {
          font-size: 12px;
        }
        @media (min-width: $sm) {
          font-size: 16px;
        }
      }

      .scoreBadge {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: ($sm - 0.02)) {
          width: 30px;
          height: 30px;
        }
        @media (min-width: $sm) {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .buttonsList {
    > * {
      margin-right: auto;
      margin-left: auto;

      &:not(:last-child) {
        @media (max-width: ($sm - 0.02)) {
          margin-bottom: 6px;
        }
        @media (min-width: $sm) {
          margin-bottom: 9px;
        }
      }
    }
  }

  .moreOptions {
    display: flex;
    @media (max-width: ($sm - 0.02)) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: $sm) {
      width: 26px;
      height: 26px;
    }

    &:after {
      display: none;
    }
  }
}

.keyboardGuideModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  //width: 930px;
  max-width: 100%;
  height: 100%;
  padding: 40px 0;
  overflow: auto;
}

.keyboardGuideCardsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.keyboardGuideCard {
  background: #dbdef1;
  border-radius: 15px;
  width: 445px;
  margin: 10px;
  padding: 20px;

  .cardTitle,
  .cardText {
    font-size: 14px;
  }

  .cardTitle {
    font-weight: 800;
    margin-bottom: 10px;
  }

  .keyboardGuideContent {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 18px;

    .keyboardGuideKeyContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > svg {
        height: auto;
        width: 60px;
      }
    }
  }
}

.dualLobbyPlayerTwoCard {
  min-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  max-width: 100%;

  @media (max-width: ($sm - 0.02)) {
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $sm) {
    padding: 20px;
  }
}

.playerCardKeyPicture {
  @media (max-width: ($sm - 0.02)) {
    height: 60px;
  }
  @media (min-width: $sm) {
    height: 90px;
  }

  > img {
    object-fit: cover;
  }

  > * {
    width: auto;
    height: 100%;
  }
}

.playerCardValueBadge {
  border-radius: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ($sm - 0.02)) {
    width: 58px;
    font-size: 14px;
  }
  @media (min-width: $sm) {
    width: 76px;
    font-size: 16px;
  }
}

.dualGameResultPage {
  @media (max-width: ($sm - 0.02)) {
    min-height: calc(100dvh - 158px);
  }
  @media (min-width: $sm) {
    min-height: calc(100dvh - 192px);
  }
}

.historyCardInnerContainer {
  display: flex;
  justify-content: space-between;

  &.detailsContainer {
    align-items: center;
    @media (max-width: ($sm - 0.02)) {
      padding: 0 5px;
    }
    @media (min-width: $sm) {
      padding: 0 12px;
    }
  }

  .userCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    height: 100%;

    @media (max-width: ($sm - 0.02)) {
      width: 90px;
      padding: 4px;
    }
    @media (min-width: $sm) {
      width: 134px;
      padding: 5px;
    }

    .topSection {
      width: 100%;
    }
  }

  .winnerBadge {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .star {
      display: flex;
      align-items: center;

      @media (max-width: ($sm - 0.02)) {
        width: 14px;
        height: 14px;
      }
      @media (min-width: $sm) {
        width: 20px;
        height: 20px;
      }

      > svg {
        width: 100%;
        height: 100%;
      }
    }

    .winnerText {
      margin: 0 4px;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;

      > svg {
        width: 100%;
      }
    }
  }

  .flag {
    display: flex;
    @media (max-width: ($sm - 0.02)) {
      width: 26px;
    }
    @media (min-width: $sm) {
      width: 40px;
    }

    img {
      border-radius: 4px;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .totalScoreCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($sm - 0.02)) {
      width: 80px;
      height: 80px;
    }
    @media (min-width: $sm) {
      width: 110px;
      height: 110px;
    }

    border-radius: 50%;

    .totalScoreInner {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 50%;
      width: 90%;
    }
  }
}

.vicabMasterList {
  max-width: 100%;

  @media (max-width: ($sm - 0.02)) {
    width: 360px;
  }
  @media (min-width: $sm) {
    width: 700px;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;

    @media (max-width: ($sm - 0.02)) {
      padding-left: 20px;
    }
    @media (min-width: $sm) {
      padding-left: 30px;
      padding-top: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .flag {
      position: absolute;
      top: 0;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      height: auto;

      @media (max-width: ($sm - 0.02)) {
        right: 24px;
        width: 28px;
      }
      @media (min-width: $sm) {
        right: 40px;
        width: 32px;
      }

      > img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .userNameCard {
      @include golden-background(right);
      text-align: center;
      border-radius: 12px 12px 0 0;
      white-space: nowrap;

      @media (max-width: ($sm - 0.02)) {
        width: fit-content;
        margin-left: 50px;
        padding: 2px 4px;
        max-width: 50%;
      }
      @media (min-width: $sm) {
        width: fit-content;
        margin-left: 74px;
        padding: 3px 5px;
        max-width: 60%;
      }
    }

    .itemCard {
      display: flex;
      align-items: center;
      position: relative;
      background: linear-gradient(to right, #a64ccf, #4c91c6);
      @media (max-width: ($sm - 0.02)) {
        border-radius: 15px;
        height: 42px;
      }
      @media (min-width: $sm) {
        border-radius: 20px;
        height: 56px;
      }

      .content {
        @media (max-width: ($sm - 0.02)) {
          margin-left: 50px;
          font-size: 12px;
        }
        @media (min-width: $sm) {
          margin-left: 70px;
          font-size: 16px;
        }
      }

      .userAvatar {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-30%, 0);
      }

      .medal {
        position: absolute;
        @media (max-width: ($sm - 0.02)) {
          width: 12px;
        }
        @media (min-width: $sm) {
          width: 20px;
        }

        > svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.messagesBox {
  width: 300px;
  height: 270px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  border-radius: 20px;

  .innerContainer {
    overflow: auto;
    border-radius: 15px;
    border: 1px solid #fcfcfc;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5), inset 0 3px 6px rgba(0, 0, 0, 0.5);
    padding: 15px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }

  .tabsContainer {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 8px;
      border: 1px solid #fcfcfc;
      color: #fcfcfc;
      width: 86px;
      height: 24px;
      font-style: italic;
      font-size: 16px;

      &:hover {
        background: rgba(252, 252, 252, 0.2);
      }
    }
  }

  .emojisContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button {
      width: calc(100% / 6);
      aspect-ratio: 1/1;
      font-size: 23px;
      border-radius: 50%;

      &:hover {
        background: rgba(252, 252, 252, 0.2);
      }
    }
  }

  .textsContainer {
    display: flex;
    flex-direction: column;
    align-items: start;

    button {
      width: 100%;
      padding-bottom: 5px;
      padding-top: 5px;
      color: #fcfcfc;
      font-size: 16px;

      &:not(:last-child) {
        border-bottom: 1px solid;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.playersListCard {
  max-width: 100%;

  @media (max-width: ($sm - 0.02)) {
    padding: 12px;
    border-radius: 12px;
    width: 360px;
  }
  @media (min-width: $sm) {
    padding: 20px;
    border-radius: 16px;
    width: 700px;
  }

  .leftItems {
    display: flex;
    align-items: center;
    @media (max-width: ($sm - 0.02)) {
      max-width: 50%;
    }
    @media (min-width: $sm) {
      max-width: 70%;
    }
  }

  .leaderboardTitleBox {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
    @include golden-background(right);

    @media (max-width: ($sm - 0.02)) {
      border-radius: 10px;
      padding: 5px;
    }
    @media (min-width: $sm) {
      border-radius: 15px;
      padding: 10px;
    }
  }

  .cardItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    @media (max-width: ($sm - 0.02)) {
      padding: 5px 12px;
      border-radius: 12px;
    }
    @media (min-width: $sm) {
      padding: 7px 16px;
      border-radius: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .flag {
      @media (max-width: ($sm - 0.02)) {
        width: 30px;
      }
      @media (min-width: $sm) {
        width: 50px;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }

    .rank {
      @include golden-background(right);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: ($sm - 0.02)) {
        width: 22px;
        height: 22px;
      }
      @media (min-width: $sm) {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.userName {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: $md) {
    max-width: 160px;
  }
}

@keyframes dualGameNextWordAnimationHorizontal {
  from {
    height: 0;
  }

  to {
    height: 50%;
  }
}

@keyframes dualGameNextWordAnimationVertical {
  from {
    width: 0;
  }

  to {
    width: 50%;
  }
}
