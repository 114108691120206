@import 'styles/globalConfigs.scss';

.card {
  display: block;
  position: relative;
  min-height: 87px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 #00000040;
  transition: height 500ms ease, margin 500ms ease, opacity 500ms ease, transform 300ms ease-in-out;

  &.dragging {
    cursor: grabbing;
  }

  .text {
    padding: 27px 25px 18px;
  }

  .badgeContainer {
    position: absolute;
    z-index: 1;

    @media (max-width: ($xsm - 0.02)) {
      top: -7px;
    }
    @media (min-width: $xsm) {
      top: -9px;
    }
  }

  &.persianArabicStyles .badgeContainer {
    right: 25px;
  }

  z-index: 1;
}

.cardLink {
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.time {
  font-weight: 100;
  font-size: 12px;
  position: absolute;
  bottom: 5px;

  @media (max-width: $xsm) {
    font-size: 11px;
  }
}

.backgroundWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: background-color 500ms ease-in-out;
  border-radius: 16px;
  overflow-x: revert;

  .crossInBackgroundWrapper {
    display: none;

    @media (max-width: $xsm) {
      transform: translateY(-50%);
      height: 10px;
      width: 10px;
      display: block;
      position: absolute;
      top: 50%;
    }

    > svg {
      height: 100%;
      width: 100%;
    }
  }
}

.cross {
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 2;
  top: 10px;

  @media (max-width: ($xsm - 0.02)) {
    display: none;
  }

  > svg {
    width: 50%;
    height: 50%;
  }
}

.enter,
.exitLeft,
.exitRight {
  opacity: 0;
  transform: translateX(0);
}

.enterActive,
.exitLeftActive,
.exitRightActive {
  opacity: 1;
  transform: translateX(0);
}

.exitLeftActive {
  transform: translateX(-100%);
}

.exitRightActive {
  transform: translateX(100%);
}
