@import './../../styles/globalConfigs';

.middleInput {
  display: inline;
  outline: none;
  @media (max-width: ($sm - 0.02)) {
    border-bottom: 2px dotted;
  }
  @media (min-width: $sm) {
    border-bottom: 3.5px dotted;
  }

  &:empty {
    @media (max-width: ($sm - 0.02)) {
      padding-right: 44px;
    }
    @media (min-width: $sm) {
      padding-right: 81px;
    }
  }
}
