@import './../../styles/globalConfigs';

.pageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100dvh - 140px);
  font-family: 'Roboto Flex', $Sahel, $SegoeUI, sans-serif;
  margin-top: -30px;
}

.menuOuterCircleBackground {
  border-radius: 50%;
  border: 8px solid;
  @media (max-width: ($xsm - 0.02)) {
    width: 310px;
    height: 310px;
  }
  @media (min-width: $xsm) {
    width: 514px;
    height: 514px;
  }
}

.menuOuterCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.menuOuterItem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: left top;

  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      @media (max-width: ($xsm - 0.02)) {
        transform: rotate(calc((360deg / 6 * #{$i - 1}) - 30deg)) translate(-50%, calc(-50% - 132px));
      }
      @media (min-width: $xsm) {
        transform: rotate(calc((360deg / 6 * #{$i - 1}) - 30deg)) translate(-50%, calc(-50% - 220px));
      }
    }
  }
}

.menuOuterItemText {
  display: block;
  @media (max-width: ($xsm - 0.02)) {
    font-size: 12px;
  }
  @media (min-width: $xsm) {
    font-size: 16px;
  }
}

.revert {
  transform: rotate(180deg);
}

.menuInnerCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  width: 82%;
  height: 82%;
  box-shadow: 5px 5px 10px 2px rgb(0 0 0 / 40%);
}

.menuInnerItem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: left top;

  .menuInnerItemIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ($xsm - 0.02)) {
      width: 25px;
      height: 25px;
    }
    @media (min-width: $xsm) {
      width: 40px;
      height: 40px;
    }

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  &.innerDividingLine {
    width: 1px;
    @media (max-width: ($xsm - 0.02)) {
      height: 42px;
    }
    @media (min-width: $xsm) {
      height: 74px;
    }
  }

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      @media (max-width: ($xsm - 0.02)) {
        transform: rotate(calc((360deg / 12 * #{$i - 1}) - 30deg)) translate(-50%, calc(-50% - 98px));
      }
      @media (min-width: $xsm) {
        transform: rotate(calc((360deg / 12 * #{$i - 1}) - 30deg)) translate(-50%, calc(-50% - 166px));
      }
    }
  }
}

.menuMiddleCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  width: 63%;
  height: 63%;
  box-shadow: inset 5px 5px 10px 2px rgb(0 0 0 / 40%);
}

.menuCenterCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  width: 76%;
  height: 76%;
  background: #e7eefb;
  box-shadow: 0 0 44px #c4d4e7;

  > svg {
    @media (max-width: $xsm) {
      width: 50px;
      height: 50px;
    }
    @media (min-width: $xsm) {
      width: 70px;
      height: 70px;
    }
  }
  > span {
    color: #231f20;
    font-size: 16px;
    font-weight: 400;
    @media (max-width: $xsm) {
      font-size: 12px;
    }
  }
}

.deleteButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuInnerItemIconWrapper {
  position: relative;
}
.badge {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  &.revertForBadge {
    bottom: -8px !important;
    right: -8px !important;
  }
  .badgeContent {
    font-size: 12px;
    &.revertForBadge {
      bottom: -8px !important;
      left: -8px !important;
    }
    &.rotateBadge90 {
      transform: rotate(90deg);
    }

    &.rotateBadgeNegative90 {
      transform: rotate(-90deg);
    }
  }
}
