@import "../../styles/globalConfigs";

.add-category-card {
  border-radius: 20px;
  max-width: 100%;

  @media (max-width: ($sm - 0.02)) {
    width: 428px;
    padding: 21px;
  }
  @media (min-width: $sm) {
    width: 600px;
    padding: 30px;
  }

  .selectcolor-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      gap: 8px;
    }
    @media (min-width: $sm) {
      gap: 11px;
    }
  }

  .selected-color-icon {
    > svg {
      @media (max-width: ($sm - 0.02)) {
        width: 34.91px;
        height: 19.23px;
      }
    }
  }

  .select-color-text {
    white-space: nowrap;
  }

  .color-badges-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .color-badge {
      cursor: pointer;
      border-radius: 50%;

      @media (max-width: ($sm - 0.02)) {
        width: 18px;
        height: 18px;
      }
      @media (min-width: $sm) {
        width: 24px;
        height: 24px;
      }
    }
  }

  .open-colorpalette-btn {
    display: flex;

    .add-circular-icon {
      transition: all .3s;
      display: flex;
      @media (max-width: ($sm - 0.02)) {
        width: 18px;
        height: 18px;
      }
      @media (min-width: $sm) {
        width: 24px;
        height: 24px;
      }

      > svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.open {
        transform: rotate(135deg);
      }
    }
  }
}