@import './../../styles/globalConfigs';

.tipCard {
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    padding: 5px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    padding: 10px;
  }

  .tipCardInner {
    @media (max-width: ($sm - 0.02)) {
      border-radius: 7px;
      border: 1px solid;
      padding: 8px 0;
    }
    @media (min-width: $sm) {
      border-radius: 10px;
      border: 2px solid;
      padding: 12px 0;
    }
  }

  .tipTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .beforeLine {
      transform: rotate(180deg);
      @media (max-width: ($sm - 0.02)) {
        width: 36px;
        height: 4px;
        margin-right: 8px;
      }
      @media (min-width: $sm) {
        width: 52px;
        height: 6px;
        margin-right: 12px;
      }
    }

    p {
      font-weight: 600;
      font-style: italic;
      @media (max-width: ($sm - 0.02)) {
        font-size: 14px;
      }
      @media (min-width: $sm) {
        font-size: 21px;
      }
    }
  }

  .content {
    @media (max-width: ($sm - 0.02)) {
      margin: 0 9px;
      font-size: 14px;
    }
    @media (min-width: $sm) {
      margin: 0 14px;
      font-size: 18px;
    }
  }
}
