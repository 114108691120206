@import "../../styles/globalConfigs";

[data-jsx="ChatBox"] {
  &.chat-box {
    position: relative;
    display: flex;
    @media (max-width: ($sm - 0.02)) {
      padding: 5px 10px 5px;
      border: 2px solid;
      min-height: 38px;
    }
    @media (min-width: $sm) {
      padding: 9px 10px 9px;
      border: 3px solid;
      min-height: 50px;
    }

    .text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .shape {
      position: absolute;
      @media (max-width: ($sm - 0.02)) {
        width: 15px;
        top: 0;
      }
      @media (min-width: $sm) {
        width: 20px;
        top: 4px;
      }
    }

    &.toRight {
      border-radius: 15px;

      @media (max-width: ($sm - 0.02)) {
        margin-left: 15px;
        border-radius: 15px;
      }
      @media (min-width: $sm) {
        margin-left: 22px;
        border-radius: 20px;
      }

      .shape {
        left: 0;
        transform: translate(-100%, 7px) rotateY(-1deg);
      }
    }

    &.toLeft {
      border-radius: 15px;

      @media (max-width: ($sm - 0.02)) {
        margin-right: 15px;
        border-radius: 15px;
      }
      @media (min-width: $sm) {
        margin-right: 22px;
        border-radius: 20px;
      }

      .shape {
        right: 0;
        transform: translate(96%, 29%) rotateY(180deg);
      }
    }
  }
}
