@import './../../styles/globalConfigs';

.exerciseStatisticsContainer {
  @media (max-width: ($sm - 0.02)) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (min-width: $sm) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  @media (min-width: $md) {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.exerciseProgressCard {
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    padding: 15px 10px;
    width: 300px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    padding: 21px;
    width: 500px;
  }
  @media (max-width: ($md - 0.02)) {
    margin: 0 auto 35px;
  }
}

.exerciseProgressItem {
  display: grid;
  grid-template-columns: auto 65%;

  @media (max-width: ($sm - 0.02)) {
    font-size: 11px;
  }
  @media (min-width: $sm) {
    font-size: 16px;
  }

  &:not(:last-child) {
    @media (max-width: ($sm - 0.02)) {
      margin-bottom: 10px;
    }
    @media (min-width: $sm) {
      margin-bottom: 20px;
    }
  }

  .sectionIcon {
    @media (max-width: ($sm - 0.02)) {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }
    @media (min-width: $sm) {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .sectionTitle {
    @media (max-width: ($sm - 0.02)) {
      margin-right: 6px;
    }
    @media (min-width: $sm) {
      margin-right: 9px;
    }
  }

  .exerciseNumber {
    @media (max-width: ($sm - 0.02)) {
      width: 18px;
    }
    @media (min-width: $sm) {
      width: 32px;
    }
  }

  .rightItemsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 auto;
  }

  .progressBarContainer {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  }

  .progressBar {
    background: transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), inset 0 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid;
    border-radius: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    @media (max-width: ($sm - 0.02)) {
      height: 9px;
      padding-right: 1px;
      padding-left: 1px;
    }
    @media (min-width: $sm) {
      height: 12px;
      padding-right: 2px;
      padding-left: 2px;
    }

    .progressBarInner {
      border-radius: 20px 20px 20px 20px;
      @media (max-width: ($sm - 0.02)) {
        height: 5.7px;
      }
      @media (min-width: $sm) {
        height: 7.1px;
      }
    }
  }

  .percentage {
    flex: 1 0 auto;
    @media (max-width: ($sm - 0.02)) {
      margin-left: 4px;
      width: 26px;
    }
    @media (min-width: $sm) {
      margin-left: 7px;
      width: 39px;
    }
  }
}

.exerciseStatisticsInfo {
  flex: 0;
}

.exerciseSuccessProgress {
  display: flex;
  align-items: center;
  @media (max-width: ($sm - 0.02)) {
    font-size: 14px;
    margin-bottom: 37px;
  }
  @media (min-width: $sm) {
    font-size: 22px;
    margin-bottom: 60px;
  }
  @media (max-width: ($md - 0.02)) {
    justify-content: center;
  }

  .exerciseSuccessProgressBar {
    overflow: hidden;
    border-radius: 20px;
    @media (max-width: ($sm - 0.02)) {
      width: 216px;
      height: 10px;
      margin: 0 7px;
    }
    @media (min-width: $sm) {
      width: 296px;
      height: 15px;
      margin: 0 12px;
    }

    .exerciseSuccessProgressBarInner {
      border-radius: 20px;
      height: 100%;
    }
  }
}

.exerciseTimeInfo {
  display: flex;
  align-items: baseline;
  @media (max-width: ($md - 0.02)) {
    justify-content: center;
  }

  .exerciseTimeInfoBadge {
    flex: 0 0 auto;
    border-radius: 50%;
    display: block;
    @media (max-width: ($sm - 0.02)) {
      margin-right: 4px;
      width: 10px;
      height: 10px;
    }
    @media (min-width: $sm) {
      margin-right: 14px;
      width: 18px;
      height: 18px;
    }
  }

  .exerciseTimeInfoContent {
    text-align: center;
    @media (max-width: ($sm - 0.02)) {
      font-size: 12px;
    }
    @media (min-width: $sm) and (max-width: ($md - 0.02)) {
      font-size: 18px;
    }
    @media (min-width: $md) {
      font-size: 22px;
      display: flex;
    }
    @media (max-width: ($md - 0.02)) {
      flex-direction: column;
    }

    .exerciseTimeInfoText {
      &:not(:last-child) {
        @media (max-width: ($md - 0.02)) {
          margin-bottom: 10px;
        }
      }

      @media (min-width: $md) {
        margin: 20px;
      }
    }
  }
}
