@import './../../styles/globalConfigs';

.avatar {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  width: 80px;
  height: 80px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
