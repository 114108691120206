@import 'globalConfigs';

.dictionary-top-page {
	@media (max-width: ($sm - 0.02)) {
		display: block !important;
	}
	@media (min-width: $sm) {
		flex-direction: row-reverse;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.dictionary-types {
	div.dictionary {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 7px;

		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
		}

		@media (max-width: 420px) {
			width: 40px;
			height: 40px;
		}
		@media (min-width: 421px) {
			width: 50px;
			height: 50px;
		}

		> img,
		svg {
			width: 97%;
			height: 97%;
			object-fit: contain;
		}

		.native {
			background-color: #f29100;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			width: calc(100% - 3px);
			height: calc(100% - 3px);

			span {
				color: #ffffff;
			}
		}

		&:not(:last-child) {
			@media (max-width: 420px) {
				margin-right: 8px;
			}
			@media (min-width: 421px) and (max-width: $lg - 1) {
				margin-right: 14px;
			}
			@media (min-width: $lg) {
				margin-right: 20px;
			}
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			bottom: -7px;
			right: 50%;
			transform: translateX(50%);
			height: 3px;
			width: 0;
			background-color: #f29100;
			border-radius: 50rem;
		}

		&.selected {
			position: relative;
			transform: scale(1.2);
			border: 1.5px solid #f29100;

			&:after {
				width: 60%;
			}
		}
	}
}

.dictionary-language {
	display: flex;
	align-items: flex-end;
	height: 20px;

	svg {
		height: 100%;
		width: auto;
	}
}

.dictionary-card {
	border: 1px solid;
	border-radius: 15px;
	padding-bottom: 20px;
	margin-top: 28px;
	margin-bottom: 10px;

	* {
		user-select: text;
	}

	.dictionary-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid;
		margin: 18px 20px;
		@media (min-width: $md) {
			margin: 28px 54px;
		}

		.word {
			font-weight: 700;
			margin-right: 16px;
			display: inline-block;
		}

		.phonetic {
			display: inline-block;
		}

		.listen {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			> * {
				margin: 7px 12px;
			}
		}
	}

	.dictionary-content {
		.pos {
			display: flex;
			align-items: center;
			margin-bottom: 11px;

			.line {
				display: inline-block;
				margin-right: 12px;
				@media (max-width: ($sm - 0.02)) {
					height: 3px;
					width: 40px;
				}
				@media (min-width: $sm) and (max-width: ($md - 0.02)) {
					height: 4px;
					width: 60px;
				}
				@media (min-width: $md) {
					height: 5px;
					width: 80px;
				}
			}

			&.noun {
				.line {
					background-color: #e5007e;
				}

				.name {
					color: #e5007e;
				}
			}

			&.verb {
				.line {
					background-color: #990098;
				}

				.name {
					color: #990098;
				}
			}

			&.noun {
				.line {
					background-color: #e5007e;
				}

				.name {
					color: #e5007e;
				}
			}
		}

		.text {
			line-height: 1.9;
			padding: 0 25px;
			@media (min-width: $md) {
				padding: 0 70px;
			}
		}
	}
}

.dictionary-more {
	width: 27px;
	height: 27px;

	> svg {
		width: 100%;
		height: 100%;
	}
}

.sentence-option {
	justify-content: flex-end;
	@media (max-width: 1299px) {
		justify-content: center;
	}
	@media (min-width: 1400px) and (max-width: 1459px) {
		justify-content: center;
	}
}
