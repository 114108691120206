@import './globalConfigs';

$fontSizeNames: (
  '3xs': $fontSize-3xSmall,
  '2xs': $fontSize-2xSmall,
  'xs': $fontSize-xSmall,
  'sr': $fontSize-smaller,
  'sm': $fontSize-small,
  'md': $fontSize-medium,
  'lg': $fontSize-large,
  'lr': $fontSize-larger,
  'xl': $fontSize-xLarge,
  '2xl': $fontSize-2xLarge,
  '3xl': $fontSize-3xLarge,
  'sl': $fontSize-superLarge
);

@each $key, $value in $fontSizeNames {
  .fs-#{$key} {
    font-size: $value;
  }
  .res\:fs-#{$key} {
    @include responsiveFontSize($value);
  }
}

:root {
  --safe-area-bottom: calc(env(safe-area-inset-bottom, 0) / 2);
}

html,
body {
  touch-action: manipulation;
}

:root {
  --h-screen-dvh: 100dvh;
  @supports not (height: 100dvh) {
    --h-screen-dvh: 100vh;
  }
}
