@import './../../../../styles/globalConfigs';

.customTitle {
  font-weight: 600;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 17px;
    padding: 4px;
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    border: 2px solid transparent;
    font-size: 24px;
    padding: 6px;
    margin-bottom: 14px;
  }
}

.accordion {
  @media (max-width: ($sm - 0.02)) {
    margin-bottom: 15px;
  }
  @media (min-width: $sm) {
    margin-bottom: 22px;
  }

  .mainAccordionItemContainer > :global(.accordion-header) > :global(.accordion-button) {
    width: 100%;
  }

  .subAccordionItemContainer > :global(.accordion-header) > :global(.accordion-button) {
    display: block;
    width: fit-content;
    margin: auto;
  }

  :global(.accordion-button) {
    &:global(.collapsed) {
      .accordionHeader {
        &.main {
          .arrow {
            transform: rotateX(0deg) translateY(-50%);
          }
        }

        &.sub {
          .arrow {
            transform: rotateX(0deg);
          }
        }
      }
    }
  }
}

.accordionHeader {
  @media (max-width: ($sm - 0.02)) {
    border-radius: 20px;
    padding: 4px;
  }
  @media (min-width: $sm) {
    border-radius: 25px;
    padding: 6px;
  }

  &.sub {
    max-width: 100%;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ($sm - 0.02)) {
      padding: 7px 14px;
      min-width: 150px;
    }
    @media (min-width: $sm) {
      padding: 10px 20px;
      min-width: 180px;
    }

    .accordionTitle {
      font-weight: 600;
      @media (max-width: ($sm - 0.02)) {
        margin-right: 8px;
      }
      @media (min-width: $sm) {
        margin-right: 12px;
      }
    }

    .arrow {
      transform: rotateX(180deg);

      @media (max-width: ($sm - 0.02)) {
        width: 12px;
      }
      @media (min-width: $sm) {
        width: 15px;
      }
    }
  }

  &.main {
    position: relative;
    width: 100%;
    @media (max-width: ($sm - 0.02)) {
      border: 1px solid;
    }
    @media (min-width: $sm) {
      border: 2px solid;
    }

    .accordionTitle {
      font-weight: 700;
      text-align: center;

      @media (max-width: ($sm - 0.02)) {
        padding: 4px 25px;
      }
      @media (min-width: $sm) {
        padding: 5px 38px;
      }
    }

    .arrow {
      position: absolute;
      transform: rotateX(180deg) translateY(50%);
      top: 50%;
      @media (max-width: ($sm - 0.02)) {
        right: 10px;
        width: 14px;
      }
      @media (min-width: $sm) {
        right: 15px;
        width: 20px;
      }
    }
  }

  .accordionTitle {
    @media (max-width: ($sm - 0.02)) {
      font-size: 16px;
    }
    @media (min-width: $sm) {
      font-size: 18px;
    }
  }

  .arrow {
    transition: transform 0.3s ease;
    transform-origin: 100%;
    display: flex;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}

.accordionBody {
  @media (max-width: ($sm - 0.02)) {
    padding-top: 7px;
  }
  @media (min-width: $sm) {
    padding-top: 14px;
  }
}
