@import './../../../../styles/globalConfigs';

.grammarFocusTitle {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  font-weight: 600;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100%;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    font-size: 17px;
    padding: 5px 10px;
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    font-size: 24px;
    padding: 6px 10px;
    margin-bottom: 14px;
    min-width: 360px;
  }

  &.border {
    @media (max-width: ($sm - 0.02)) {
      border: 1px solid transparent;
    }
    @media (min-width: $sm) {
      border: 2px solid transparent;
    }
  }
}
