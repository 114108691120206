@import './../../../../styles/globalConfigs';

.subTitle {
  scroll-margin-top: 20vh;
  transition: opacity 300ms;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (max-width: ($sm - 0.02)) {
    border-radius: 10px;
    border: 1px solid;
    font-size: 17px;
    padding: 4px;
    margin-bottom: 7px;
  }
  @media (min-width: $sm) {
    border-radius: 15px;
    border: 2px solid;
    font-size: 24px;
    padding: 6px;
    margin-bottom: 14px;
  }
}
